import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import NoAccountAcceptInviteE from "./pages/NoAccountAcceptInviteE";
import HomeFeed from "./pages/HomeFeed";
import DeleteAccount from "./pages/DeleteAccount";

import NoAccountAcceptInviteE1 from "./pages/NoAccountAcceptInviteE1";
import EventEventDetailsBrowse from "./pages/EventEventDetailsBrowse";
import EventEventDetailsBrowse1 from "./pages/EventEventDetailsBrowse1";
import AvailabilitySharedNoAccou from "./pages/AvailabilitySharedNoAccou";
import EventEventDetailsNoAcc from "./pages/EventEventDetailsNoAcc";
import EventEventDetailsNoAcc1 from "./pages/EventEventDetailsNoAcc1";
import TermsandCondition from "./pages/TermsandCondition";
import GetSignIn from "./pages/GetSignIn";
import DraftEvent from "./pages/DraftEvent";
import DraftEventList from "./pages/DraftEventList";
import StagingDraftEvent from "./pages/StagingDraftEvent";
import Signin from "./pages/Loginpage";
import VerificationCode from "./pages/Verificationpage";
import UserDetail from "./pages/Userdetail";
import ProfilePage from "./pages/Profilepage";
import WeekHours from "./pages/GeneralAvailablity";
import GoogleCalender from "./pages/accessGoolecalender";
import EventList from "./pages/Eventlist";
import SingleEvent from "./pages/SingleEvent";
import LiveEvent from "./pages/LiveEvent";
import FeedbackForm from "./pages/Feedbackform";
import FeedbackList from "./pages/FeedbackList";
import updateProfile  from "./pages/updateProfile"
import UpdateProfile from "./pages/updateProfile";
// import DraftEventList from "./pages/DraftEventList";


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/home-feed":
        title = "";
        metaDescription = "";
        break;
      case "/no-account-accept-invite-enter-name-disabled":
        title = "";
        metaDescription = "";
        break;
      case "/event-event-details-browser":
        title = "";
        metaDescription = "";
        break;
      case "/event-event-details-browser1":
        title = "";
        metaDescription = "";
        break;
      case "/availability-shared-no-account":
        title = "";
        metaDescription = "";
        break;
      case "/event-event-details-no-account":
        title = "";
        metaDescription = "";
        break;
      case "/event-event-details-no-account1":
        title = "";
        metaDescription = "";
        break;
      case "/terms-and-condition":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes >
      {/* <Route path="/" element={<DeleteAccount />} /> */}
      <Route
        path="/"
        element={<DraftEventList />}
      />
      <Route path="/homefeed" element={<HomeFeed />} />
      <Route
        path="/no-account-accept-invite-enter-name-disabled"
        element={<NoAccountAcceptInviteE1 />}
      />
      <Route
        path="/event-event-details-browser"
        element={<EventEventDetailsBrowse />}
      />
      <Route
        path="/event-event-details-browser1"
        element={<EventEventDetailsBrowse1 />}
      />
      <Route
        path="/availability-shared-no-account"
        element={<AvailabilitySharedNoAccou />}
      />
      <Route
        path="/event-event-details-no-account"
        element={<EventEventDetailsNoAcc />}
      />
      <Route
        path="/event-event-details-no-account1"
        element={<EventEventDetailsNoAcc1 />}
      />
      <Route
        path="/terms-and-condition"
        element={<TermsandCondition />}
      />
      <Route
        path="/get_signin"
        element={<GetSignIn />}
      />
      <Route
        path="/de/:id"
        element={<DraftEvent />}
      />
     <Route
        path="/le"
        element={<StagingDraftEvent />}
      />
       <Route
        path="/signin"
        element={<Signin />}
      />
      <Route
        path="/verification"
        element={<VerificationCode />}
      />
      <Route
        path="/details"
        element={<UserDetail />}
      />
       <Route
        path="/profile"
        element={<ProfilePage />}
      />
       <Route
        path="/generalavailability"
        element={<WeekHours />}
      />
      <Route
        path="/googlecalender"
        element={<GoogleCalender />}
      />
       <Route
        path="/le/:id"
        element={<SingleEvent />}
      />
        <Route
        path="/leve/:id"
        element={<LiveEvent />}
      />
          <Route
        path="/feedback"
        element={<FeedbackForm />}
      />
       <Route
        path="/feedbacklist"
        element={<FeedbackList />}
      />
       <Route
        path="/updateprofile"
        element={<UpdateProfile />}
      />
    </Routes>
    
  );
}
export default App;
