import { FunctionComponent, useEffect, useState } from "react";
import styles from "./AvailabilitySharedNoAccou.module.css";
import HomeFeed from "./HomeFeed";
import { Calendar, Whisper, Popover, Badge } from 'rsuite';
import { datetime, RRule } from 'rrule';
import { useSearchParams } from "react-router-dom";
import { isMobile, isAndroid } from 'react-device-detect';
import axios from 'axios';

const apiUrl = "../api";
const AvailabilitySharedNoAccou: FunctionComponent = (message) => {
  const [filterevent, setfilterevent]: any = useState(false);
  const [arrayEvent, setarrayEvent]: any = useState([]);
  const [activityDataList, setactivityData]: any = useState([]);
  const [browserdata, setBrowserData]: any = useSearchParams();
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  useEffect(() => {
    fetchTodos();
    const checkAppInstallation = async () => {
      if ('getInstalledRelatedApps' in navigator && typeof navigator.getInstalledRelatedApps === 'function') {
        try {
          const relatedApps = await navigator.getInstalledRelatedApps?.();
          if (relatedApps) {
            const isInstalled = relatedApps.some((app: any) => {
              // Replace 'com.example.app' with your package name or bundle identifier
              return app.id === 'adventureplanet.com://';
            });
            setIsAppInstalled(isInstalled);
            // alert(isInstalled);
          }
        } catch (error) {
          console.error('Error checking app installation:', error);
        }
      } else {
        console.warn('getInstalledRelatedApps is not supported in this browser.');
      }
    };

    checkAppInstallation();

  }, []);

  const checkAndRedirectToAppCreateAccoount = () => {
    
    // alert("open app");
    const appPackageName = 'adventureplanet.com://'; // Replace with your app's package name
    try {
      if (isMobile && !isAndroid) {
        // iOS device
        
        window.location.href = appPackageName;
        setTimeout(function () { window.location.href = "https://app.motus.fun/?redirect=invite"; }, 2000);
      } else if (isMobile && isAndroid) {
        
        // Android device
        window.location.href = appPackageName;
        setTimeout(function () { window.location.href = "https://app.motus.fun/?redirect=invite"; }, 2000);

      } else {
        
        
        // window.location.href = appPackageName;
        setTimeout(function () { window.location.href = "https://app.motus.fun/?redirect=invite"; }, 2000);
      }

    } catch (error) {
      console.error('Error during redirection:', error);
      // 
      // 
    }
  };

  const fetchTodos = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    try {
      const todoData = await axios.get(`${apiUrl}/draftEvent/activity/list`);
      const activityList = todoData?.data;
      setactivityData(todoData?.data);
      
      const dates: string[] = [];
      const activityData: any[] = [];
      for (var i = 0; i < activityList.length; i++) {
        let rule: any = []
        let rulesdatanew: any = [];
        let ownerId = JSON.parse(activityList[i].activityOwner);
        if (activityList[i].activityType == '2' && ownerId.id == browserdata.get("userId")) {
          if (activityList[i].activityRepeatRule == "") {

            rulesdatanew.push(activityList[i].activityStartDate);
          } else {
            let ruleData = activityList[i].activityRepeatRule as string;
            const ruleArray = RRule.fromString(ruleData);
            // 
            rulesdatanew = ruleArray.between(datetime(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()), datetime(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate() + 30));
          }
        }


        // 
        const rulesdata = rulesdatanew.filter((dateStr: string | number | Date) => {
          const date = new Date(dateStr);
          return date >= new Date();
        });

        
        for (var j = 0; j < rulesdata.length; j++) {
          if (!dates.includes(rulesdata[j].toDateString())) {
            // 
            dates.push(rulesdata[j].toDateString())
            const data: any = {}
            const arrayList = []
            arrayList.push(activityList[i])
            data.date = rulesdata[j].toDateString()
            data.arrayList = arrayList


            activityData.push(data)

          } else {
            
            for (var k = 0; k < activityData.length; k++) {
              if (rulesdata[j].toDateString() == activityData[k].date) {
                let sharedUsers = JSON.parse(activityList[i].activitySharedUsers)
                activityData[k].arrayList.push(activityList[i])

              }

            }
          }
        }
      }
      // 
      setarrayEvent(activityData)
    }
    catch (error) {
      
    }

  }


  const updateMonthData = async (month: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    try {
      const activityList = activityDataList;
      // 
      const dates: string[] = [];
      const activityData: any[] = [];
      for (var i = 0; i < activityList.length; i++) {
        let rule: any = []
        let rulesdatanew: any = [];
        let ownerId = JSON.parse(activityList[i].activityOwner);
        if (activityList[i].activityType == '2' && ownerId.id == browserdata.get("userId")) {
          if (activityList[i].activityRepeatRule == "") {

            rulesdatanew.push(activityList[i].activityStartDate);
          } else {
            let ruleData = activityList[i].activityRepeatRule as string;
            const ruleArray = RRule.fromString(ruleData);
            // 
            rulesdatanew = ruleArray.between(datetime(new Date(month).getFullYear(), new Date(month).getMonth() + 1, new Date(month).getDate()), datetime(new Date(month).getFullYear(), new Date(month).getMonth() + 1, new Date(month).getDate() + 30));
          }
        }


        // 
        const rulesdata: any = []
        rulesdatanew.filter((data: any) => {
          if (data != '') {
            data = new Date(data);
            const currentDate = new Date().getDate();
            const currentMonth = new Date().getMonth() + 1;
            const currentYear = new Date().getFullYear();
            const newDate = data.getDate();
            const newMonth = data.getMonth() + 1;
            const newYear = data.getFullYear();
            const fullCurrentdate = new Date(currentYear + "-" + currentMonth + "-" + currentDate);
            const fullNewDate = new Date(newYear + "-" + newMonth + "-" + newDate);
            // 
            if (fullCurrentdate <= fullNewDate) {
              rulesdata.push(data)
            } else {
            }
          }


        })

        // 
        for (var j = 0; j < rulesdata.length; j++) {
          if (!dates.includes(rulesdata[j].toDateString())) {
            // 
            dates.push(rulesdata[j].toDateString())
            const data: any = {}
            const arrayList = []
            arrayList.push(activityList[i])
            data.date = rulesdata[j].toDateString()
            data.arrayList = arrayList


            activityData.push(data)

          } else {
            // 
            for (var k = 0; k < activityData.length; k++) {
              if (rulesdata[j].toDateString() == activityData[k].date) {
                let sharedUsers = JSON.parse(activityList[i].activitySharedUsers)
                activityData[k].arrayList.push(activityList[i])

              }

            }
          }
        }
      }
      // 
      setarrayEvent(activityData)
    }
    catch (error) {
      console.log(error)
    }

  }

  function getTodoList(date: any) {

    const day = date.getDate();
    const month = date.getMonth();
    let datedata = arrayEvent.find((v: any) => { console.log("ddd", day == new Date(v.date).getDate()); if (day == new Date(v.date).getDate() && month == new Date(v.date).getMonth()) { return v } })

    if (datedata != undefined) {
      switch (day) {
        case new Date(datedata.date).getDate():
          
          let valueData = datedata.arrayList.map((s: any) => { return { "time": s.time = "Available", color: '#0066ff' } })[0];
          return [valueData]
        default:
          return [];
      }
    }
    else {
      return [];
    }



  }


  function renderCell(date: any) {
    const list = getTodoList(date);
    
    const displayList = list.filter((item, index) => index < 3);

    if (list.length) {
      const moreCount = list.length - displayList.length;
      const moreItem = (
        <li >
          <Whisper
            placement="top"
            trigger="click"
            speaker={
              <Popover>
                {list.map((item, index) => (
                  <p key={index}>
                    <b>{item.time}</b>
                  </p>
                ))}
              </Popover>
            }
          >
            <a>{moreCount} more</a>
          </Whisper>
        </li>
      );

      return (
        <ul className="calendar-todo-list">
          {displayList.map((item, index) => (
            <li style={{ fontSize: '7px' }} key={index}>
              <Badge style={{ backgroundColor: item.color }} /> <b>{item.time}</b>
            </li>
          ))}
          {moreCount ? moreItem : null}
        </ul>
      );
    }

    return null;
  }



  return (
    <>
      {filterevent == true ? <HomeFeed /> : <><div className={styles.availabilitySharedNoAccou}>
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }} className={styles.leftAlign}>
          <b >Motus</b>
        </div>

        <div style={{ paddingLeft: '16px', paddingRight: '16px' }} className={styles.leftAlign}>
          <b className={styles.jodyWilliamsIsContainer}>Schedule</b>
          <span className={styles.flr}>

            <img onClick={() => { setfilterevent(true) }} className={styles.iconListChild} alt="" src="/frame-20.svg" />
            <img className={styles.iconListChild} alt="" src="/frame-21.svg" />

          </span>
        </div>


        <div style={{ paddingLeft: '16px', paddingRight: '16px' }} className={styles.datePicker}>
          <>
            <style>
              {`
      .bg-gray {
        background-color: rgba(242, 242, 242, 0.3);
      }
      .rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
        width: 100%;
        height: 100px;
        overflow: hidden;
    }
    .rs-calendar-bordered .rs-calendar-table {
    border: 0px solid #e5e5ea; 
    /* border: 1px solid var(--rs-border-primary); */
    border-radius: 6px;
}
.rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
  height: 55px;
  // width: 55px;
}
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
  // color: #fff;
  color: #3498ff;
  width: 20px;
  height: 20px;
  background-color: #f8f8f8 !important;
  /* background-color: var(--rs-calendar-today-bg); */
  /* border-radius: 50%; */
  margin-left: auto;
  margin-right: auto;
}
.rs-calendar-panel .rs-calendar-header-month-toolbar {
  width: 0 auto !important;
}
.rs-calendar-header-month-toolbar {
  float: none;
}
.rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
  float: right;
  display: none;
}

      `}
            </style>
            <Calendar compact bordered onMonthChange={(month: any) => { updateMonthData(month) }} cellClassName={(date) => (date.getDay() % 2 ? 'bg-gray' : undefined)} renderCell={renderCell} />
          </>

        </div>






      </div>
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <div className={styles.cardOuter}>
            <div className={styles.card}>
              <div className={styles.container}>
                <div className={styles.cdAlign}>

                  <div className={styles.fl}> <div className={styles.messagesAvatar}>
                    <img
                      className={styles.contactPhotoIcon}
                      alt=""
                      src="/motuscircle.png"
                    />
                  </div></div>
                  <div className={styles.fr}><b className={styles.joinRichOn}><span className={styles.p8}>Join Rich on Motus</span></b></div>
                  <div className={styles.cl} />

                </div>

                <div className={styles.p10} >
                  <div className={styles.alignCenter}>
                    <span className={styles.alignCenter}>Sign up to Motus to schedule rides of your own.</span>
                  </div>

                </div>
                <div className={styles.cdAlign}>
                  <div className={styles.buttonPrimary} style={{ width: "100%" }} onClick={checkAndRedirectToAppCreateAccoount}>
                    <b className={styles.jodyWilliamsIsContainer} style={{ color: 'white' }}>Create account</b>

                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>
      </>}
    </>
  );
};

export default AvailabilitySharedNoAccou;
