import {
  SyntheticEvent,
  useState,
} from 'react';
import { FunctionComponent } from "react";
import styles from "./GetSignIn.module.css";
import { Container } from "@material-ui/core";

import ApiCalendar from 'react-google-calendar-api';

const config = {
    "clientId": "698185071274-qe0cjpk6c0isre2o1vu43vq6grg4pi59.apps.googleusercontent.com",
    "apiKey": "AIzaSyB7B8CTu3vD23jMjOLfYqIu43pNRJXWkK4",
    "scope": "https://www.googleapis.com/auth/calendar",
    "discoveryDocs": [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ]
}

const apiCalendar = new ApiCalendar(config)

const TestDemo = () => {
  const [events, setEvents] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const handleItemClick = (event: SyntheticEvent<any>, name: string): void => {
    if (name === 'sign-in') {
      apiCalendar.handleAuthClick()
    } else if (name === 'sign-out') {
      apiCalendar.handleSignoutClick();
    }
  };

  return (
    <div>
      <div style={{ padding: "0.5em",textAlign: 'center'}}>
        <button className={styles.btn} onClick={(e) => handleItemClick(e, "sign-in")}>sign-in</button>
        {/* <button onClick={(e) => handleItemClick(e, "sign-out")}>
          sign-out
        </button> */}
      </div>
      {/* <div style={{ padding: "0.5em" }}>
        <button
          onClick={(e) => {
            const eventFromNow: object = {
              summary: "Poc Dev From Now",
              time: 480,
            };

            apiCalendar
              .createEventFromNow(eventFromNow)
              .then((result: object) => {
                
              })
              .catch((error: any) => {
                
              });
          }}
        >
          Create Event from now
        </button>
      </div> */}
      <div style={{ padding: "0.5em",textAlign:'center' }}>
        <button className={styles.btn}
          onClick={(e) => {
            apiCalendar.listUpcomingEvents(10).then(({ result }: any) => {
              
              setEvents(result.items);
            });
          }}
        >
          List upcoming events
        </button>
        <div className={styles.wrap}>
          <h4>Events</h4>
          <div style={{padding:"10%"}}>
          {events.length === 0 && <p style={{textAlign:'left'}}>No events to show</p>}
          {events.map((event) => (
            
            <p style={{textAlign:'left'}} key={event['id']}>{JSON.stringify(event)}</p>
            
          ))}
          </div>
        </div>
      </div>
      {/* <div style={{ padding: "0.5em" }}>
        <button
          onClick={(e) => {
            apiCalendar.listCalendars().then(({ result }: any) => {
              
              setCalendars(result.items);
            });
          }}
        >
          List calendars
        </button>
        <div>
          <h4>Calendars</h4>
          {calendars.length === 0 && <p>No calendars to show</p>}
          {calendars.map((calendar) => (
            <p key={calendar['id']}>{JSON.stringify(calendar)}</p>
          ))}
        </div>
      </div> */}
      {/* <div style={{ padding: "0.5em" }}>
        <button
          onClick={(e) => {
            apiCalendar.createCalendar("myCalendar2").then(({ result }: any) => {
              
            });
          }}
        >
          Create calendar
        </button>
       </div> */}
    </div>
  );
}

export default TestDemo