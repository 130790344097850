import {
    useEffect,
    useState
} from 'react';
import styles from "./updateProfile.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import Headertag from './header';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import { LiaRedoSolid } from 'react-icons/lia';
import '../global.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiUrl = "../api";



const UpdateProfile = (props: any) => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [errorstatus, seterrorstatus] = useState(true);
    const [showLoader, setshowLoader] = useState(false);
    const location = useLocation();
    const [userdetails, setusrDetails]: any = useState('');
    const [sumbmitloader, setSumbmitLoader] = useState(false);
    const [shownameinput, setshownameinput] = useState(false);
    const { phone, status } = location.state || {};

    const isMobile = useMediaQuery({ maxWidth: 768 });
    const isDesktop = useMediaQuery({ minWidth: 769 });

    const items = [{ name: 'Name', value: userdetails?.firstName && userdetails?.lastName ? userdetails?.firstName + '' + userdetails?.lastName : '', navigate: '', method: '' }, { name: 'Phone Number', value: phoneNumber, navigate: '', method: '' }, { name: 'Working Hours', value: '', navigate: '/generalavailability', method: '' }, { name: 'Calendar Access', value: '', navigate: '/googlecalender', method: '' }, { name: 'Logout', value: '', navigate: '', method: 'handleSignout' }, { name: 'Delete Account', value: '', navigate: '', method: '' }];

    useEffect(() => {
        // console.log(location.state)
        // if(location.state.value == 'Name'){
        //     setshownameinput(true);
        // }else{
        //     setshownameinput(false);
        // }
        setshowLoader(true);
        getUser(phone);

    }, [])

    const getUser = async (phoneNumber: any) => {
        const CookeData = Cookies.get('phoneNumber');
        let cookieData = CookeData ? JSON.parse(CookeData) : {};
        setPhoneNumber(cookieData.phone);
        let user = ''
        try {
            let modifiedNumber = cookieData.phone.replace("+", "");
            console.log(`${apiUrl}/draftEvent/user/` + modifiedNumber)
            const responseData = await axios.get(`${apiUrl}/draftEvent/user/` + modifiedNumber);

            user = responseData['data'].Items[0];
            setFirstname(responseData['data'].Items[0].firstName);
            setLastname(responseData['data'].Items[0].lastName);
            setusrDetails(responseData['data'].Items[0]);
            setshowLoader(false);
            redirect(responseData['data'].Items[0], cookieData.phone)
        } catch (err: any) {
            setusrDetails(undefined)
            setshowLoader(false);
            redirect(undefined, cookieData.phone)
        }
    }

    const redirect = (findUser: any, phone: any) => {

        let user = null;

        // Check if findUser exists and has required fields
        if (findUser && findUser.firstName && findUser.lastName) {
            setFirstname(findUser.firstName)
            setLastname(findUser.lastName)
            // Retrieve existing cookie data if available
            const CookeData = Cookies.get('phoneNumber');
            let cookieData = CookeData ? JSON.parse(CookeData) : {};
            let generalActivity = undefined;
            let calendertoken = undefined;


            if (findUser.generalAvailabilityTime) {
                generalActivity = findUser.generalAvailabilityTime
            }
            if (findUser.calendarToken) {
                calendertoken = findUser.calendarToken
            }

            if (cookieData.generalAvailabilityTime) {
                generalActivity = cookieData.generalAvailabilityTime;
                findUser.generalAvailabilityTime = cookieData.generalAvailabilityTime;

            }
            if (cookieData.calendarToken) {
                calendertoken = cookieData.calendarToken;
                findUser.calendarToken = cookieData.calendarToken;
            }

            // Only set generalAvailabilityTime and calenderToken if they don't exist in the cookie
            const dataToSet = {
                firstName: findUser.firstName,
                lastName: findUser.lastName,
                phone: phone,
                generalAvailabilityTime: generalActivity,
                calendarToken: calendertoken,
                id: findUser.id
            };

            Cookies.set('phoneNumber', JSON.stringify(dataToSet), { expires: 100 });
            user = findUser;
        } else {
            // If no findUser, check for cookie data
            const CookeData = Cookies.get('phoneNumber');
            if (CookeData) {
                user = JSON.parse(CookeData);
                setFirstname(user?.firstName)
                setLastname(user?.lastName)
            }
        }
        setshowLoader(false)

        if (user && user.firstName && user.lastName && status == false) {

            // Check generalAvailabilityTime and calenderToken for both findUser and cookieData
            if (user.generalAvailabilityTime) {
                if (user.calendarToken) {

                    const CookeExpireData = Cookies.get('phoneNumber');
                    let cookieData = CookeExpireData ? JSON.parse(CookeExpireData) : {};
                    let calnderTok = typeof user.calendarToken == 'string' ? JSON.parse(user.calendarToken) : user.calendarToken

                    if (TokenexpiryDate(calnderTok.expires_in, calnderTok.token_generate_Date) == true) {
                        navigate('/');
                    } else {
                        navigate('/googlecalender', { state: { status: false } });
                    }

                } else {

                    navigate('/googlecalender', { state: { status: false } });
                }
            } else {
                navigate('/generalavailability', { state: { status: false } });
            }
        }
    }

    const handlePhoneChange = (e: any) => {
        const value = e.target.value;
        setPhoneNumber(e.target.value);

        // Regex for 10-digit number validation
        const phoneRegex = /^\d{10}$/;

        if (value === '' || phoneRegex.test(value)) {
            setError(''); // Clear error if valid
            seterrorstatus(false)
        } else {
            setError('Please enter a valid 10-digit number');
            seterrorstatus(true)
        }

    };

    const handlefistname = ((val: any) => {

        setFirstname(val.target.value);
    })
    const handlelastname = ((val: any) => {
        setLastname(val.target.value);
    })

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (phoneNumber && firstname && lastname) {
            setSumbmitLoader(true);

            const CookeData = Cookies.get('phoneNumber');
            let cookieData = CookeData ? JSON.parse(CookeData) : {};

            const dataToSet = {
                firstName: firstname,
                lastName: lastname,
                phone: cookieData.phone,
                generalAvailabilityTime: cookieData.generalAvailabilityTime,
                calendarToken: cookieData.calendarToken,
                id: cookieData.id
            };
            Cookies.set('phoneNumber', JSON.stringify(dataToSet), { expires: 100 });
            if (userdetails) {

                try {
                    const phoneNosign = userdetails.phone.startsWith("+") ? userdetails.phone.slice(1) : userdetails.phone;

                    const response = await axios.put(`${apiUrl}/draftEvent/updateUser`, { phone: phoneNosign, firstName: firstname, lastName: lastname });
                    toast.success("Personal Info updated");
                    setSumbmitLoader(false);
                } catch (err) {
                    toast.error("Error");
                    setSumbmitLoader(false);
                }

            } else {
                setSumbmitLoader(false);
            }
            checkwhileSkip(userdetails, phoneNumber)
        }
    };

    const checkallfields = () => {
        if (phoneNumber && firstname && lastname) {
            return false
        } else {
            return true
        }
    }


    const skipPage = () => {

        checkwhileSkip(userdetails, phoneNumber)
    }

    const checkwhileSkip = (findUser: any, phone: any) => {
        let user = null;

        // Check if findUser exists and has required fields
        if (findUser) {
            // Retrieve existing cookie data if available
            const CookeData = Cookies.get('phoneNumber');
            let cookieData = CookeData ? JSON.parse(CookeData) : {};
            let generalActivity = undefined;
            let calendertoken = undefined;
            if (cookieData.generalAvailabilityTime) {
                generalActivity = cookieData.generalAvailabilityTime
            }
            if (cookieData.calendarToken) {
                calendertoken = cookieData.calendarToken
            }

            if (findUser.generalAvailabilityTime) {
                generalActivity = findUser.generalAvailabilityTime
            }
            if (findUser.calendarToken) {
                calendertoken = findUser.calendarToken
            }

            // Only set generalAvailabilityTime and calenderToken if they don't exist in the cookie
            const dataToSet = {
                firstName: findUser.firstName,
                lastName: findUser.lastName,
                phone: phone,
                generalAvailabilityTime: generalActivity,
                calendarToken: calendertoken,
                id: findUser.id
            };

            Cookies.set('phoneNumber', JSON.stringify(dataToSet), { expires: 100 });
            user = findUser;
        } else {
            // If no findUser, check for cookie data
            const CookeData = Cookies.get('phoneNumber');
            if (CookeData) {
                user = JSON.parse(CookeData);
            }
        }


        if (user) {
            // Check generalAvailabilityTime and calenderToken for both findUser and cookieData
            if (user.generalAvailabilityTime) {
                if (user.calendarToken) {
                    const CookeExpireData = Cookies.get('phoneNumber');
                    let cookieData = CookeExpireData ? JSON.parse(CookeExpireData) : {};
                    let calnderTok = typeof user.calendarToken == 'string' ? JSON.parse(user.calendarToken) : user.calendarToken

                    if (TokenexpiryDate(calnderTok.expires_in, calnderTok.token_generate_Date) == true) {
                        if (props.showstript != false) {
                            navigate('/');
                        }

                    } else {
                        if (props.showstript != false) {
                            navigate('/googlecalender', { state: { status: false } });
                        }
                    }
                } else {
                    if (props.showstript != false) {
                        navigate('/googlecalender', { state: { status: false } });
                    }
                }
            } else {
                if (props.showstript != false) {
                    navigate('/generalavailability', { state: { status: false } });
                }
            }
        }
    }

    const TokenexpiryDate = (e: any, d: any) => {
        const generateTime = new Date(d);

        // Add expiresIn (in seconds) to generateTime
        const expirationTime = new Date(generateTime.getTime() + e * 1000);

        // Get the current date and time
        const currentTime = new Date();

        // Check if current time is greater than expiration time
        if (currentTime > expirationTime) {
            return false; // Expired
        } else {
            return true; // Not expired
        }
    }



    return (
        <>
            {props.showstript != false ? <div><Headertag showHeader={status} dropDownShow={false} /></div> : <></>}

            {showLoader ? <div className={styles.centerloader}><div className={styles.loader}></div></div> :
                <div className={styles.loginContainer}>
                    <form className={styles.loginform} onSubmit={handleSubmit}>
                        <div className={styles.phoneinputmargin}>
                            <div style={{ textAlign: 'left' }}>
                                <label>First name</label>
                                <p style={{ color: '#ccc' }}>Use the name your friends will know</p>
                            </div>
                            <div className={styles.phoneinput}>
                                <input
                                    type="text"
                                    value={firstname}
                                    onChange={handlefistname}
                                    placeholder="First name"
                                    className={styles.phonenumber}
                                />

                            </div>
                        </div>
                        <div className={styles.phoneinputmargin}>
                            <div style={{ textAlign: 'left' }}>
                                <label>Last name</label>
                            </div>
                            <div className={styles.phoneinput}>
                                <input
                                    type="text"
                                    value={lastname}
                                    onChange={handlelastname}
                                    placeholder="Last name"
                                    className={styles.phonenumber}
                                />

                            </div>
                        </div>
                        <div className={styles.phoneinputmargin}>
                            <div style={{ textAlign: 'left' }}>
                                <label>Phone number</label>
                            </div>
                            <div className={styles.phoneinput}>
                                <input
                                    type="text"
                                    value={phoneNumber}
                                    onChange={handlePhoneChange}
                                    placeholder="Phone Number"
                                    className={styles.phonenumber}
                                    disabled
                                />

                            </div>

                        </div>


                        {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
                        <div style={{ padding: '10px' }}>
                            <button type="submit" className={styles.submitbtn} disabled={checkallfields() || sumbmitloader}>
                                Save
                            </button>
                            <ToastContainer />
                        </div>

                        {sumbmitloader == true ? <div className={styles.centerloader}>
                            <div className={styles.spinner}></div>
                        </div> : <></>}
                    </form>
                    {props.showstript != false ? <div style={{ textAlign: 'center', padding: '20px', cursor: 'pointer' }}>
                        {status == true ? <><p ></p></> : <p ><a onClick={() => skipPage()}>Skip</a></p>}

                    </div> : <></>}
                    
                </div>


            }

        </>
    );
}

export default UpdateProfile