import React, { useEffect, useState } from 'react';
import '../global.css'
import MapPopup from './Mappopup';
import { click } from '@testing-library/user-event/dist/click';
import axios from 'axios';
import { toaster } from 'rsuite';
import PlacesAutocomplete from 'react-places-autocomplete';
import styles from './Optionpopup.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// const GOOGLE_MAPS_API_KEY = 'AIzaSyAXrPVn3SjLfblK9zpY_cPZg_LP3Tw3LiM';
const GOOGLE_MAPS_API_KEY = 'AIzaSyCuAPKydbCX6pJUoPE6ldA30FgT4nR_3Jk';
const apiUrl = "../api";

const OptionPopup = (props: any) => {
    const [selectedAdres, setSelectedAdres] = useState('');
    const [inputValue, setInputValue] = useState(props.location);
    const [responsestatus, setresponsestatus] = useState(true);
    const [respontext, setrespontext] = useState('');
    const [address, setAddress] = useState('');
    const [oldaddress, setOldAddress] = useState('');
    const [showLoader, setshowLoader] = useState(false); 
    
    const [selectedPosition, setSelectedPosition] = useState<google.maps.LatLngLiteral | null>(null);
    const [center, setCenter] = useState({
      lat: -34.397, // Initial latitude
      lng: 150.644  // Initial longitude
    });

    useEffect(() => {
        
        setInputValue(selectedAdres);
        setAddress(selectedAdres)
        
      }, [selectedAdres]);

      useEffect(()=>{
        if(props.location){
          
          if(typeof props.location == "string"){
            
            setAddress(props.location);
            setOldAddress(props.location);
            setInputValue(props.location);
          }else{
            // props.location="Vancouver";
            setAddress("Vancouver");
            setOldAddress("Vancouver");
            setInputValue("Vancouver");
            
          }
            
        const geocoder = new window.google.maps.Geocoder();
            
        geocoder.geocode({ address: props.location }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            if(results){
                
                setInputValue(results[0].formatted_address);
                
                const newLocation = results[0].geometry.location;
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();
    
            setCenter({ lat, lng });
            setSelectedPosition({ lat, lng });
            }
               
             
          } else {
            alert('Geocode was not successful for the following reason: ' + status);
          }
        });
    }
      },[])

      const handleSelect = async (value:any) => {
        setAddress(value);
            
            if (!address) return; // Don't proceed if there's no address
        
            const geocoder = new window.google.maps.Geocoder();
            
            geocoder.geocode({ address: value }, (results, status) => {
              if (status === window.google.maps.GeocoderStatus.OK) {
                if(results){
                    
                    setInputValue(results[0].formatted_address);
                    
                    const newLocation = results[0].geometry.location;
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();
        
                setCenter({ lat, lng });
                setSelectedPosition({ lat, lng });
                }
                   
                 
              } else {
                alert('Geocode was not successful for the following reason: ' + status);
              }
            });
         
        // You can also use geocodeByAddress and getLatLng if you want to extract the location data.
      };

    const handleInputChange = (event:any) => {
      setInputValue(event.target.value); // Update state with input value
    };
  
    const handleSubmit = async (event:any) => {
        setshowLoader(true);
      event.preventDefault(); // Prevent page reload
      if(inputValue){
        
        try {
            
            // Post data to the API
            const response = await axios.put(`${apiUrl}/draftEvent`, {id:props.eventId,location:inputValue});
            toast.success("Location updated");
            // let groupobj={
            //     "groupId":props.groupId,
            //     "msg":`\r\n Location: ~${oldaddress}~ ${inputValue}`,
            //     "url":"https://stg.motus.fun/de/"+props.eventId
            // }
            // 
            // const groupresponse = await axios.post('https://bot.motus.fun/wa/updateinfo', groupobj);
            // 
            setresponsestatus(true);
            setrespontext('Success');
            props.closePopup();
            setshowLoader(false);
          
          } catch (error) {
            toast.error("Error");
            setresponsestatus(false);
            setrespontext('Error');
            setshowLoader(false);
            // Handle error response
            // setResponseMessage('Error submitting the form');
          }
      }
      
    };
  

    return (
        // <div className="popup2">
        //   <div className="popup_inner_3">
        <div>
            <div style={{ width: '100%', flexDirection: 'row', display: 'flex', color: '#000' }}>
                <div style={{ width:'100%',textAlign:'center' }}>
                <h4>Location</h4>
                </div>
                <div style={{ flex: 4, textAlign: 'right' }}>
                    <button style={{ background: 'none' }} onClick={props.closePopup}  ><span className="crosssign">
                        {/* <div className="crosssign_circle"></div> */}
                        <div className="crosssign_stem"></div>
                        <div className="crosssign_stem2"></div>
                    </span></button>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
            <div style={{padding:'10px',textAlign:'center'}}>
            <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input style={{textAlign:'center',width:'80%',height:'50px'}} {...getInputProps({ placeholder: 'Search Places ...' })} />

          <div style={{cursor:'pointer'}}>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const style = {
                backgroundColor: suggestion.active ? '#a8dadc' : '#fff',
                padding:"5px"
              };
              return (
                <div  {...getSuggestionItemProps(suggestion, { style })}>
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
                        {/* <textarea
                            style={{width:'100%'}}
                            value={inputValue}
                            onChange={handleInputChange} // Update state on input change
                            placeholder="Enter address"
                        ></textarea>
                        <p style={responsestatus ? {color:'green'} : {color:'red'}}>{respontext}</p>
                       
                    */}
            </div>
            <div>
                <div>
                    <h5 style={{textAlign:'center'}}>OR</h5>
                    <MapPopup setSelectedAdres={setSelectedAdres} position={selectedPosition} />
                </div>

            </div>
            <div style={{textAlign:'center',width:'100%'}}>
            <button className={styles.submitbtn} type="submit">Update Location</button>
            </div>
            {showLoader ? <div className={styles.centerloader}><div className={styles.loader}></div></div> : <div style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '20px',color:'green' }}></div>}
            </form>
            <ToastContainer />
        </div>
        
        // </div>   
    )
}
export default OptionPopup
