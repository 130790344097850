import { FunctionComponent, useCallback, useEffect, useState } from "react";
import styles from "./NoAccountAcceptInviteE1.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import axios from 'axios';

const apiUrl = "../api";
const NoAccountAcceptInviteE1: FunctionComponent = () => {
  const [firstName,setFirstname] = useState('');
  const [lastName,setLastname] = useState('');
  const [phoneNumber,setPhoneNumber] = useState(0);
  const [activityId,setActivityId] = useState('');
  const [browserdata,setBrowserData]:any = useSearchParams();
  const [shareusersData,setshareusersData]:any =useState();

  const [inputItems, setInputItems] = useState([]);
  const [inputItemsLname, setInputItemsLname] = useState([]);
  const [buttonValue, setbuttonValue] = useState('Accept');
  const navigate = useNavigate();
  useEffect(() => {
    
    if(activityId =="" ){
      fetchTodos()
    }

    if (localStorage !== null) {
      const storedValue =localStorage.getItem('autocompleteItems');
      const storedValueLastName =localStorage.getItem('autocompleteItemslastname');
      if (storedValue !== null) {
    const savedItems = JSON.parse(storedValue) || [];
     
    setInputItems(savedItems);
  }
  if (storedValueLastName !== null) {
    const savedItems = JSON.parse(storedValueLastName) || [];
     
    setInputItemsLname(savedItems);
  }
    }
  },[]);


  async function fetchTodos() {
    let data=localStorage.getItem('SharedUsersData');
    
    localStorage.setItem('Phonenumber',browserdata.get("phone").trim())
    let usersData= data !== null ?JSON.parse(data):'';
    
    setshareusersData(usersData);
    
    if(usersData.length > 0){
      
    let findbyPhoneno=usersData.find((val:any)=> val.phone == '+'+browserdata.get("phone").trim());
    
      setFirstname(findbyPhoneno.firstName);
      setLastname(findbyPhoneno.lastName);
      // setPhoneNumber(Number(browserdata.get("phone")));
    }
    try {
     let activityNewId:any = localStorage.getItem("activityId");
      setActivityId(activityNewId)
    } catch (err) {
      
    }
  } 
  const onInputContainerClick = useCallback(() => {
    // Please sync "Sign Up / Create Account" to the project
  }, []);

  const onButtonPrimaryClick = async () => {
    if (firstName.trim() !== '') {
      const newItems:any = [...inputItems.filter(item => item !== firstName), firstName];
      setInputItems(newItems);
      localStorage.setItem('autocompleteItems', JSON.stringify(newItems));
    }
    if (lastName.trim() !== '') {
      const newItems:any = [...inputItemsLname.filter(item => item !== lastName), lastName];
      setInputItemsLname(newItems);
      localStorage.setItem('autocompleteItemslastname', JSON.stringify(newItems));
    }
    
    let value=localStorage.getItem('SharedUsersData');
    let localUserData= value !== null ?JSON.parse(value):'';
    let updateFLname=localUserData.map((val:any)=>{
      if(val.phone == '+'+browserdata.get("phone").trim()){
        
        val.firstName=firstName;
        val.lastName=lastName;
        return val
      }else{
        return val
      }
    });
    
    let updateActivityData = {
      id :browserdata.get("eventId"),
      activitySharedUsers: JSON.stringify(updateFLname)
    }
    
    const todoData = await axios.put(`${apiUrl}/draftEvent/activity/update`,{activitySharedUsers: JSON.stringify(updateFLname)});
    
    navigate("/event-event-details-no-account")
    // Please sync "Sign Up / Create Account" to the project
  };

  const onArrowBackTextClick = useCallback(() => {
    
    navigate('/event-event-details-browser1?id=' + browserdata.get("eventId") + '&phone='+browserdata.get("phone").trim());
  }, []);
  const handleFirstName = (event:any) =>{
    
    setFirstname(event.target.value);
    
  }
  const handleItemSelect = (value:any) => {
    setFirstname(value);
  };
  const handleItemSelectlastName = (value:any) => {
    setLastname(value);
  };
  const handleInputClick = () => {
    setFirstname(''); // Clear the input value when clicked
  };
  const handleLastName = (event:any) =>{
    setLastname(event.target.value)
  }

  const handleInputlastnameClick = () => {
    setLastname(''); // Clear the input value when clicked
  };
  const handlePhoneNumber = (event:any) =>{
    setPhoneNumber(event.target.value)
  }
  return (
    <div className={styles.noAccountAcceptInviteE}>
      <div style={{padding:'20px'}}>
      <b className={styles.acceptInvite}>Motus</b>
      <div className={styles.arrowBack} onClick={onArrowBackTextClick}>
        arrow_back
      </div>
      </div>
      <div>
        <p style={{fontFamily: "Open Sans"}}>Enter your first and last name to accept the invite.</p>
      </div>
      <div className={styles.form}>
        <div className={styles.input}>
          <div className={styles.label} style={{margin: '0 auto',textAlign: 'left',width: '342px',alignItems: 'baseline',padding: '10px 0px 10px 0px'}}>
            <b className={styles.firstName}>First name</b>
          </div>
          <div className={styles.input1} onClick={onInputContainerClick}>
             <Autocomplete
        getItemValue={(item:any) => item}
        items={inputItems}
        value={firstName}
        onChange={handleFirstName}
        onSelect={handleItemSelect}
        wrapperStyle={{
          width: "100%",
        }}
        inputProps={{
          style: {
            backgroundColor: "whitesmoke",
            border: 'none',
            width: "100%",
            padding: "2%"
          },
          onFocus: handleInputClick
        }}
        renderItem={(item, isHighlighted) => (
          <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
            {item}
          </div>
        )}
      />
        
          </div>
          
        </div>
        <div className={styles.input}>
          <div style={{margin: '0 auto',textAlign: 'left',width: '342px',alignItems: 'baseline',padding: '20px 0px 10px 0px'}}>
          <b className={styles.firstName}>Last name</b>
          </div>
          <div className={styles.input3}>
            <Autocomplete
        getItemValue={(item:any) => item}
        items={inputItemsLname}
        value={lastName}
        onChange={handleLastName}
        onSelect={handleItemSelectlastName}
        wrapperStyle={{
          width: "100%",
        }}
        inputProps={{
          style: {
            backgroundColor: "whitesmoke",
            border: 'none',
            width: "100%",
            padding: "2%"
          },
          onFocus: handleInputlastnameClick
        }}
        renderItem={(item, isHighlighted) => (
          <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
            {item}
          </div>
        )}
      />
          
          </div>
        </div>
      </div>
    
      <div className={styles.buttonPrimary} style={{ backgroundColor: browserdata.get("value") == 'ACCEPT' ? '#00BE9A' : '#de6666' }} onClick={onButtonPrimaryClick}>
        <b className={styles.firstName}>{browserdata.get("value") == 'ACCEPT' ? 'Accept' : 'Decline'}</b>
      </div>
      
      
    </div>
  );
};

export default NoAccountAcceptInviteE1;
