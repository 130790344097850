import React, { useEffect, useState } from "react";
import styles from "./GeneralAvailablity.module.css"; // Assuming you have a CSS file for styling
import moment from "moment-timezone";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Cookies from "js-cookie";
import Headertag from "./header";
import { useNavigate, useLocation } from 'react-router-dom';
import { RRule, RRuleSet, rrulestr, Weekday } from 'rrule';
import { MdAddCircleOutline, MdOutlineRemoveCircleOutline } from "react-icons/md";
// import { getUser, listActivities, listUsers } from "../graphql/queries";
// import { updateUser } from "../graphql/mutations";
import axios from 'axios';
import Autocomplete from "react-autocomplete";
import { FiEdit2 } from "react-icons/fi";
import { CiCircleRemove } from "react-icons/ci";
import { useMediaQuery } from 'react-responsive';
import '../global.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiUrl = "../api";


interface TimeSlot {
    start: string;
    end: string;
}

interface WeeklyHours {
    day: string;
    timezone: string;
    StartDateTime: string;
    isAvailable: boolean;
    slots: TimeSlot[];
}

interface SpecificHour {
    start: string;
    end: string;
}

// List of timezones (you can expand this based on your needs)
const timezones = [
    { label: "Select Timeszone", value: "" },
    { label: "India Standard Time (IST)", value: "Asia/Calcutta" },
    { label: "Eastern Time (Canada)", value: "America/Toronto" },
    { label: "Pacific Time (Canada)", value: "America/Vancouver" },
    { label: "Mountain Time (Canada)", value: "America/Edmonton" },
    { label: "Central Time (Canada)", value: "America/Winnipeg" },
    { label: "Atlantic Time (Canada)", value: "America/Halifax" },
    { label: "Newfoundland Time (Canada)", value: "America/St_Johns" }
];

const getDayOfWeek = (dayNumber: number): string => {
    const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    return days[dayNumber];
};

//   const generateWeeklyHours = (): WeeklyHours[] => {
//     const currentDay = new Date().getDay(); // Get current day (0 = Sunday, 6 = Saturday)
//     const weeklyHours: WeeklyHours[] = [];

//     // Loop from the current day and fill the week dynamically
//     for (let i = 0; i < 7; i++) {
//       const dayIndex = (currentDay + i) % 7; // Ensure days wrap around the week
//       const dayName = getDayOfWeek(dayIndex);

//       weeklyHours.push({
//         day: dayName,
//         isAvailable: dayIndex !== 0 && dayIndex !== 6, // Assume Sunday and Saturday are unavailable by default
//         slots: dayIndex !== 0 && dayIndex !== 6 ? [{ start: "09:00", end: "17:00" }] : [], // Example time slots
//       });
//     }

//     return weeklyHours;
//   };

const generateWeeklyHours = (): WeeklyHours[] => {
    const date = new Date();
    const currentDay = new Date().getDay(); // Get current day (0 = Sunday, 6 = Saturday)
    const weeklyHours: WeeklyHours[] = [];

    // Loop from the current day and only up to Saturday (6 = Saturday)
    for (let i = 0; i <= 6; i++) {
        const dayName = getDayOfWeek(i);

        const fullDate = new Date(date); // Create a copy of the current date
        fullDate.setDate(date.getDate() + (i - currentDay));

        weeklyHours.push({
            day: dayName,
            timezone: '',
            StartDateTime: fullDate.toISOString().split('T')[0],
            isAvailable: true,
            slots: [{ start: "09:00", end: "10:00" }],
        });
    }

    return weeklyHours;
};

const WeekHours = (props: any) => {
    // const [weeklyHours, setWeeklyHours] = useState<WeeklyHours[]>([
    //   { day: "SUN", isAvailable: true, slots: [{ start: "09:00", end: "17:00" }] },  // 24-hour format
    //   { day: "MON", isAvailable: false, slots: [] },
    //   { day: "TUE", isAvailable: true, slots: [{ start: "09:00", end: "17:00" }] },  // 24-hour format
    //   { day: "WED", isAvailable: true, slots: [{ start: "09:00", end: "17:00" }] },  // 24-hour format
    //   { day: "THU", isAvailable: true, slots: [{ start: "09:00", end: "17:00" }] },  // 24-hour format
    //   { day: "FRI", isAvailable: true, slots: [{ start: "09:00", end: "17:00" }] },  // 24-hour format
    //   { day: "SAT", isAvailable: false, slots: [] },
    // ]);
    const [weeklyHours, setWeeklyHours] = useState<WeeklyHours[]>([]);
    const [weeklySpecific, setweeklySpecific] = useState<SpecificHour[]>([]);
    // const [selectedTimezone, setSelectedTimezone] = useState<string>("UTC");
    const [selectedDates, setSelectedDates] = useState<Date[]>([]);
    const [updateddDates, setupdateddDates] = useState<any[]>([]);
    const [showCalender, setShowCalender] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [showSavebutton, setShowsavebutton] = useState(false);
    const [userdata, setuserData]: any = useState<any[]>();
    const [sumbmitloader, setSumbmitLoader] = useState(false);
    const [phonenumber, setphoneNumber] = useState();
    const [value, setValue] = useState(''); // Value of the selected timezone
    const [selectedTimezone, setSelectedTimezone] = useState<any>(null);
    const [showAutocomplete, setshowAutocomplete] = useState(true);
    const [userHours, setuserHours] = useState(false);
    const [startslots, setstartSlots] = useState("09:00");
    const [endslots, setendSlots] = useState("10:00");
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const isDesktop = useMediaQuery({ minWidth: 769 });

    const navigate = useNavigate();
    const location = useLocation();
    const { status } = location.state || {};

    useEffect(() => {
        getUser();
        const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        handleTimezoneChangedefault(systemTimezone);
        // Initialize weekly hours dynamically based on the current week

        setWeeklyHours(generateWeeklyHours());
        addSpecificHour()
    }, []);

    const getUser = async () => {
        const CookeData = Cookies.get('phoneNumber');
        let cookieData = CookeData ? JSON.parse(CookeData) : {};
        setphoneNumber(cookieData.phone);


        try {
            let modifiedNumber = cookieData.phone.replace("+", "");
            const responseData = await axios.get(`${apiUrl}/draftEvent/user/` + modifiedNumber);

            setuserData(responseData['data'].Items[0]);
            setShowCalender(true)
            if (responseData['data'].Items[0].generalAvailabilityTime) {

                const AvailablityTime = JSON.parse(responseData['data'].Items[0].generalAvailabilityTime);
                console.log(AvailablityTime)
                if (AvailablityTime.recurring[0].timezone) {
                    
                    const selected: any = timezones.find(item => item.value === AvailablityTime.recurring[0].timezone.value);
                    setSelectedTimezone(selected);
                    setshowAutocomplete(true);
                } else {
                    const selected: any = timezones.find(item => item.value === "America/Vancouver");
                    setSelectedTimezone(selected);
                    setshowAutocomplete(true);
                }


                setWeeklyHours(transformSchedule(AvailablityTime.recurring));

                const selectedHours = AvailablityTime.one_off.map((val: any) => new Date(val.start));

                setSelectedDates(selectedHours)
                // setSelectedDates([
                //     new Date("2024-11-26T08:29:47.747Z"),
                //     new Date("2024-11-27T08:29:47.747Z")
                // ])
                setSpecificHours(setPreviosHours(AvailablityTime.one_off))
            } else {
                const selected: any = timezones.find(item => item.value === "America/Vancouver");
                setSelectedTimezone(selected);
                setshowAutocomplete(true);

            }

        } catch (err: any) {
            setuserData(undefined);
            const selected: any = timezones.find(item => item.value === "America/Vancouver");
            setSelectedTimezone(selected);
            setshowAutocomplete(true);

        }
        // const todoData = await client.graphql({
        //     query: listUsers
        // });
        // 
        // let user = todoData['data'].listUsers.items
        // const UserData = user.find(val => val.phone == cookieData.phone);
        // setuserData(UserData)
        // 
    }


    // Helper function to get the day of the week abbreviation (SUN, MON, etc.)
    const getDayAbbreviation = (weekday: any) => {

        const days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
        const fullDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
        const findVal = fullDays.find((val, index) => val === weekday) ? days[fullDays.findIndex((val, index) => val === weekday)] : null;

        return findVal;
    };

    // Current date for StartDateTime (assuming we are starting from today's date)
    const startDates = new Date();

    // Function to transform the input
    const transformSchedule = (input: any) => {
        const result: any = [];

        // 

        // For each weekday in the input
        input.forEach((item: any) => {
            const dayAbbreviation = getDayAbbreviation(item.weekday);
            const slotsArr = result.find((day: any) => day.day === dayAbbreviation);
            // 
            // If the day is already in the result, add the slot to it
            if (slotsArr) {
                slotsArr.slots.push({ start: item.start.split(":").slice(0, 2).join(":"), end: item.end.split(":").slice(0, 2).join(":") });
            } else {
                // Otherwise, create a new entry for the day
                result.push({
                    day: dayAbbreviation,
                    timezone: item.timezone || "",
                    StartDateTime: startDates.toISOString().slice(0, 10),  // Using the current date
                    isAvailable: true,
                    slots: [{ start: item.start.split(":").slice(0, 2).join(":"), end: item.end.split(":").slice(0, 2).join(":") }]
                });
            }
        });
        // 
        const arr2Days = result.map((item: any) => item.day);
        const missingDays = generateWeeklyHours().filter(item => !arr2Days.includes(item.day));

        missingDays.forEach(item => {
            result.push({
                day: item.day,
                timezone: "Asia/Calcutta",
                StartDateTime: item.StartDateTime,
                isAvailable: false,
                slots: [{ start: "09:00", end: "10:00" }],
            });
        });

        const dayOrder = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

        // Sort the array based on the order of days
        result.sort((a: any, b: any) => dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day));
        return result;
    };


    const setPreviosHours = (array: any) => {
        // Function to format the time
        const formatTime = (date: any) => {
            const hours = date.getUTCHours().toString().padStart(2, '0');
            const minutes = date.getUTCMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
        };

        const removeDuplicates = (arr: any[]) => {
            const uniqueArray = arr.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.start === value.start && t.end === value.end
                ))
            );
            return uniqueArray;
        };

        // Convert input data
        const output = array.map((item: any) => ({
            start: formatTime(new Date(item.start)),
            end: formatTime(new Date(item.end))
        }));

        return removeDuplicates(output)
    }


    const [specificHours, setSpecificHours] = useState<SpecificHour[]>([]);

    const toggleDayAvailability = (index: number) => {
        const updatedHours = [...weeklyHours];
        updatedHours[index].isAvailable = !updatedHours[index].isAvailable;
        setWeeklyHours(updatedHours);
    };

    //   const addTimeSlot = (index: number) => {
    //     const updatedHours = [...weeklyHours];
    //     updatedHours[index].slots.push({ start: "18:00", end: "19:00" });
    //     setWeeklyHours(updatedHours);
    //   };

    // const toggleDayAvailability = (index: number) => {
    //     const updatedHours = [...weeklyHours];
    //     updatedHours[index].isAvailable = !updatedHours[index].isAvailable;
    //     setWeeklyHours(updatedHours);
    //   };

    const addTimeSlot = (index: number) => {
        const updatedHours = [...weeklyHours];
        const daySlots = updatedHours[index].slots;

        const lastSlot = daySlots[daySlots.length - 1] || { start: "09:00", end: "10:00" };
        const newStart = addOneHour(lastSlot.end);
        const newEnd = addOneHour(newStart);

        daySlots.push({ start: newStart, end: newEnd });
        setWeeklyHours(updatedHours);
    };

    const addOneHour = (time: string) => {
        const [hours, minutes] = time.split(":").map(Number);
        const newHour = (hours + 1) % 24;
        return `${newHour < 10 ? `0${newHour}` : newHour}:${minutes < 10 ? `0${minutes}` : minutes}`;
    };

    const validateTime = (start: string, end: string) => {
        return start < end; // Check if start is earlier than end
    };

    const removeTimeSlot = (dayIndex: number, slotIndex: number) => {
        const updatedHours = [...weeklyHours];
        updatedHours[dayIndex].slots.splice(slotIndex, 1);
        setWeeklyHours(updatedHours);
    };

    const addSpecificHour = () => {
        setSpecificHours([...specificHours, { start: "09:00", end: "17:00" }]);
    };

    const addSpecificHoursingle = (index: any) => {

        const updatedHours = [...weeklySpecific];
        const daySlots = updatedHours;

        const lastSlot = daySlots[daySlots.length - 1] || { start: "09:00", end: "10:00" };
        const newStart = addOneHour(lastSlot.end);
        const newEnd = addOneHour(newStart);

        daySlots.push({ start: newStart, end: newEnd });


        setweeklySpecific(daySlots);
    };

    const removeSpecificHour = (index: number) => {
        const updatedHours = [...specificHours];
        updatedHours.splice(index, 1);

        setSpecificHours(updatedHours);
    };

    const removeSpecificHoursingle = (index: number) => {
        const updatedHours = [...weeklySpecific];
        updatedHours.splice(index, 1);

        setweeklySpecific(updatedHours);
    };

    const updateSlotTime = (dayIndex: number, slotIndex: number, field: "start" | "end", value: string) => {
        const updatedHours = [...weeklyHours];
        const currentSlot = updatedHours[dayIndex].slots[slotIndex];

        // Update the specific field (start or end)
        currentSlot[field] = value;

        // // Validation: ensure end time is later than start time
        // if (field === "start" && !validateTime(value, currentSlot.end)) {
        //     alert("Start time cannot be later than end time");
        //     return;
        // }
        // if (field === "end" && !validateTime(currentSlot.start, value)) {
        //     alert("End time must be later than start time");
        //     return;
        // }

        setWeeklyHours(updatedHours); // Update state after validation
    };

    const convertTimeSlotToTimezone = (time: string, timezone: string) => {
        // Assuming `time` is in "HH:mm" 24-hour format
        const localTime = moment.tz(time, "HH:mm", "UTC"); // Assume UTC is the default time
        return localTime.tz(timezone).format("HH:mm");
    };

    const handleTimezoneChangedefault = (e: any) => {
        const newTimezone = e;

        setSelectedTimezone(newTimezone);

        // Update the times in weekly hours according to the new timezone
        const updatedHours = weeklyHours.map((day) => ({
            ...day,
            timezone: day.timezone = newTimezone,
            slots: day.slots.map((slot) => ({
                start: convertTimeSlotToTimezone(slot.start, newTimezone),
                end: convertTimeSlotToTimezone(slot.end, newTimezone),
            }))

        }));

        setWeeklyHours(updatedHours);
    };

    // Function to handle timezone changes
    const handleTimezoneChange = (e: any) => {
        const newTimezone = e.target.value;

        setSelectedTimezone(newTimezone);

        // Update the times in weekly hours according to the new timezone
        const updatedHours = weeklyHours.map((day) => ({
            ...day,
            timezone: day.timezone = newTimezone
            // slots: day.slots.map((slot) => ({
            //     start: convertTimeSlotToTimezone(slot.start, newTimezone),
            //     end: convertTimeSlotToTimezone(slot.end, newTimezone),
            // }))

        }));

        setWeeklyHours(updatedHours);
    };


    //secondslot

    const handleDateChange = (date: Date | null) => {
        if (!date) return; // Handle null date from the date picker

        if (selectedDates.some((d) => d.getTime() === date.getTime())) {
            // Remove the date if already selected
            setSelectedDates(selectedDates.filter((d) => d.getTime() !== date.getTime()));
        } else {
            // Add the new date to the selection
            setSelectedDates([...selectedDates, date]);

        }
    };

    // const handleDateChange = (date: Date | null) => {
    //     if (!date) return; // Handle null date from the date picker

    //     if (!startDate || (startDate && endDate)) {
    //       // If no start date is set, or if there’s already a range, set start date
    //       setStartDate(date);
    //       setEndDate(null); // Reset end date
    //     } else if (startDate && !endDate) {
    //       // If there's a start date but no end date, set end date
    //       if (date >= startDate) {
    //         setEndDate(date); // Set end date if it is after start date
    //       } else {
    //         // If end date is before start date, reset to start date
    //         setStartDate(date);
    //       }
    //     }
    //   };

    //   const highlightDates = [];
    //   if (startDate && endDate) {
    //     const currentDate = new Date(startDate);
    //     while (currentDate <= endDate) {
    //       highlightDates.push(new Date(currentDate)); // Add each date in the range
    //       currentDate.setDate(currentDate.getDate() + 1);
    //     }
    //   }

    // const handleDateChange = (date: Date | null) => {
    //     if (!date) return; // Handle null date from the date picker

    //     // Check if the selected date falls within the current range
    //     if (startDate && endDate && date >= startDate && date <= endDate) {
    //         alert("This date falls within the selected range and cannot be added as an individual selection.");
    //         return; // Do not add the date if it falls within the range
    //     }

    //     // Check if date is part of the selected dates for multi-select
    //     if (selectedDates.some((d) => d.getTime() === date.getTime())) {
    //         // Remove the date if already selected
    //         setSelectedDates(selectedDates.filter((d) => d.getTime() !== date.getTime()));
    //     } else {
    //         // Add the new date to the selection
    //         setSelectedDates([...selectedDates, date]);
    //     }

    //     // Handle range selection
    //     if (!startDate || (startDate && endDate)) {
    //         // If no start date is set, or if there’s already a range, set start date
    //         setStartDate(date);
    //         setEndDate(null); // Reset end date
    //     } else if (startDate && !endDate) {
    //         // If there's a start date but no end date, set end date
    //         if (date >= startDate) {
    //             setEndDate(date); // Set end date if it is after start date
    //         } else {
    //             // If end date is before start date, reset to start date
    //             setStartDate(date);
    //         }
    //     }
    // };

    // const highlightDates = [...selectedDates]; // Individual selected dates

    // // If a range is selected, add those dates to highlightDates
    // if (startDate && endDate) {
    //     const currentDate = new Date(startDate);
    //     while (currentDate <= endDate) {
    //         highlightDates.push(new Date(currentDate)); // Add each date in the range
    //         currentDate.setDate(currentDate.getDate() + 1);
    //     }
    // }


    const updateSlotTimesecondslot = (slotIndex: number, field: "start" | "end", value: string) => {
        const updatedHours = [...specificHours];
        const currentSlot = updatedHours[slotIndex];

        // Update the specific field (start or end)
        currentSlot[field] = value;

        // Validation: ensure end time is later than start time
        if (field === "start" && !validateTime(value, currentSlot.end)) {
            alert("Start time cannot be later than end time");
            return;
        }
        if (field === "end" && !validateTime(currentSlot.start, value)) {
            alert("End time must be later than start time");
            return;
        }

        setSpecificHours(updatedHours); // Update state after validation
    };


    const updateSlotTimeuseHours = (field: "start" | "end", value: string) => {
        // console.log(field, value)
        if (field == 'start') {
            setstartSlots(value);
        } else {
            setendSlots(value);
        }

        const updatedHours = [...weeklyHours];
        updatedHours.forEach(day => {
            day.slots.forEach(slot => {
                slot[field] = value;
            });
        });

        // const currentSlot = updatedHours[dayIndex].slots[slotIndex];

        // // Update the specific field (start or end)
        // currentSlot[field] = value;

        // // Validation: ensure end time is later than start time
        // if (field === "start" && !validateTime(value, currentSlot.end)) {
        //     alert("Start time cannot be later than end time");
        //     return;
        // }
        // if (field === "end" && !validateTime(currentSlot.start, value)) {
        //     alert("End time must be later than start time");
        //     return;
        // }

        setWeeklyHours(updatedHours);
    };


    const upateSpecificadatehours = async () => {
        // console.log(weeklyHours,weeklySpecific)

        const filteredArray = selectedDates.map(date => {

            if (date !== startDate && date !== endDate) {

                return { StartDateTime: date, endDateTime: null, slot: specificHours }
            } else if (startDate && endDate == null) {
                return { StartDateTime: startDate, endDateTime: null, slot: specificHours }
            }
        });

        const unfilteredArray = filteredArray.filter(val => val != undefined)
        if (startDate != null && endDate != null) {
            let ob: any = { StartDateTime: startDate, endDateTime: endDate, slot: specificHours }
            unfilteredArray.push(ob)
        }
        Cookies.set('specifichours', JSON.stringify(unfilteredArray), { expires: 100 });
        setupdateddDates(unfilteredArray);
        setSpecificHours([]);
        setSelectedDates([]);
        setStartDate(null);
        setEndDate(null);
        setShowCalender(false);



        // Cookies.set('weeklyhours', JSON.stringify(weeklyHours), { expires: 100 });
        // rruleset(weeklyHours, unfilteredArray);
        const daysOfWeek: any = {
            "SUN": "sunday",
            "MON": "monday",
            "TUE": "tuesday",
            "WED": "wednesday",
            "THU": "thursday",
            "FRI": "friday",
            "SAT": "saturday"
        };

        const output = weeklyHours
            .filter(item => item.isAvailable == true)
            .map(item => item.slots.map(slot => ({
                weekday: daysOfWeek[item.day] || "unknown",
                start: `${slot.start}:00`,
                end: `${slot.end}:00`,
                timezone: selectedTimezone,
                until: dateFormat()// Optional end date for recurring event
            })))
            .flat();



        const transformed = unfilteredArray.flatMap(item => {
            if (!item?.StartDateTime) return []; // Skip items without StartDateTime
            const baseDate = new Date(item.StartDateTime);
            return item.slot.map(slot => {
                const startDateTime = new Date(baseDate);
                const [startHours, startMinutes] = slot.start.split(":");
                startDateTime.setUTCHours(+startHours, +startMinutes, 0);

                const endDateTime = new Date(baseDate);
                const [endHours, endMinutes] = slot.end.split(":");
                endDateTime.setUTCHours(+endHours, +endMinutes, 0);

                return {
                    start: startDateTime.toISOString(),
                    end: endDateTime.toISOString()
                };
            });
        });


        const objValue = {
            "one_off": transformed,
            "recurring": output
        }
        // console.log(objValue)
        Cookies.set('weeklyhours', JSON.stringify(weeklyHours), { expires: 100 });
        // rruleset(weeklyHours, unfilteredArray);

        if (userdata) {
            const variables = {
                input: { id: userdata.id, generalAvailabilityTime: JSON.stringify(objValue) }
            };
            try {
                // const todoData = await client.graphql({
                //     query: updateUser,
                //     variables: variables
                // });
                const phoneNosign = userdata.phone.startsWith("+") ? userdata.phone.slice(1) : userdata.phone;
                const todoData = await axios.put(`${apiUrl}/draftEvent/updateUser`, { phone: phoneNosign, generalAvailabilityTime: JSON.stringify(objValue) });
                setSumbmitLoader(false);
                toast.success("Working Hours updated");
                // if (todoData.errors) {
                //     console.error('GraphQL Errors:', todoData.errors);
                // }
            } catch (error) {
                setSumbmitLoader(false);
                console.error('GraphQL Request Failed:', error);
                toast.error("Error");
            }
        } else {
            setSumbmitLoader(false);
        }

        const CookeData = Cookies.get('phoneNumber');
        let cookieData = CookeData ? JSON.parse(CookeData) : {};
        const dataToSet = {
            firstName: cookieData.firstName,
            lastName: cookieData.lastName,
            phone: cookieData.phone,
            generalAvailabilityTime: JSON.stringify(objValue),
            calendarToken: cookieData.calendarToken,
            id: cookieData.id
        };
        Cookies.set('phoneNumber', JSON.stringify(dataToSet), { expires: 100 })
        checkwhileSkip(userdata, phonenumber)
    }


    const dateFormat = () => {
        const today = new Date();
        const formattedDate = today.getFullYear() + '-' +
            String(today.getMonth() + 1).padStart(2, '0') + '-' +
            String(today.getDate()).padStart(2, '0');
        return formattedDate
    }


    const updategeneral = () => {
        if (userHours == true) {
            const updatedHours = weeklyHours.map((day) => ({
                ...day,
                slots: day.slots = weeklySpecific

            }));
            setWeeklyHours(updatedHours);
        }

        setSumbmitLoader(true);
        upateSpecificadatehours();

    }

    const getWeekday = (day: string) => {
        const days: { [key: string]: Weekday } = {
            'SUN': RRule.SU,
            'MON': RRule.MO,
            'TUE': RRule.TU,
            'WED': RRule.WE,
            'THU': RRule.TH,
            'FRI': RRule.FR,
            'SAT': RRule.SA
        };

        return days[day as keyof typeof days];
    };

    const rruleset = async (array1: any, array2: any) => {
        const ruleSet = new RRuleSet();
        const dailySlotsMap = new Map();

        // Process weekly recurrence from array1
        array1.forEach((item: any) => {
            if (item.isAvailable) {
                const day = item.day;
                if (!dailySlotsMap.has(day)) {
                    dailySlotsMap.set(day, []);
                }
                const slots = dailySlotsMap.get(day);

                item.slots.forEach((slot: any) => {
                    const [startHour, startMinute] = slot.start.split(':');
                    const startTime = new Date(item.StartDateTime);
                    const timeSlot = new Date(startTime.setHours(startHour, startMinute));
                    slots.push(timeSlot);
                });
            }
        });

        // Create a single rule for each day with multiple time slots
        dailySlotsMap.forEach((slots, day) => {
            const rule = new RRule({
                freq: RRule.WEEKLY,
                byweekday: getWeekday(day),
                dtstart: slots[0], // Use the first time slot's date for the start date
            });
            ruleSet.rrule(rule);
            slots.forEach((slot: any) => {
                ruleSet.rdate(slot); // Add specific slots as RDATE
            });
        });

        // Process specific date(s) or date ranges from array2
        array2.forEach((item: any) => {
            const startDate = new Date(item.StartDateTime);
            const slots = item.slot;

            slots.forEach((slot: any) => {
                const [startHour, startMinute] = slot.start.split(':');
                const specificDate = new Date(startDate.setHours(startHour, startMinute));
                ruleSet.rdate(specificDate);  // Add specific date as RDATE

                // If there's an end date, add a recurrence up to the end date
                if (item.endDateTime) {
                    const endDate = new Date(item.endDateTime);
                    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
                        const dateInstance = new Date(date.setHours(startHour, startMinute));
                        ruleSet.rdate(dateInstance);
                    }
                }
            });
        });


        const combinedRRule = generateRRULE(ruleSet);


        if (userdata) {
            const variables = {
                input: { id: userdata.id, generalAvailabilityTime: combinedRRule }
            };
            try {
                // const todoData = await client.graphql({
                //     query: updateUser,
                //     variables: variables
                // });
                const phoneNosign = userdata.phone.startsWith("+") ? userdata.phone.slice(1) : userdata.phone;
                const todoData = await axios.put(`${apiUrl}/draftEvent/updateUser`, { phone: phoneNosign, generalAvailabilityTime: combinedRRule });
                setSumbmitLoader(false);

                // if (todoData.errors) {
                //     console.error('GraphQL Errors:', todoData.errors);
                // }
            } catch (error) {
                setSumbmitLoader(false);
                console.error('Request Failed:', error);
            }
        } else {
            setSumbmitLoader(false);
        }



        const CookeData = Cookies.get('phoneNumber');
        let cookieData = CookeData ? JSON.parse(CookeData) : {};
        const dataToSet = {
            firstName: cookieData.firstName,
            lastName: cookieData.lastName,
            phone: cookieData.phone,
            generalAvailabilityTime: combinedRRule,
            calendarToken: cookieData.calendarToken,
            id: cookieData.id
        };
        Cookies.set('phoneNumber', JSON.stringify(dataToSet), { expires: 100 })
        checkwhileSkip(userdata, phonenumber)
    };

    function generateRRULE(data: any) {
        const weekdays: any = [];

        // Format specific dates correctly for RDATE
        const specificDates = data._rdate.map((date: any) => {
            const d = new Date(date);
            return `${d.getUTCFullYear()}${(d.getUTCMonth() + 1).toString().padStart(2, '0')}${d.getUTCDate().toString().padStart(2, '0')}T` +
                `${d.getUTCHours().toString().padStart(2, '0')}${d.getUTCMinutes().toString().padStart(2, '0')}Z`;
        });

        // Extract weekdays from the _rrule
        data._rrule.forEach((rule: any) => {
            const weekday = rule.options.byweekday; // Get the weekdays from options
            weekday.forEach((day: any) => weekdays.push(day));
        });

        // Map weekday numbers to their string representations in the required order
        const byday = weekdays.map((day: any) => {
            switch (day) {
                case 0: return 'SU'; // Sunday
                case 1: return 'MO'; // Monday
                case 2: return 'TU'; // Tuesday
                case 3: return 'WE'; // Wednesday
                case 4: return 'TH'; // Thursday
                case 5: return 'FR'; // Friday
                case 6: return 'SA'; // Saturday
                default: return '';
            }
        }).filter((value: any, index: any, self: any) => self.indexOf(value) === index); // Remove duplicates

        // Construct the DTSTART string in the desired format
        const startDate = new Date(data.options.dtstart);
        const formattedStartDate = `${startDate.getUTCFullYear()}${(startDate.getUTCMonth() + 1).toString().padStart(2, '0')}${startDate.getUTCDate().toString().padStart(2, '0')}T` +
            `${startDate.getUTCHours().toString().padStart(2, '0')}${startDate.getUTCMinutes().toString().padStart(2, '0')}Z`;

        // Construct the RRULE string
        const rrule = `RRULE:FREQ=WEEKLY;BYDAY=${byday.join(',')};DTSTART=${formattedStartDate};RDATE=${specificDates.join(',')}`;

        return rrule;
    }




    const skip = () => {
        checkwhileSkip(userdata, phonenumber)
    }

    const checkwhileSkip = (findUser: any, phone: any) => {
        let user = null;

        // Check if findUser exists and has required fields
        if (findUser) {
            // Retrieve existing cookie data if available
            const CookeData = Cookies.get('phoneNumber');
            let cookieData = CookeData ? JSON.parse(CookeData) : {};
            let generalActivity = undefined;
            let calendertoken = undefined;
            if (cookieData.generalAvailabilityTime) {
                generalActivity = cookieData.generalAvailabilityTime
            }
            if (cookieData.calendarToken) {
                calendertoken = cookieData.calendarToken
            }

            if (findUser.generalAvailabilityTime) {
                generalActivity = findUser.generalAvailabilityTime
            }
            if (findUser.calendarToken) {
                calendertoken = findUser.calendarToken
            }

            // Only set generalAvailabilityTime and calenderToken if they don't exist in the cookie
            const dataToSet = {
                firstname: findUser.firstName,
                lastname: findUser.lastName,
                phone: phone,
                generalAvailabilityTime: generalActivity,
                calendarToken: calendertoken,
                id: findUser.id
            };

            Cookies.set('phoneNumber', JSON.stringify(dataToSet), { expires: 100 });
            user = findUser;
        } else {
            // If no findUser, check for cookie data
            const CookeData = Cookies.get('phoneNumber');
            if (CookeData) {
                user = JSON.parse(CookeData);
            }
        }


        if (user) {
            // Check generalAvailabilityTime and calenderToken for both findUser and cookieData

            if (user.calendarToken) {
                // navigate('/');
                const CookeExpireData = Cookies.get('phoneNumber');
                let cookieData = CookeExpireData ? JSON.parse(CookeExpireData) : {};
                let calnderTok = typeof user.calendarToken == 'string' ? JSON.parse(user.calendarToken) : user.calendarToken

                if (TokenexpiryDate(calnderTok.expires_in, calnderTok.token_generate_Date) == true) {
                    if(props.showstript !=false){
                    navigate('/');
                    }
                } else {
                    if(props.showstript !=false){
                    navigate('/googlecalender', { state: { status: false } });
                    }
                }

            } else {
                if(props.showstript !=false){
                navigate('/googlecalender', { state: { status: false } });
                }
            }

        }
    }

    const TokenexpiryDate = (e: any, d: any) => {
        const generateTime = new Date(d);

        // Add expiresIn (in seconds) to generateTime
        const expirationTime = new Date(generateTime.getTime() + e * 1000);

        // Get the current date and time
        const currentTime = new Date();

        // Check if current time is greater than expiration time
        if (currentTime > expirationTime) {
            return false; // Expired
        } else {
            return true; // Not expired
        }
    }

    const filteredTimezones = timezones.filter((timezone) =>
        timezone.label.toLowerCase().includes(value.toLowerCase())
    );

    const usesameHours = () => {
        setuserHours((prevState) => !prevState);
        setweeklySpecific([{ start: "09:00", end: "10:00" }])
    }

    return (
        <>
            {props.showstript != false ? <div><Headertag showHeader={status} dropDownShow={false} /></div> : <></>}
            {/* <div><Headertag showHeader={status} dropDownShow={false} /></div> */}
            {isMobile ? <div className={styles.container}>
                <div className={styles.weekhourscontainer}>

                    <div style={{ display: 'flex', margin: '10px 10px 0px 20px', justifyContent: 'center' }}>
                        {weeklyHours.map((day, dayIndex) => (
                            <div key={day.day} className={styles.daycontainercss}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={day.isAvailable}
                                        onChange={() => toggleDayAvailability(dayIndex)}
                                    />
                                    <span style={{ color: '#fff' }}>{day.day}</span>
                                </label>
                            </div>
                        ))}
                    </div>

                    <div className={styles.rowlayout}>
                        <div className={styles.suite}>Use same hours for all days</div>

                        <div style={{ marginRight: '-40px', marginTop: '8px' }}>
                            <input
                                type="checkbox"
                                id="daySwitch"
                                className={styles.switch}
                                onChange={() => usesameHours()}
                            />
                            <label htmlFor="daySwitch" className={styles.switchlabel}></label>

                        </div>


                    </div>



                    <div style={{ margin: '20px 20px 0px 20px' }}>
                        {/* <label>Select Timezone: </label>
                        <select value={selectedTimezone} onChange={handleTimezoneChange}>
                            {timezones.map((tz) => (
                                <option key={tz.value} value={tz.value}>
                                    {tz.label}
                                </option>
                            ))}
                        </select> */}
                        {!showAutocomplete ? <div><Autocomplete
                            value={value}
                            items={filteredTimezones}
                            getItemValue={(item) => item.label}  // Display the label of the timezone in the input field
                            onChange={(e) => setValue(e.target.value)}  // Update the value on input change
                            onSelect={(val) => {
                                const selected = timezones.find(item => item.label === val);
                                setSelectedTimezone(selected || null);  // Set null if no timezone is found
                                setValue(val);  // Update the input field with the selected label
                                setshowAutocomplete(true);
                            }}
                            renderMenu={(items) => (
                                <div className={styles.autocompletemenu}>
                                    {items}
                                </div>
                            )}
                            renderItem={(item, isHighlighted) => (
                                <div
                                    key={item.value}
                                    style={{
                                        background: isHighlighted ? 'lightgray' : 'white',
                                        padding: '8px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {item.label}
                                </div>
                            )}
                        /> </div> : <>{selectedTimezone && selectedTimezone.value && (
                            <div style={{ display: 'flex' }}>

                                <p style={{ color: 'blue' }}>{selectedTimezone.label}</p> <div className={styles.editicon} onClick={() => setshowAutocomplete(false)}><FiEdit2 style={{ color: 'blue' }} /></div>
                            </div>
                        )}</>}



                    </div>
                    <div>{userHours ? <div className={styles.userHourscss}>
                        {weeklySpecific.map((slot, slotIndex) => (
                            <div className={styles.timebox} style={{ margin: '5px' }}>
                                <input
                                    type="time"
                                    value={slot.start}
                                    onChange={(e) => updateSlotTimeuseHours("start", e.target.value)}

                                />
                                <span>-</span>
                                <input
                                    type="time"
                                    value={slot.end}
                                    onChange={(e) => updateSlotTimeuseHours("end", e.target.value)}

                                />
                                <div className={styles.buttons}>
                                    <button style={{ marginLeft: '10px' }} onClick={() => removeSpecificHoursingle(slotIndex)}><MdOutlineRemoveCircleOutline /></button>
                                    <button style={{ marginLeft: '10px' }} onClick={() => addSpecificHoursingle(slotIndex)}><MdAddCircleOutline /></button>
                                </div>
                            </div>
                        ))}
                    </div> : <></>}</div>
                    {userHours ? <></> : weeklyHours.map((day, dayIndex) => (
                        <div key={day.day} className={styles.daycontainer} style={{ margin: '10px 20px 0px 20px' }}>
                            {/* <div>
                                <label style={{ display: 'flex' }}>
                                    <input
                                        type="checkbox"
                                        checked={day.isAvailable}
                                        onChange={() => toggleDayAvailability(dayIndex)}
                                    />
                                    {day.day}
                                </label>
                            </div> */}
                            {day.isAvailable ? <>{day.isAvailable &&
                                day.slots.map((slot, slotIndex) => (
                                    <div key={slotIndex} className={styles.timeslot} style={{ padding: '10px 0px', width: '100%',textAlign: 'left' }}>
                                        {/* 
                                        <input type="time" value={slot.start} onChange={(e) => updateSlotTime(dayIndex, slotIndex, "start", e.target.value)} />

                                        <input type="time" value={slot.end} onChange={(e) => updateSlotTime(dayIndex, slotIndex, "end", e.target.value)} /> */}
                                        {slotIndex == 0 ? <label>{day.day}</label> : <><label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></>}

                                        <div className={styles.timebox} >
                                            <input
                                                type="time"
                                                value={slot.start}
                                                onChange={(e) => updateSlotTime(dayIndex, slotIndex, "start", e.target.value)}

                                            />
                                            <span>-</span>
                                            <input
                                                type="time"
                                                value={slot.end}
                                                onChange={(e) => updateSlotTime(dayIndex, slotIndex, "end", e.target.value)}

                                            />
                                            <div className={styles.buttons}>
                                                {slotIndex != 0 ? <button style={{ marginLeft: slotIndex == 0 ? '10px' : '10px' }} onClick={() => removeTimeSlot(dayIndex, slotIndex)}><MdOutlineRemoveCircleOutline /></button> : <></>}
                                                {day.isAvailable && <button style={{ marginLeft: slotIndex == 0 ? '10px' : '10px' }} onClick={() => addTimeSlot(dayIndex)}><MdAddCircleOutline /></button>}
                                            </div>
                                        </div>

                                    </div>
                                ))}</> : <></>}


                        </div>
                    ))}


                </div>
                {/* <div className={styles.specificHoursContainer}>
                    <div style={{ padding: '20px', textAlign: 'left' }}>

                        <h4>Date-specific hours</h4>
                        <p style={{ color: '#8d8686', fontSize: '16px', marginBottom: '20px', marginTop: '20px', width: '100%' }}>Override your availability for specific dates when your hours differ from your regular weekly hours.</p>
                        <button className={styles.addspecifichours} onClick={() => { setShowCalender(!showCalender) }}><label style={{ fontSize: '20px', fontWeight: 'bolder' }}>+</label> Add date-specific hours</button>
                        {showCalender ? <div style={{ paddingTop: '20px' }}>
                            <h5>Select the date(s) you want to assign specific hours</h5>
                            <DatePicker
                                selected={null} // We don't need a single selected date
                                onChange={handleDateChange}
                                onClickOutside={() => console.log(selectedDates)}
                                inline
                                highlightDates={selectedDates} 
                                filterDate={(date) => true} 
                            />

                            
                            <div className={styles.specific}>
                                <p style={{ fontWeight: 'bold', fontSize: '12px' }}>What hours are you available?</p>
                                {specificHours.map((specificHour, index) => (
                                    <div key={index} className={styles.timebox} style={{ display: 'flex' }}>
                                        <input
                                            type="time"
                                            value={specificHour.start}
                                            onChange={(e) => updateSlotTimesecondslot(index, "start", e.target.value)}

                                        />
                                        <span>-</span>
                                        <input
                                            type="time"
                                            value={specificHour.end} onChange={(e) => updateSlotTimesecondslot(index, "end", e.target.value)}

                                        />
                                        <div className={styles.buttons}>
                                            <button style={{ marginLeft: '10px' }} onClick={() => removeSpecificHour(index)}><MdOutlineRemoveCircleOutline /></button>
                                            <button style={{ marginLeft: '10px' }} onClick={() => addSpecificHour()}><MdAddCircleOutline /></button>
                                        </div>
                                    </div>
                                    
                                ))}
                            </div>
                        </div> : <div></div>}
                    </div>

                </div> */}
            </div> : <></>}
            {isDesktop ? <div className={styles.container}>
                <div className={styles.weekhourscontainer}>



                    <div className={styles.rowlayoutdesktop}>
                        <div className={styles.suite}>Use same hours for all days</div>

                        <div style={{ marginRight: '-40px', marginTop: '8px' }}>
                            <input
                                type="checkbox"
                                id="daySwitch"
                                className={styles.switch}
                                onChange={() => usesameHours()}
                            />
                            <label htmlFor="daySwitch" className={styles.switchlabel}></label>

                        </div>


                    </div>

                    <div style={{ borderBottom: '1px solid gray' }}></div>



                    <div style={{ margin: '20px 20px 0px 20px' }}>
                        {/* <label>Select Timezone: </label>
                        <select value={selectedTimezone} onChange={handleTimezoneChange}>
                            {timezones.map((tz) => (
                                <option key={tz.value} value={tz.value}>
                                    {tz.label}
                                </option>
                            ))}
                        </select> */}
                        {!showAutocomplete ? <div><Autocomplete
                            value={value}
                            items={filteredTimezones}
                            getItemValue={(item) => item.label}  // Display the label of the timezone in the input field
                            onChange={(e) => setValue(e.target.value)}  // Update the value on input change
                            onSelect={(val) => {
                                const selected = timezones.find(item => item.label === val);
                                setSelectedTimezone(selected || null);  // Set null if no timezone is found
                                setValue(val);  // Update the input field with the selected label
                                setshowAutocomplete(true);
                            }}
                            renderMenu={(items) => (
                                <div className={styles.autocompletemenu}>
                                    {items}
                                </div>
                            )}
                            renderItem={(item, isHighlighted) => (
                                <div
                                    key={item.value}
                                    style={{
                                        background: isHighlighted ? 'lightgray' : 'white',
                                        padding: '8px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {item.label}
                                </div>
                            )}
                        /> </div> : <>{selectedTimezone && selectedTimezone.value && (
                            <div style={{ display: 'flex',justifyContent: 'center' }}>

                                <p style={{ color: 'blue' }}>{selectedTimezone.label}</p> <div className={styles.editicon} onClick={() => setshowAutocomplete(false)}><FiEdit2 style={{ color: 'blue' }} /></div>
                            </div>
                        )}</>}



                    </div>

                    <div>{userHours ? <div className={styles.userHourscss}>
                        {weeklySpecific.map((slot, slotIndex) => (
                            <div className={styles.timebox} style={{ margin: '5px' }}>
                                <input
                                    type="time"
                                    value={slot.start}
                                    onChange={(e) => updateSlotTimeuseHours("start", e.target.value)}

                                />
                                <span>-</span>
                                <input
                                    type="time"
                                    value={slot.end}
                                    onChange={(e) => updateSlotTimeuseHours("end", e.target.value)}

                                />
                                <div className={styles.buttons}>
                                    <button style={{ marginLeft: '10px' }} onClick={() => removeSpecificHoursingle(slotIndex)}><MdOutlineRemoveCircleOutline /></button>
                                    <button style={{ marginLeft: '10px' }} onClick={() => addSpecificHoursingle(slotIndex)}><MdAddCircleOutline /></button>
                                </div>
                            </div>
                        ))}
                    </div> : <></>}</div>

                    {userHours ? <></> : weeklyHours.map((day, dayIndex) => (
                        <div key={day.day} className={styles.daycontainer} style={{ margin: '10px 20px 0px 20px' }}>
                            {/* <div>
                                <label style={{ display: 'flex' }}>
                                    <input
                                        type="checkbox"
                                        checked={day.isAvailable}
                                        onChange={() => toggleDayAvailability(dayIndex)}
                                    />
                                    {day.day}
                                </label>
                            </div> */}
                            {day.isAvailable ? <>{day.isAvailable &&
                                day.slots.map((slot, slotIndex) => (
                                    <div key={slotIndex} className={styles.timeslot} style={{ padding: '10px 0px', width: '100%' }}>
                                        {/* 
                                        <input type="time" value={slot.start} onChange={(e) => updateSlotTime(dayIndex, slotIndex, "start", e.target.value)} />

                                        <input type="time" value={slot.end} onChange={(e) => updateSlotTime(dayIndex, slotIndex, "end", e.target.value)} /> */}

                                        {slotIndex == 0 ? <> <input
                                            type="checkbox"
                                            style={{ width: '10px' }}
                                            checked={day.isAvailable}
                                            onChange={() => toggleDayAvailability(dayIndex)}
                                        /><label >

                                                {day.day}</label></> : <><label style={{ paddingRight: '67px' }}></label></>}

                                        <div className={styles.timebox} >
                                            <input
                                                type="time"
                                                value={slot.start}
                                                onChange={(e) => updateSlotTime(dayIndex, slotIndex, "start", e.target.value)}

                                            />
                                            <span>-</span>
                                            <input
                                                type="time"
                                                value={slot.end}
                                                onChange={(e) => updateSlotTime(dayIndex, slotIndex, "end", e.target.value)}

                                            />
                                            <div className={styles.buttons}>
                                                {slotIndex != 0 ? <button style={{ marginLeft: slotIndex == 0 ? '10px' : '10px' }} onClick={() => removeTimeSlot(dayIndex, slotIndex)}><MdOutlineRemoveCircleOutline /></button> : <></>}
                                                {day.isAvailable && <button style={{ marginLeft: slotIndex == 0 ? '10px' : '10px' }} onClick={() => addTimeSlot(dayIndex)}><MdAddCircleOutline /></button>}
                                            </div>
                                        </div>

                                    </div>
                                ))}</> : <><div><input
                                    type="checkbox"
                                    style={{ width: '10px' }}
                                    checked={day.isAvailable}
                                    onChange={() => toggleDayAvailability(dayIndex)}
                                /><label>{day.day}</label>Available</div></>}


                        </div>
                    ))}


                </div>
                {/* <div className={styles.specificHoursContainer}>
                    <div style={{ padding: '20px', textAlign: 'left' }}>

                        <h4>Date-specific hours</h4>
                        <p style={{ color: '#8d8686', fontSize: '16px', marginBottom: '20px', marginTop: '20px', width: '100%' }}>Override your availability for specific dates when your hours differ from your regular weekly hours.</p>
                        <button className={styles.addspecifichours} onClick={() => { setShowCalender(!showCalender) }}><label style={{ fontSize: '20px', fontWeight: 'bolder' }}>+</label> Add date-specific hours</button>
                        {showCalender ? <div style={{ paddingTop: '20px' }}>
                            <h5>Select the date(s) you want to assign specific hours</h5>
                            <DatePicker
                                selected={null} // We don't need a single selected date
                                onChange={handleDateChange}
                                onClickOutside={() => console.log(selectedDates)}
                                inline
                                highlightDates={selectedDates} 
                                filterDate={(date) => true} 
                            />

                            
                            <div className={styles.specific}>
                                <p style={{ fontWeight: 'bold', fontSize: '12px' }}>What hours are you available?</p>
                                {specificHours.map((specificHour, index) => (
                                    <div key={index} className={styles.timebox} style={{ display: 'flex' }}>
                                        <input
                                            type="time"
                                            value={specificHour.start}
                                            onChange={(e) => updateSlotTimesecondslot(index, "start", e.target.value)}

                                        />
                                        <span>-</span>
                                        <input
                                            type="time"
                                            value={specificHour.end} onChange={(e) => updateSlotTimesecondslot(index, "end", e.target.value)}

                                        />
                                        <div className={styles.buttons}>
                                            <button style={{ marginLeft: '10px' }} onClick={() => removeSpecificHour(index)}><MdOutlineRemoveCircleOutline /></button>
                                            <button style={{ marginLeft: '10px' }} onClick={() => addSpecificHour()}><MdAddCircleOutline /></button>
                                        </div>
                                    </div>
                                    
                                ))}
                            </div>
                        </div> : <div></div>}
                    </div>

                </div> */}
            </div> : <></>}
            <div><div style={{ textAlign: 'center', cursor: 'pointer' }}><button className={styles.submitbtn} onClick={() => { updategeneral() }} disabled={sumbmitloader}>Save</button></div></div>
            {sumbmitloader == true ? <div className={styles.centerloader}>
                <div className={styles.spinner}></div>
            </div> : <></>}
            {props.showstript != false ? <div style={{ textAlign: 'center', padding: '20px', cursor: 'pointer' }}>
                {status == true ? <p ></p> : <p ><a onClick={() => skip()}>Skip</a></p>}
            </div> : <></>}
            <ToastContainer />
        </>
    );
};

export default WeekHours;
