import { FunctionComponent, useEffect, useState } from "react";
import styles from "./HomeFeed.module.css";
import AvailabilitySharedNoAccou from "./AvailabilitySharedNoAccou";
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule';
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { isMobile, isAndroid } from 'react-device-detect';
import SignupFormPopup from "./SignupFormPopup";
import OpenApp from 'web-open-app';
import axios from 'axios';
import Cookies from "js-cookie";

interface RelatedApplication {
  id: string;
  platform: string;
}

const apiUrl = "../api";
const HomeFeed: FunctionComponent = () => {
  const [gridevent, setgridevent]: any = useState(false);
  const [signedUser, setSignedUser]: any = useState('');
  const [AllEvents, setAllEvents]: any = useState([]);
  const [browserdata, setBrowserData]: any = useSearchParams();
  const [previousMonth, setPreviousMonth]: any = useState('');
  const [previousYear, setPreviousYear]: any = useState('');
  const [activityData, setactivityData]: any = useState([]);
  const [holdMonth, setHoldMonth]: any = useState('');
  const [holdYear, setHoldYear]: any = useState('');
  const [boolean, setBoolean]: any = useState(false);
  const [indexValue, setindexValue]: any = useState(-1);
  const navigate = useNavigate();
  const location = useLocation();
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [testbool, settestbool] = useState(true);
  const [testboolpopup, settestboolpopup] = useState(false);
  const [commingSoon, setcommingSoon] = useState(false);
  const [Installed, setInstalled] = useState(false);
  const [isAppInstalledapp, setIsAppInstalledapp] = useState(null);
  const [statustext, setstatustext] = useState('');

  useEffect(() => {

    

    if (browserdata.get("redirect") == 'invite') {
      
      checkAndRedirectToApponload();
      
      setcommingSoon(true);
    } else if(browserdata.get("code") != null){
      
      navigate("/googlecalender")
      Cookies.set('code',browserdata.get("code"))
    } else if (location.search == '') {
      const storedNumber = Cookies.get('phoneNumber');
      // 
      if (storedNumber) {
        navigate('/details', { state: { status: false } })
      }
    } else {
      fetchTodos();
      
      setcommingSoon(false);
    }

 

    const checkAppInstallation = async () => {
      if ('getInstalledRelatedApps' in navigator && typeof navigator.getInstalledRelatedApps === 'function') {
        try {
          const relatedApps = await navigator.getInstalledRelatedApps?.();
          if (relatedApps) {
            const isInstalled = relatedApps.some((app: any) => {
              // Replace 'com.example.app' with your package name or bundle identifier
              return app.id === 'adventureplanet.com://';
            });

            // alert("app is installed"+isInstalled)
            setIsAppInstalled(isInstalled);
            // alert(isInstalled);
          }
        } catch (error) {
          console.error('Error checking app installation:', error);
        }
      } else {
        console.warn('getInstalledRelatedApps is not supported in this browser.');
      }
    };

    checkAppInstallation();
  
  
  }, []);





  const openapp = new OpenApp({
    scheme: "motus://",
    applink: "",
    iosUseScheme: true,
    copyText: "number: " + Math.ceil(Math.random() * 10000),
    callback: function (status: any, msg: any) {
      
      setstatustext(status);
      if (status == 'FAILED') {
        window.location.href = "https://apps.apple.com/ca/app/motus-fun/id6474702612";
      }
    
    }
  })


  const checkAndRedirectToApponload = async () => {
    
    // alert("open app");
    const appPackageName = 'adventureplanet.com://'; // Replace with your app's package name
    try {
      if (isMobile && !isAndroid) {
        const appPackageNamesd = 'motus://';
        // iOS device
        
        openapp.open();
      } else if (isMobile && isAndroid) {
        
        checkIsAppInstalledAndroid();
      } else {
        
        
        window.location.href = "https://play.google.com/store/apps/details?id=fun.motus.app&hl=en_CA&gl=US";
      }

    } catch (error) {
      console.error('Error during redirection:', error);
      // 
      // 
    }
  };

  const checkAndRedirectToApp = async () => {
    
    // alert("open app");
    const appPackageName = 'adventureplanet.com://'; // Replace with your app's package name
    try {
      if (isMobile && !isAndroid) {
        // iOS device
        
        checkIsAppInstalledIOS();
        // window.location.href = appPackageName;
      } else if (isMobile && isAndroid) {
        
        checkIsAppInstalledAndroid();
      } else {
        
        
        window.location.href = "https://play.google.com/store/apps/details?id=fun.motus.app&hl=en_CA&gl=US";
      }

    } catch (error) {
      console.error('Error during redirection:', error);
    }
  };

  const checkIsAppInstalledAndroid = async () => {
    // Define your app's custom URI scheme
    const customScheme = `adventureplanet.com://`;

    return new Promise((resolve) => {
      // Create an invisible iframe and try to open your app's custom URI scheme
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = customScheme;
      document.body.appendChild(iframe);

      // Set a timeout to determine if the app is installed or not
      const timeout = setTimeout(() => {
        document.body.removeChild(iframe);

        resolve(false); // Timeout occurred, app is likely not installed
        if (Installed == false) {
          window.location.href = "https://play.google.com/store/apps/details?id=fun.motus.app&hl=en_CA&gl=US";
        }
      }, 2000); // Adjust the timeout duration as needed

      // Listen for when the app's custom URI scheme is opened
      window.addEventListener('blur', () => {
        clearTimeout(timeout);
        document.body.removeChild(iframe);
        setInstalled(true);

        resolve(true); // App is installed
        window.location.href = customScheme;
      });
    });
  };

  const checkIsAppInstalledIOS = async () => {
    // Define your app's custom URI scheme
    const customSchemeIos = `motus://`;

    return new Promise((resolve) => {
      // Create an invisible iframe and try to open your app's custom URI scheme
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = customSchemeIos;
      document.body.appendChild(iframe);

      // Set a timeout to determine if the app is installed or not
      const timeout = setTimeout(() => {
        document.body.removeChild(iframe);

        resolve(false); // Timeout occurred, app is likely not installed
        if (Installed == false) {
          window.location.href = "https://apps.apple.com/ca/app/motus-fun/id6474702612";
        }
      }, 2000); // Adjust the timeout duration as needed

      // Listen for when the app's custom URI scheme is opened
      window.addEventListener('blur', () => {
        clearTimeout(timeout);
        document.body.removeChild(iframe);
        setInstalled(true);

        resolve(true); // App is installed
        window.location.href = customSchemeIos;
      });
    });
  };

  const news = async () => {
    window.location.href = "https://apps.apple.com/ca/app/motus-fun/id6474702612";
  }

  const close = () => {
    
    settestboolpopup(true);
  }

 

  const fetchTodos = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // const userData = await client.graphql({
    //   query:getUser,
    //   variables:{id:params.id}
    // })
    try {
      // const todoData = await client.graphql({
      //   query: listActivities
      // });
      const todoData = await axios.get(`${apiUrl}/draftEvent/activity/list`);
        
      //const todoData = await API.graphql(graphqlOperation(listActivities));
      const activityList = todoData?.data;
      
      setactivityData(todoData?.data);

      const dates: string[] = [];
      const activityData: any[] = [];
      // 
      for (var i = 0; i < activityList.length; i++) {
        let rule: any = []
        let rulesdatanew: any = [];
        let ownerId = JSON.parse(activityList[i].activityOwner);
        if (activityList[i].activityType == '2' && ownerId.id == browserdata.get("userId")) {
          if (activityList[i].activityRepeatRule == "") {

            rulesdatanew.push(activityList[i].activityStartDate);
          } else {
            
            let ruleData = activityList[i].activityRepeatRule as string;
            const ruleArray = RRule.fromString(ruleData);
            // 
            rulesdatanew = ruleArray.between(datetime(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate() - 1), datetime(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate() + 10));
            
            if (rulesdatanew.length > 0) {
              let get_Month = new Date(rulesdatanew[0]);
              
              rulesdatanew = ruleArray.between(datetime(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate() - 1), datetime(get_Month.getFullYear(), get_Month.getMonth() + 1, new Date().getDate() + 30));
              // 
              setPreviousMonth(get_Month.getMonth() + 1);
              setPreviousYear(get_Month.getFullYear());
              setHoldMonth(get_Month.getMonth() + 1);
              setHoldYear(get_Month.getFullYear());
            }
            // rulesdatanew=ruleArray.all()
          }
        }


        // 

        const rulesdata = rulesdatanew.filter((dateStr: string | number | Date) => {
          const date = new Date(dateStr);
          
          return date >= new Date(new Date().setDate(new Date().getDate() - 1));
        });
        // 
        // for(var k=0;k<rulesdatanew.length;k++){

        // }
        // 
        for (var j = 0; j < rulesdata.length; j++) {
          if (!dates.includes(rulesdata[j].toDateString())) {
            // 
            dates.push(rulesdata[j].toDateString())
            const data: any = {}
            const arrayList = []
            arrayList.push(activityList[i])
            data.date = rulesdata[j].toDateString()
            data.arrayList = arrayList

            activityData.push(data)

          } else {
            // 
            for (var k = 0; k < activityData.length; k++) {
              if (rulesdata[j].toDateString() == activityData[k].date) {
                let sharedUsers = JSON.parse(activityList[i].activitySharedUsers)
                activityData[k].arrayList.push(activityList[i])

              }

            }
          }
        }
      }
      // 
      setAllEvents(activityData)
    }
    catch (error) {
      
    }

  }

  const fetchTodosReadmore = async (data: any) => {
    
    // if(previousMonth >= holdMonth && previousYear == holdYear){


    // const urlSearchParams = new URLSearchParams(window.location.search);
    // const params = Object.fromEntries(urlSearchParams.entries());
    // const userData = await client.graphql({
    //   query:getUser,
    //   variables:{id:params.id}
    // })
    try {
      // const todoData = await client.graphql({
      //   query: listActivities
      //     });
      //const todoData = await API.graphql(graphqlOperation(listActivities));
      const activityList = activityData;
      // 
      const dates: string[] = [];
      const activityDataList: any[] = [];
      for (var i = 0; i < activityList.length; i++) {
        let rule: any = []
        let rulesdatanew: any = [];
        let ownerId = JSON.parse(activityList[i].activityOwner);
        if (activityList[i].activityType == '2' && ownerId.id == browserdata.get("userId")) {
          if (activityList[i].activityRepeatRule == "") {

            rulesdatanew.push(activityList[i].activityStartDate);
          } else {
            let ruleData = activityList[i].activityRepeatRule as string;
            const ruleArray = RRule.fromString(ruleData);
            if (data === 0) {
              rulesdatanew = ruleArray.between(datetime(previousYear, previousMonth - 1, new Date().getDate()), datetime(previousYear, previousMonth - 1, new Date().getDate() + 30));
              setPreviousMonth(previousMonth - 1);
              setPreviousYear(previousYear);
            } else if (data === 1) {
              rulesdatanew = ruleArray.between(datetime(previousYear, previousMonth + 1, new Date().getDate()), datetime(previousYear, previousMonth + 1, new Date().getDate() + 30));
              setPreviousMonth(previousMonth + 1);
              setPreviousYear(previousYear);
            }

          }
        }


        //  
        const rulesdata = rulesdatanew.filter((dateStr: string | number | Date) => {
          const date = new Date(dateStr);
          return date >= new Date();
        });
    
        for (var j = 0; j < rulesdata.length; j++) {
          if (!dates.includes(rulesdata[j].toDateString())) {
            // 
            dates.push(rulesdata[j].toDateString())
            const data: any = {}
            const arrayList = []
            arrayList.push(activityList[i])
            data.date = rulesdata[j].toDateString()
            data.arrayList = arrayList
            activityDataList.push(data)

          } else {
            // 
            for (var k = 0; k < activityDataList.length; k++) {
              if (rulesdata[j].toDateString() == activityDataList[k].date) {
                let sharedUsers = JSON.parse(activityList[i].activitySharedUsers)
                activityDataList[k].arrayList.push(activityList[i])
              }
   
            }
          }
        }
      }
      
      setAllEvents(activityDataList)
    }
    catch (error) {
      console.log(error)
    }
  }
  


  function getFormattedDate(time: any) {
    const date = new Date(time);

    // Get parts of the date
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour format to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Add leading zeros to minutes and seconds
    const strMinutes = minutes < 10 ? '0' + minutes : minutes;
    const strSeconds = seconds < 10 ? '0' + seconds : seconds;

    // Format the time string
    const formattedTime = `${hours}:${strMinutes} ${ampm}`;

    return formattedTime;
  }



  const testFuntion = (value: any, length: any) => {
    if (length > 1) {
      setBoolean(true);
      setindexValue(value)
    }

  }

  const viewData = AllEvents.map((course: any, index: any) =>
    <div>

      <div className={styles.leftAlign}>
        <b className={styles.jodyWilliamsIsContainer}>{course.date}</b>
      </div>
      <div>
        <div className={(boolean === true && index == indexValue) ? styles.buttonwrapsd : styles.buttonwrap} style={(boolean === true && index == indexValue) ? { 'height': ((course.arrayList.length * 85) + 100) } : {}}>
          {course.arrayList.map((item: any, i: any) =>

            <>


              <div className={styles.buttonView} style={(boolean === true && index == indexValue) ? { "transform": 'translate(0px,' + ((course.arrayList.length * 85) - (i * 100)) + 'px)', width: "100%" } : { width: "100%" }} onClick={() => { testFuntion(index, course.arrayList.length) }}>
                <div style={{ border: "1px solid #909090", borderRadius: "10px", borderLeft: "5px solid #D27A13", background: 'white' }}>
                  <div className={styles.container1}>
                    <div className={styles.round}></div>
                    <span className={styles.text}>Available</span>
                    {(course.arrayList.length > 1 && index != indexValue) ? <div className={styles.text2}>
                      <div>{course.arrayList.length > 1 ? <div>+{(course.arrayList.length - 1)} others</div> : ''} </div>
                    </div> : ''}

                  </div>
                  <div className={styles.container3} >
                    <div className={styles.outerschedule}><p className={styles.schedule} style={{ cursor: 'pointer' }} onClick={checkAndRedirectToApp}>Create Event</p></div>
                  </div>
                  <div className={styles.container2}>
                    <div >{getFormattedDate(item.activityStartTime)} - {getFormattedDate(item.activityEndTime)}</div>
                  </div>
                </div>

              </div>

            </>

          )}

          {/* {arrayDataItems(course.arrayList)} */}
        </div>

      </div>
    </div>

  )

  return (
    // <Container maxWidth="lg">
    <div >

      <>{gridevent == true ? <AvailabilitySharedNoAccou /> : <>
        {commingSoon == true ? <>
          {/* <div>---{isMobile}</div> */}
          {isMobile && !isAndroid ? <><div className={styles.aboveloader}><button className={styles.loadmore} onClick={() => news()}>Download Motus</button></div></> : <div className={styles.aboveloader}>
            <div className={styles.loader}></div>
          </div>}

          {/* <div style={{textAlign:"center",marginTop:"20px"}}><img src="./logo.png" width={100} /></div>
    <div style={{textAlign:"center",fontSize:"large",fontWeight:"bold"}}><u>App coming soon</u>
    </div><div style={{paddingTop: "10px",textAlign:"center"}}>Sign up for the launch</div><div>{getText(testbool)}</div> */}
        </> : <>
          <div className={styles.leftAlign}>
            <b className={styles.contai}>Motus</b>

          </div>
          <div className={styles.leftAlign} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
            <b style={{ fontSize: "20px" }} className={styles.jodyWilliamsIsContainer}>Schedule</b>
            <span className={styles.filter}>

              <img onClick={() => { setgridevent(false) }} className={styles.iconListChild} alt="" src="/frame-20.svg" />
              <img onClick={() => { setgridevent(true) }} className={styles.iconListChild} alt="" src="/frame-21.svg" />
              {/* <button onClick={()=> {navigate("/event-event-details-no-account")}}>test</button> */}

            </span>
          </div>

          {viewData.length > 0 ? <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
            {viewData}
            {/* <div style={{width:'100%',padding:'20px',textAlign:'center'}}>
<div style={{cursor:'pointer',paddingTop:'30px'}} >
  {(previousMonth > holdMonth) ? <span  style={{padding: '10px',border: '1px solid gray',borderRadius: '5px',margin: '5px'}} onClick={()=>fetchTodosReadmore(0)}>Previous</span> : <div></div>}
  <span style={{padding: '10px',border: '1px solid gray',borderRadius: '5px',margin: '5px'}} onClick={()=>fetchTodosReadmore(1)}>Next</span>
            </div>

</div> */}
            <div style={{ width: '100%', padding: '20px', textAlign: 'center' }}>
              <button className={styles.loadmore} onClick={() => fetchTodosReadmore(1)}>load more</button>
            </div>
          </div> : <div style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '20px' }}>No Available Data</div>}

          <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
            <div className={styles.cardOuter}>
              <div className={styles.card}>
                <div className={styles.container}>
                  <div className={styles.cdAlign}>

                    <div className={styles.fl}> <div className={styles.messagesAvatar}>
                      <img
                        className={styles.contactPhotoIcon}
                        alt=""
                        src="/motuscircle.png"
                      />
                    </div></div>
                    <div className={styles.fr}><b className={styles.joinRichOn}><span className={styles.p8}>Join Rich on Motus</span></b></div>
                    <div className={styles.cl} />

                  </div>

                  <div className={styles.p10} >
                    <div className={styles.alignCenter}>
                      <span className={styles.alignCenter}>Sign up to Motus to schedule rides of your own.</span>
                    </div>

                  </div>
                  <div className={styles.cdAlign}>
                    <div className={styles.buttonPrimary} style={{ width: "100%" }} onClick={checkAndRedirectToApp}>
                      <b className={styles.jodyWilliamsIsContainer} style={{ color: 'white' }}>Create account</b>

                    </div>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </>
        }
      </>}</>

      {/* </div> */}
      {/* </Container> */}
    </div>


  );
};

export default HomeFeed;
