import { FunctionComponent, useCallback, useEffect, useState ,useRef} from "react";
import styles from "./SingleEvent.module.css";
import { useNavigate, useSearchParams, useParams, useLocation } from "react-router-dom";
import Popup from "./popup";
import CalenderPopup from "./calenderpopup";
import { RRule, datetime } from "rrule";
import { isMobile, isAndroid } from 'react-device-detect';
import { FaCheckCircle, FaTimesCircle, FaRegTimesCircle } from "react-icons/fa";
import Cookies from "js-cookie";
import { MdEdit } from "react-icons/md";
import Headertag from "./header";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS for styling
import { TimePicker } from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import PlacesAutocomplete from 'react-places-autocomplete';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const apiUrl = "../api";

const botUrl = process.env.REACT_APP_BOT_URL+'/wa';
const SingleEvent: FunctionComponent = () => {


    const [activityData, setActivityData]: any = useState('');
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastName] = useState('');
    const [hostNumber, sethostNumber] = useState('');
    const [browserdata, setBrowserData]: any = useSearchParams();
    const [accepted, setAccepted] = useState('');
    const [chooseDateType, setchooseDateType] = useState(false);
    const [typeChoose, settypeChoose] = useState('');
    const [typeCalender, settypeCalender] = useState(false);
    const [eventDates, seteventDates] = useState<Date[]>([]);
    const [passcolorDates, setpasscolorDates] = useState<Object[]>([]);
    const [eventProccess, seteventProccess] = useState('initial value');
    const [disabledDate, setdisabledDate] = useState<Date[]>([]);
    const [Rrule, setRrule] = useState(false);
    const [rruleText, setrruleText] = useState('');
    const [numberBoolean, setnumberBoolean] = useState(false);
    const [bottomPopup, setbottomPopup] = useState(false);
    const [firstRender, setFirstRender] = useState(false);
    const [isAppInstalled, setIsAppInstalled] = useState(false);
    const [Installed, setInstalled] = useState(false);
    const [twomontDates, settwomontDates] = useState<Date[]>([]);
    const [showEventcomplte, setshowEventcomplte] = useState(false);
    const [rRule, setrRule] = useState(false);
    const [showLoader, setshowLoader] = useState(false);
    const [showDate, setShowDate] = useState<any>('');
    const [eventName, seteventName] = useState('');
    const [eventLocation, seteventLocation] = useState('');
    const [editEvent, seteditEvent] = useState(false);
    const [editBoolean, setEditboolean] = useState(false);
    const [showButton, setshowButton] = useState(false);
    const [cookeValue, setcookieValue] = useState<any>();
    const { id } = useParams() as { id: string };

    const [startDate, setStartDate] = useState<Date | null>(null); // Explicitly type startDate
    const [startTime, setStartTime] = useState<string>(''); // Initialize as an empty string
    const [endTime, setEndTime] = useState<string>(''); // Initialize as an empty string
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const [acstarttime, setAcstarttime] = useState('');
    const [acendtime, setAcendtime] = useState('');
    const [btnColor, setbtnColor] = useState(false);
    const [locationbox, setShowlocationBox] = useState(false);
    const [coookeboolean, setcookieValueBoolean] = useState(false);
    

    const [updateError, setupdateError] = useState('');
    const [specificUser, setspecificUser] = useState('');

    const canvasRef = useRef(null);
    const [imageDataUrl, setImageDataUrl] = useState('');
  

    const navigate = useNavigate();
    const locationparm = useLocation();
    
    useEffect(() => {
        
        
        if (!activityData || activityData == "" || activityData == undefined) {
            const CookeData = Cookies.get('phoneNumber');
            let cookieData = CookeData ? JSON.parse(CookeData) : {};
            
            if (cookieData.phone) {

                setcookieValue(cookieData)
                setshowButton(true);
                setcookieValueBoolean(true);
            } else {
                setcookieValue('')
                setshowButton(false);
                setcookieValueBoolean(false);
                Cookies.set('liveId', '/le/'+id, { expires: 100 })
            }
            setshowLoader(true);
            let status = false;
            if (locationparm.state?.status == undefined) {
                status = true;
            } else {
                status = locationparm.state?.status;
            }
            fetchTodos(cookieData, status);
        }



        //  
        const checkAppInstallation = async () => {
            if ('getInstalledRelatedApps' in navigator && typeof navigator.getInstalledRelatedApps === 'function') {
                try {
                    const relatedApps = await navigator.getInstalledRelatedApps?.();
                    if (relatedApps) {
                        const isInstalled = relatedApps.some((app: any) => {
                            // Replace 'com.example.app' with your package name or bundle identifier
                            return app.id === 'adventureplanet.com://';
                        });
                        setIsAppInstalled(isInstalled);
                        // alert(isInstalled);
                    }
                } catch (error) {
                    console.error('Error checking app installation:', error);
                }
            } else {
                console.warn('getInstalledRelatedApps is not supported in this browser.');
            }
        };

        checkAppInstallation();

    }, [firstRender]);



    const setBootompopupActive = () => {
        setbottomPopup(true)
    }

    async function fetchTodos(cookeData: any, reloadBoolean: boolean) {

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        
        if (params.redirect == 'invite') {
            navigate('/webapp')
        }
        else if (params.userId) {
            navigate('/event-event-details-no-account1?userId=' + params.userId)
        }
        try {
            let AcceptedData: any = localStorage.getItem("Accepted");
            setAccepted(AcceptedData)
            const todoData = await axios.get(`${apiUrl}/draftEvent/activity/${id}`);
            
            

            if (todoData.data != null) {
                let phoneNumber = cookeData.phone ? cookeData.phone.trim() : '';
                
                let findNumber = JSON.parse(todoData.data.activitySharedUsers).find((s: any) => { if (formatPhoneNumber(s.phone) == phoneNumber) { return s } });
                
                if (findNumber == undefined) {
                    setnumberBoolean(true);
                    setshowButton(false);
                } else {
                    setnumberBoolean(true);
                    setshowButton(true);
                    setspecificUser(findNumber)
                    if (findNumber.allDays == true) {
                        setbtnColor(true);
                    } else if (findNumber.allDays == false) {
                        setbtnColor(false);
                    }

                }
            } else {
                setnumberBoolean(false);
            }

            
            let activityData: any = todoData.data;
            const ss = RRule.fromString(activityData.activityRepeatRule);
            
            setrruleText(ss.toText());
            // 
            let currentDate = ss.between(datetime(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()), datetime(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate() + 31));
            const CookeDataValue = Cookies.get('eventDate');
            if (CookeDataValue && reloadBoolean == false) {
                
                setShowDate(CookeDataValue)
            } else {
                
                let resulta = activityData.activityRepeatRule.includes("FREQ");
                if (resulta == true) {
                    setShowDate(formatDate(currentDate[0]))
                } else {
                    setShowDate(activityData.activityStartTime)
                }

            }

            settwomontDates(ss.between(datetime(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()), datetime(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate() + 31)));
            // seteventDates(ss.between(datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()), datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()+10)));
            let sharedUsers: any = JSON.parse(activityData.activitySharedUsers);
            let activityOwner: any = JSON.parse(activityData.activityOwner);
            activityData.activitySharedUsers = sharedUsers;
            
            setFirstname(activityOwner.firstName)
            setLastName(activityOwner.lastName)
            sethostNumber(activityOwner.phone)
            setActivityData(activityData);
            seteventName(activityData?.activityName);
            seteventLocation(unString(activityData?.activityLocation));
            setRrule(activityData.activityRepeatRule.includes("FREQ"))
            let resulta = activityData.activityRepeatRule.includes("FREQ");
            
            if (resulta == true) {
                setrRule(true);
            } else {
                setrRule(false);
                if (checkDateandTime(activityData.activityStartTime)) {
                    setshowEventcomplte(true)  //make it true once dev completed
                } else {
                    
                    setshowEventcomplte(false)

                }
            }
            
            let phoneNumberhost = cookeData.phone ? cookeData.phone.trim() : '';
            if (phoneNumberhost === activityOwner.phone) {
                setEditboolean(true);
                setStartDate(new Date(activityData.activityStartDate))
                const startnow = new Date(activityData.activityStartTime);
                // Format the current time in HH:mm format
                const optionsStartTime: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
                const formattedStartTime = startnow.toISOString().split('T')[1].split('.')[0]; // Get HH:mm
                
                setStartTime(formattedStartTime);

                // Calculate end time (1 hour later)
                const endnow = new Date(activityData.activityEndTime);
                const optionsEndTime: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
                const formattedEndTime = endnow.toISOString().split('T')[1].split('.')[0]; // Get HH:mm
                setEndTime(formattedEndTime);

            } else {
                setEditboolean(false); //make it false once dev completed
            }

            localStorage.setItem("activityData", JSON.stringify(activityData))
            localStorage.setItem("activityId", id)
            // allactivity(activityData.activitySharedUsers, ss.between(datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()), datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()+10)));
            // const todos = todoData.data.lis.items;
            // setTodos(todos);
            if (!firstRender) {
                setBootompopupActive();
            }
            setshowLoader(false);
        } catch (err) {
            setshowLoader(false);
            
        }
    }

    const checkDateandTime = (eventtime: any) => {
        
        let eventDateandTime = new Date(eventtime);

        let currentDate = new Date();
        let currentDateVancouver = new Date(
            new Intl.DateTimeFormat('en-US', {
                timeZone: 'America/Vancouver',
            }).format(currentDate)
        );
        
        

        if (currentDateVancouver > eventDateandTime) {
            return true; // Current time is later than the event time
        } else {
            return false; // Event time has not passed yet
        }
    }


    const goToName = async () => {

        
        seteventProccess('ACCEPT');
        let result = activityData.activityRepeatRule.includes("FREQ");
        
        if (result == true) {
            setchooseDateType(true);

            
        } else {
            
            togglePopup('ACCEPT');
        }
    
    }

    const declainEvent = async () => {
        // activityData.activityRepeatRule="";
        seteventProccess('DECLAIN');
        
        let result = activityData.activityRepeatRule.includes("FREQ");
        if (result == true) {
            togglePopup('DECLAIN');
            // setchooseDateType(true);
        } else {

            togglePopup('DECLAIN');
        }
      
    }

    const checktwoDates = (compare: any, check: any) => {
        
        const date1 = new Date(compare);
        const date2 = new Date(check);
        
        if (date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()) {
            return true;
        } else {
            return false;
        }
    }

    const close = () => {
        setchooseDateType(false);
    }

    const closeCalandar = () => {
        settypeCalender(false);
    }

    const togglePopup = (value: any) => {

        
        setchooseDateType(false);
        settypeChoose("ALLDATE");
        activityData.activitySharedUsers = activityData.activitySharedUsers.filter((val: any) => {
            if (formatPhoneNumber(val.phone) == cookeValue.phone.trim()) {

                let processValue = typeof value == 'string' ? value : eventProccess;
                
                if (val.goingDate.length == 0 && val.notGoingDate.length == 0) {

                    if (processValue == 'ACCEPT') {
                        
                        val.allDays = true;
                        setbtnColor(true);

                    } else if (processValue == 'DECLAIN') {
                        
                        val.allDays = false;
                        setbtnColor(false);

                    }

                    return val
                } else {
                    val.goingDate = [];
                    val.notGoingDate = [];
                    if (processValue == 'ACCEPT') {
                        val.allDays = true;
                        setbtnColor(true);

                    } else if (processValue == 'DECLAIN') {
                        val.allDays = false;
                        setbtnColor(false);

                    }
                    return val;
                }
            } else {
                return val
            }
        });
     

        if (activityData.activitySharedUsers.length > 0) {
            if (cookeValue) {
                let findbyPhoneno = activityData.activitySharedUsers.find((val: any) => formatPhoneNumber(val.phone) == cookeValue.phone.trim());
                
                if (findbyPhoneno) {
                    let processValues = typeof value == 'string' ? value : eventProccess;
                    localStorage.setItem("SharedUsersData", JSON.stringify(activityData.activitySharedUsers));
                    updateEvent(activityData.activitySharedUsers);
                    setspecificUser(findbyPhoneno)
                    // decl(findbyPhoneno)
                }
            }


        }
    }

    const updateEvent = async (updateuser: any) => {
        let updateActivityData = {
            id: id,
            activitySharedUsers: JSON.stringify(updateuser)
        }
        
        const todoData = await axios.put(`${apiUrl}/draftEvent/activity/update`,{id: id, activitySharedUsers: JSON.stringify(updateuser) });
        
        close()
    }

    const updateEvents = async (event: any) => {
        
        let newObj: any = { id: event.id };
        let message = '';

        if (event.activityName != eventName) {
            newObj['activityName'] = eventName;
            message += `\r\n Name: ~${event.activityName}~ ${eventName}`;
        }
        if (event.activityLocation != eventLocation) {
            newObj['activityLocation'] = eventLocation;
            message += `\r\n Location: ~${unString(event.activityLocation)}~ ${eventLocation}`;
        }
        if (event.activityStartDate != acstarttime) {
            newObj['activityStartDate'] = acstarttime;
            message += `\r\n Date: ~${dateForat(event.activityStartDate)}~ ${dateForat(acstarttime)}`;
        }
        if (event.activityStartTime != acstarttime) {
            newObj['activityStartTime'] = acstarttime;
            message += `\r\n Time : ~${new Date(event.activityStartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${new Date(event.activityEndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}~ ${new Date(acstarttime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${new Date(acendtime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
        }
        if (event.activityEndTime != acendtime) {
            newObj['activityEndTime'] = acendtime;
        }


        
        
        if (Object.keys(newObj).length === 1) {
            
            seteditEvent(false)
        } else {
            
            try {
                let updateActivityData = newObj
                
                const todoData = await axios.put(`${apiUrl}/draftEvent/activity/update`,updateActivityData);
              
                toast.success("Location updated");
                activityData.activityName = eventName;
                activityData.activityLocation = eventLocation;
                if (newObj.activityStartTime && newObj.activityEndTime) {
                    const CookeData = Cookies.get('phoneNumber');
                    let cookieData = CookeData ? JSON.parse(CookeData) : {};
                    fetchTodos(cookieData, true)
                }
                setupdateError("");
                if (newObj.activityStartDate) {
                    Cookies.set("eventDate", dateFormateSet(newObj.activityStartDate), { expires: 100 })
                }

                if(todoData){
                    try {
                        let grouploc = {
                            "groupId": activityData.activityGroupId,
                            "msg": message,
                            "url": process.env.REACT_APP_BASE_URL+"/le/" + activityData.id
                        }
                        
                        setupdateError("");
                        const grouplocation = await axios.post(`${botUrl}/updateinfo`, grouploc);
                        
                    } catch (err) {
                        setupdateError("Error on updating in Chat Group");
                    }
                }
                

                seteditEvent(false)
            } catch (err) {
                toast.error("Error");
                seteditEvent(false);
                setupdateError("Error");
            }
        }


    }


    const updateEventsLocation = async (event: any) => {
        
        let newObj: any = { id: event.id };
        let message = '';

        if (event.activityLocation != eventLocation) {
            newObj['activityLocation'] = eventLocation;
            message += `\r\n Location: ~${unString(event.activityLocation)}~ ${eventLocation}`;
        }


        
        
        if (Object.keys(newObj).length === 1) {
            
            seteditEvent(false)
        } else {
            
            try {
                let updateActivityData = newObj
                
                const todoData = await axios.put(`${apiUrl}/draftEvent/activity/update`,updateActivityData);
                toast.success("Location updated");
                activityData.activityLocation = eventLocation;
                setupdateError("");
                if(todoData){
                    try {
                        let grouploc = {
                            "groupId": activityData.activityGroupId,
                            "msg": message,
                            "url": process.env.REACT_APP_BASE_URL+"/le/" + activityData.id
                        }
                        
                        setupdateError("");
                        const grouplocation = await axios.post(`${botUrl}/updateinfo`, grouploc);
                        
                    } catch (err) {
                       
                }
                setupdateError("Error on updating in Chat Group");
                }

                seteditEvent(false);
                setShowlocationBox(false);
            } catch (err) {
                toast.error("Error");
                seteditEvent(false);
                setShowlocationBox(false);
                setupdateError("Error");
            }
        }


    }

    const dateForat = (date: any) => {
        const da = new Date(date);
        const formattedDate = da.toDateString();
        
        return formattedDate
    }

    const dateFormateSet = (dateStr: any) => {
        const date = new Date(dateStr);

        // Options for formatting
        const optionsData: any = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

        // Format the date
        const formattedDate = date.toLocaleDateString('en-US', optionsData);

        return formattedDate
    }

    const chooseTypeCalender = () => {
        setchooseDateType(false);
        settypeCalender(!typeCalender);
        settypeChoose("CHOOSEDATE");
    }

    const checkDateandTimeSender = async (eventtime: any) => {
        
        let activityStime = new Date(activityData.activityStartTime);
        
        let eventDateandTime = new Date(eventtime);
        eventDateandTime.setHours(activityStime.getHours());
        eventDateandTime.setMinutes(activityStime.getMinutes());

        let currentDate = new Date();
        // 
        
        
        if (currentDate > eventDateandTime) {
            let vv1 = twomontDates.slice(1, 2);
            
            return vv1
        } else {

            let vv2 = twomontDates.slice(0, 1);
            
            return vv2
        }
    }


    const submitnextrrdate = async () => {
        let CookeData = '';

        const CookeDataValue = Cookies.get('eventDate');
        if (CookeDataValue) {
            CookeData = CookeDataValue
        } else {
            CookeData = formatDate(twomontDates[0])
        }
        // let cookieData = CookeData ? JSON.parse(CookeData) : {};
        

        let ss = twomontDates;
        

        if (ss.length > 0) {
            
            let newValue = await checkDateandTimeSender(twomontDates[0]);
            if (activityData.activitySharedUsers.length > 0) {
                activityData.activitySharedUsers = activityData.activitySharedUsers.filter((val: any) => {
                    if (formatPhoneNumber(val.phone) == cookeValue.phone.trim()) {
                        // dateFormat(newValue).forEach((s: any) => val.goingDate.push(s));
                        let date = "Wed Oct 02 2024"
                        let index = val.notGoingDate.indexOf(CookeData);

                        if (index !== -1) {
                            
                            // Remove the date from notgoingdate
                            val.notGoingDate.splice(index, 1);

                            // Add the date to goingdate
                            val.goingDate.push(CookeData);
                        } else {
                            val.goingDate.push(CookeData);
                        }
                        
                        return val;
                    } else {
                        return val;
                    }
                })

                localStorage.setItem("SharedUsersData", JSON.stringify(activityData.activitySharedUsers));
                updateEvent(activityData.activitySharedUsers)
                

            }

        }

    }

    const submitcalendarAction = () => {
        let ss = localStorage.getItem('calendervalue');
        if (ss === null) {
            ss = "default value";
        } else {
            ss = JSON.parse(ss);
        }
        
        if (eventProccess == 'ACCEPT' && ss != null) {
            
            if (activityData.activitySharedUsers.length > 0) {
                activityData.activitySharedUsers = activityData.activitySharedUsers.filter((val: any) => {

                    if (formatPhoneNumber(val.phone) == cookeValue.phone.trim()) {
                        // val.allDays='';
                        
                        if (val.allDays === '') {
                            
                            let selectedDates = dateFormat(ss);
                            
                            const filteredArray = val.notGoingDate.filter((date: any) => !selectedDates.some((selectedDates: any) => (checktwoDates(date, selectedDates) == true)));
                            

                            if (filteredArray != undefined) {
                                val.notGoingDate = filteredArray;
                            } else {
                                dateFormat(ss).forEach((s: any) => val.notGoingDate.push(s));
                            }

                            // val.notGoingDate=filteredArray;
                            dateFormat(ss).forEach((s: any) => val.goingDate.push(s));
                            // val.goingDate=dateFormat(ss);
                            return val;
                        } else if (val.allDays == true || val.allDays == false) {
                            
                            let selectedDatesbool = dateFormat(ss);
                            const filteredArray = val.notGoingDate.filter((date: any) => !selectedDatesbool.some((selectedDates: any) => (checktwoDates(date, selectedDates) == true)));
                            

                            if (filteredArray != undefined) {
                                val.notGoingDate = filteredArray;
                            }
                            // else{
                            //   dateFormat(ss).forEach((s:any)=> val.notGoingDate.push(s));
                            // }
                            dateFormat(ss).forEach((s: any) => val.goingDate.push(s));
                            // val.notGoingDate=[];
                            return val;
                        }
                    } else {
                        return val;
                    }
                })
                localStorage.setItem("SharedUsersData", JSON.stringify(activityData.activitySharedUsers));
                updateEvent(activityData.activitySharedUsers)
                

            }
        } else if (eventProccess == 'DECLAIN' && ss != null) {
            
            if (activityData.activitySharedUsers.length > 0) {
                
                activityData.activitySharedUsers = activityData.activitySharedUsers.filter((val: any) => {
                    if (formatPhoneNumber(val.phone) == cookeValue.phone.trim()) {
                        // val.allDays='';
                        if (val.allDays === '') {
                            
                            let selectedDates = dateFormat(ss);
                            
                            const filteredArray = val.goingDate.filter((date: any) => !selectedDates.some((selectedDates: any) => (checktwoDates(date, selectedDates) == true)));
                            
                            if (filteredArray != undefined) {
                                val.goingDate = filteredArray;
                            } else {
                                dateFormat(ss).forEach((s: any) => val.goingDate.push(s));
                            }

                            // filteredArray.forEach((s:any)=> val.goingDate=s);
                            dateFormat(ss).forEach((s: any) => val.notGoingDate.push(s));
                            // val.notGoingDate=dateFormat(ss);
                            return val;
                        } else if (val.allDays == true || val.allDays == false) {
                            let selectedDatesbool = dateFormat(ss);
                            const filteredArray = val.goingDate.filter((date: any) => !selectedDatesbool.some((selectedDates: any) => (checktwoDates(date, selectedDates) == true)));
                            

                            if (filteredArray != undefined) {
                                val.goingDate = filteredArray;
                            }
                            dateFormat(ss).forEach((s: any) => val.notGoingDate.push(s));
                            // val.goingDate=[];
                            return val
                        }
                    } else {
                        return val
                    }
                })
                localStorage.setItem("SharedUsersData", JSON.stringify(activityData.activitySharedUsers));
                updateEvent(activityData.activitySharedUsers)
                

            }
        }
    }

    const dateFormat = (value: any) => {
        return value.map((val: any) => {
            const inputDate = val;
            const dateObj = new Date(inputDate);
            const formattedDate = dateObj.toDateString().substr(0, 3) + '' + dateObj.toDateString().substr(3);

            
            return formattedDate
        })
    }



    const checkingTwodates = (datesArray: any) => {
        let CookeData = '';
        const CookeDataValue = Cookies.get('eventDate');
        if (CookeDataValue) {
            CookeData = CookeDataValue
        } else {
            CookeData = formatDate(twomontDates[0])
        }
        let index = datesArray.indexOf(CookeData);
        
        if (index !== -1) {
            return true
        } else {
            return false
        }
    }


    const invitedPeopleName = (firstName: any, lastName: any, phone: any) => {
        if (firstName && lastName) {
            return firstName + ' ' + lastName
        } else {
            if (!phone.startsWith("+")) {
                phone = "+" + phone;  // Add "+" if it's missing
            }
            return phone
        }
    }

    const formatPhoneNumber = (phone: string) => {
        phone = phone.toString(); // Ensure the phone is a string
        
        if (!phone.startsWith('+')) {
            phone = '+' + phone;
        }
        return phone;
    }

    const handleClose = () => {
        
        if (!startDate || !startTime || !endTime) {
            console.error('Please select date and time.');
            return;
        }

        // Create Date object for activityStartTime
        const startDateTime = new Date(startDate);
        const [startHours, startMinutes] = startTime.split(':').map(Number);
        startDateTime.setUTCHours(startHours);
        startDateTime.setUTCMinutes(startMinutes);

        // Create Date object for activityEndDate
        const endDateTime = new Date(startDate);
        const [endHours, endMinutes] = endTime.split(':').map(Number);
        endDateTime.setUTCHours(endHours);
        endDateTime.setUTCMinutes(endMinutes);
        
        // Format in ISO 8601 format with 'Z' timezone suffix
        const activityStartTime = startDateTime.toISOString();
        const activityEndDate = endDateTime.toISOString();

         // 2024-10-14T10:00:00.000Z
         // 2024-10-14T10:00:00.000Z
        setAcstarttime(activityStartTime);
        setAcendtime(activityEndDate)

        setShowPicker(false);
    };



    const formatDate = (date: Date | null) => {
        return date ? date.toLocaleDateString('en-US') : '';
    };

    const handleSelect = async (value: any) => {
        seteventLocation(value);
        
        if (!eventLocation) return; // Don't proceed if there's no address

        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ address: value }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
                if (results) {
                    
                    seteventLocation(results[0].formatted_address);
                }


            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });

        // You can also use geocodeByAddress and getLatLng if you want to extract the location data.
    };

    const handleCancel = () => {
        seteditEvent(false);
        setupdateError("");
    }

    const isJSONString = (str: any) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    const unString = (value: any) => {
        if (isJSONString(value)) {
            let stringValue = JSON.parse(value);
            
            return stringValue
        } else {
            
            return value
        }
    }

    const accept = (r: any) => {
        if (r.allDays == undefined || r.allDays.length == 0) {
            return 'white'
        } else if (r.allDays == true && r.notGoingDate.length == 0) {
            return "#00be9a"
        } else if (r.allDays == true && r.notGoingDate.length > 0) {
            if (checkingTwodates(r.notGoingDate) == true) {
                return "#f53131"
            } else {
                return "#00be9a"
            }
        } else if (r.allDays == false && r.goingDate.length > 0) {
            if (checkingTwodates(r.goingDate) == true) {
                return "#00be9a"
            } else {
                return "#f53131"
            }

        } else if (r.allDays == false && r.goingDate.length == 0) {
            return "#f53131"
        }
    }

    const decl = (r: any): JSX.Element | null => {
        if (r.allDays == undefined || r.allDays.length == 0) {
            return (
                <>
                    <div className={styles.buttonPrimary} style={{ backgroundColor: "#fff", color: "#000", border: "1px solid grey" }} onClick={goToName}>
                        <b className={styles.no}>Accept</b>
                    </div>

                    <div className={styles.buttonDeclainalign} style={{ backgroundColor: "#fff", color: "#000" }} onClick={declainEvent}>
                        <b className={styles.no}>Decline</b>
                    </div>
                </>
            );
        } else if (r.allDays == true && r.notGoingDate.length == 0) {
            return (
                <>
                    <div className={styles.buttonPrimary} style={{ backgroundColor: "#00be9a", color: "#fff", border: "" }} onClick={goToName}>
                        <b className={styles.no}>Accept</b>
                    </div>

                    <div className={styles.buttonDeclainalign} style={{ backgroundColor: "#fff", color: "#000" }} onClick={declainEvent}>
                        <b className={styles.no}>Decline</b>
                    </div>
                </>
            );
        } else if (r.allDays == true && r.notGoingDate.length > 0) {
            if (checkingTwodates(r.notGoingDate) == true) {
                return (
                    <>
                        <div className={styles.buttonPrimary} style={{ backgroundColor: "#fff", color: "#000", border: "1px solid grey" }} onClick={goToName}>
                            <b className={styles.no}>Accept</b>
                        </div>

                        <div className={styles.buttonDeclainalign} style={{ backgroundColor: "#f53131", color: "#fff" }} onClick={declainEvent}>
                            <b className={styles.no}>Decline</b>
                        </div>
                    </>
                );
            } else {
                return (
                    <>
                        <div className={styles.buttonPrimary} style={{ backgroundColor: "#00BE9A", color: "#fff", border: "" }} onClick={goToName}>
                            <b className={styles.no}>Accept</b>
                        </div>

                        <div className={styles.buttonDeclainalign} style={{ backgroundColor: "#fff", color: "#000" }} onClick={declainEvent}>
                            <b className={styles.no}>Decline</b>
                        </div>
                    </>
                );
            }
        } else if (r.allDays == false && r.goingDate.length > 0) {
            if (checkingTwodates(r.goingDate) == true) {
                return (
                    <>
                        <div className={styles.buttonPrimary} style={{ backgroundColor: "#00BE9A", color: "#fff", border: "" }} onClick={goToName}>
                            <b className={styles.no}>Accept</b>
                        </div>

                        <div className={styles.buttonDeclainalign} style={{ backgroundColor: "#fff", color: "#000" }} onClick={declainEvent}>
                            <b className={styles.no}>Decline</b>
                        </div>
                    </>
                );
            } else {
                return (
                    <>
                        <div className={styles.buttonPrimary} style={{ backgroundColor: "#fff", color: "#000", border: "1px solid grey" }} onClick={goToName}>
                            <b className={styles.no}>Accept</b>
                        </div>

                        <div className={styles.buttonDeclainalign} style={{ backgroundColor: "#f53131", color: "#fff" }} onClick={declainEvent}>
                            <b className={styles.no}>Decline</b>
                        </div>
                    </>
                );
            }
        } else if (r.allDays == false && r.goingDate.length == 0) {
            return (
                <>
                    <div className={styles.buttonPrimary} style={{ backgroundColor: "#fff", color: "#000", border: "1px solid grey" }} onClick={goToName}>
                        <b className={styles.no}>Accept</b>
                    </div>

                    <div className={styles.buttonDeclainalign} style={{ backgroundColor: "#f53131", color: "#fff" }} onClick={declainEvent}>
                        <b className={styles.no}>Decline</b>
                    </div>
                </>
            );
        }

        return null;
    };

    const localDate = (date: any) => {
        
        const localDate = new Date(date);
        
        const utcString = localDate.toUTCString();

        const headingformattedDate = utcString.split(' ').slice(0, 4).join(' ');
        return headingformattedDate
    }

    const timestring = (dateValue: any) => {
        
        const date = new Date(dateValue);
        
        // Extract hours and minutes
        let hours = date.getUTCHours().toString().padStart(2, '0');;
        let minutes = date.getUTCMinutes().toString().padStart(2, '0');

        // Combine hours and minutes in the desired format
        const time = `${hours}:${minutes}`;
        return time
    }

    const loginVerification =()=>{
        Cookies.set('liveId','/le/'+id, { expires: 100 })
        navigate('/signin');
  }

   const showLocationinput = () => {
        setShowlocationBox(true);
   }

    return (
        <div style={{ padding: '0px' }}>
            <div>
                {/* {bottomPopup ? <BottomPopup closePopup={togglebottomPopup} openApp={checkAndRedirectToApp} /> : <></>} */}
            </div>
            <>

                {showEventcomplte ? <><div style={{ margin: 'auto', width: "100%", textAlign: "center", paddingTop: "200px" }}>Event Completed</div></> :
                    <>
                        {showLoader ? <div className={styles.centerloader}><div className={styles.loader}></div></div> : <>
                            {numberBoolean ?
                                <>
                                    <div><Headertag showHeader={true} dropDownShow={false} /></div>
                                    <div className={`${styles.eventEventDetailsNoAcc}`}>

                                        <div className={styles.eventEventDetailsNoAccChild}>
                                            {editEvent == false ? <div style={{ textAlign: 'right', paddingRight: '15px' }}>
                                                {editBoolean == true ? <MdEdit onClick={() => { seteditEvent(true); handleClose();setShowlocationBox(false) }} /> : <></>}</div> :
                                                <div style={{ textAlign: 'right', paddingRight: '15px' }}><button style={{ marginRight: '10px', color: '#00be9a' }} onClick={() => { updateEvents(activityData) }}>Update</button><button style={{ color: 'red' }} onClick={() => { handleCancel() }} >Cancel</button></div>}
                                            <div style={{ color: 'red', textAlign: 'right', paddingRight: '15px' }}>{updateError}</div>
                                            {/* <div style={{textAlign:'right',paddingRight:'15px'}}><button onClick={()=>{seteditEvent(true)}}>Edit</button></div> */}
                                            {/* <div className={styles.adventurePlanet}>
                                        <FaArrowLeft onClick={()=>{navigate(-1)}} style={{ width: '40px', height: '36px',paddingTop:'22px' }} />

                                             Motus</div> */}
                                            <div >
                                                {editEvent == true ? <div style={{ padding: '14px 16px 14px 16px' }}>
                                                    <input
                                                        type="text"
                                                        value={eventName} // Step 2: Bind the input value to state
                                                        onChange={(e) => seteventName(e.target.value)} // Step 3: Make the input editable by updating the state
                                                    />
                                                </div> : <b className={styles.frommeShred}>{activityData?.activityName}</b>}
                                            </div>
                                            <div className={styles.p10}>
                                                <div className={styles.datecard}>
                                                    <img
                                                        className={styles.iconLocationPin}
                                                        alt=""
                                                        src="/-icon-time.svg"
                                                    />
                                                </div>
                                                <div>
                                                    {editEvent == true ? <div style={{ padding: '3px 16px 14px 16px' }}>
                                                        <input
                                                            type="text"
                                                            placeholder="select date and time"
                                                            value={formatDate(startDate)} // Bind the selected date to the input
                                                            onClick={() => setShowPicker(true)} // Show picker when input is clicked
                                                            readOnly
                                                        />
                                                        {`   ${startTime} - ${endTime}`}
                                                        {showPicker && (
                                                            <div className={styles.datetimepicker}>
                                                                <DatePicker
                                                                    selected={startDate}
                                                                    onChange={(date: Date | null) => setStartDate(date)}
                                                                    inline
                                                                />
                                                                <div>
                                                                    <label>
                                                                        Start Time:
                                                                        <TimePicker
                                                                            onChange={(time: string | null) => {
                                                                                if (time) {
                                                                                    setStartTime(time); // Only update if time is not null
                                                                                }
                                                                            }}
                                                                            value={startTime}
                                                                            clearIcon={null} // You can customize this if needed
                                                                        />
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <label>
                                                                        End Time:
                                                                        <TimePicker

                                                                            onChange={(time: string | null) => {
                                                                                if (time) {
                                                                                    setEndTime(time); // Only update if time is not null
                                                                                }
                                                                            }}
                                                                            value={endTime}
                                                                            clearIcon={null} // You can customize this if needed
                                                                        />
                                                                    </label>
                                                                </div>
                                                                <button onClick={handleClose}>Select</button>
                                                            </div>
                                                        )}
                                                    </div> : <><div >{localDate(showDate)}</div>
                                                        <div style={{ paddingLeft: "35px", color: "#958f8f" }}>{timestring(activityData.activityStartTime)} - {timestring(activityData.activityEndTime)}</div></>}
                                                    {/* <div >{rRule ? <>{checkDateandTimeview(twomontDates[0])}</> : <>{new Date(activityData.activityStartDate).toDateString()}</>}</div> */}

                                                </div>
                                            </div>
                                            {/* <div style={{ paddingLeft: '15px' }}>
                                                <div className={styles.datecard}>
                                                    <div className={styles.iconLocationPin}>&nbsp;</div>
                                                </div>
                                                <div>
                                                    <div >{rRule ? <>{chagneFistletter(rruleText)}</> : <>&nbsp;</>}</div>
                                                </div>
                                            </div> */}
                                            {activityData?.activityLocation ? <div className={styles.p10}>
                                                <div className={styles.datecard}>
                                                    <img
                                                        className={styles.iconLocationPin}
                                                        alt=""
                                                        src="/-icon-location-pin.svg"
                                                    />
                                                </div>
                                                <div>
                                                    {editEvent == true || locationbox == true ? <div style={{ padding: '3px 16px 14px 16px' }}>
                                                        {/* <input
                                                            type="text"
                                                            value={eventLocation} // Step 2: Bind the input value to state
                                                            onChange={(e) => seteventLocation(e.target.value)} // Step 3: Make the input editable by updating the state
                                                        /> */}
                                                        <PlacesAutocomplete
                                                            value={eventLocation}
                                                            onChange={seteventLocation}
                                                            onSelect={handleSelect}
                                                        >
                                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                <div>
                                                                    <input style={{ textAlign: 'center', width: '80%', height: '50px' }} {...getInputProps({ placeholder: 'Search Places ...' })} />
                                                                    {locationbox ==  true ? <><a style={{cursor:'pointer',paddingLeft:'10px'}} onClick={()=> updateEventsLocation(activityData)}> Update</a> <a style={{cursor:'pointer',paddingLeft:'10px',color:'red'}} onClick={()=> setShowlocationBox(false)}> Cancel</a></> : <></>}
                                                                    <div style={{ cursor: 'pointer' }}>
                                                                        {loading && <div>Loading...</div>}
                                                                        {suggestions.map((suggestion) => {
                                                                            const style = {
                                                                                backgroundColor: suggestion.active ? '#a8dadc' : '#fff',
                                                                                padding: "5px"
                                                                            };
                                                                            return (
                                                                                <div  {...getSuggestionItemProps(suggestion, { style })}>
                                                                                    {suggestion.description}
                                                                                </div>
                                                                            );
                                                                        })}
                                                                       
                                                                    </div>
                                                                    
                                                                </div>
                                                                
                                                            )}
                                                        </PlacesAutocomplete>
                                                        
                                                    </div> : <div>{unString(activityData?.activityLocation)} {showButton == true ? <a style={{cursor:'pointer'}} onClick={()=>{showLocationinput()}}> Change Location</a>:<></>}</div>}

                                                    {coookeboolean == false ? <div style={{textAlign:'left',paddingTop:'10px',paddingLeft:'36px',color:'red'}}>Please <a style={{color:'blue',cursor:'pointer'}} onClick={()=>{loginVerification()}}>Login</a> to change location.</div> : <></>}
                                                </div>
                                            </div> : <></>}

                                            {firstName && lastName || hostNumber ? <div className={styles.p10}>
                                                <div className={styles.datecard}>
                                                    <img
                                                        className={styles.iconLocationPin}
                                                        alt=""
                                                        src="/-icon-person.svg"
                                                    />
                                                </div>
                                                <div>
                                                    <div >Hosted by {firstName && lastName ? `${firstName} ${lastName}` : `${hostNumber}`}</div>
                                                </div>
                                            </div> : <></>}

                                        </div>
                                        <div style={{ padding: '20px' }}>
                                            <div >
                                                {activityData.activitySharedUsers ? (
                                                    <>
                                                        <b className={styles.peopleAreInvited}>{activityData?.activitySharedUsers.length} people are invited</b>
                                                    </>
                                                ) : (
                                                    <>
                                                        <b className={styles.peopleAreInvited}>0 people are invited</b>
                                                    </>
                                                )
                                                }
                                                <div>
                                                    <div className={styles.chipParent}>
                                                        {/* <div className={styles.chip}>
          <div className={styles.signUp}>Pending invite</div>
        </div> */}
                                                        {(activityData.activitySharedUsers && activityData.activitySharedUsers.length > 0) ? (
                                                            <>
                                                                {activityData?.activitySharedUsers.map((r: any, _index: any) => {

                                                                    if (r.allDays == undefined || r.allDays.length == 0) {
                                                                        
                                                                        return (<div className={styles.chip}>
                                                                            <div className={styles.ellipseParent}>
                                                                                <div className={styles.orange} />

                                                                            </div>
                                                                            <div className={styles.brianTaylor}>{invitedPeopleName(r.firstName, r.lastName, r.phone)}</div>
                                                                        </div>)

                                                                    } else if (r.allDays == true && r.notGoingDate.length == 0) {
                                                                        
                                                                        return (<div className={styles.chip}>
                                                                            <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                                                                                <FaCheckCircle size={20} color="#00be9a" />
                                                                            </div>
                                                                            <div className={styles.brianTaylor}>{invitedPeopleName(r.firstName, r.lastName, r.phone)}</div>
                                                                        </div>)
                                                                    } else if (r.allDays == true && r.notGoingDate.length > 0) {
                                                                        
                                                                        return (<div className={styles.chip}>
                                                                            {checkingTwodates(r.notGoingDate) == true ? <>
                                                                                <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                                                                                    <FaCheckCircle size={20} color="#f53131" />
                                                                                </div>
                                                                            </> : <>
                                                                                <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                                                                                    <FaCheckCircle size={20} color="#00be9a" />
                                                                                </div>
                                                                            </>}

                                                                            <div className={styles.brianTaylor}>{invitedPeopleName(r.firstName, r.lastName, r.phone)}</div>
                                                                        </div>)
                                                                    } else if (r.allDays == false && r.goingDate.length > 0) {
                                                                        
                                                                        return (<div className={styles.chip}>
                                                                            {checkingTwodates(r.goingDate) == true ? <>
                                                                                <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                                                                                    <FaCheckCircle size={20} color="#00be9a" />
                                                                                </div>
                                                                            </> : <>
                                                                                <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                                                                                    <FaCheckCircle size={20} color="#f53131" />
                                                                                </div>
                                                                            </>}

                                                                            <div className={styles.brianTaylor}>{invitedPeopleName(r.firstName, r.lastName, r.phone)}</div>
                                                                        </div>)
                                                                    } else if (r.allDays == false && r.goingDate.length == 0) {
                                                                        
                                                                        return (<div className={styles.chip}>
                                                                            <div style={{ paddingRight: '5px', paddingTop: '5px' }}>


                                                                                <FaTimesCircle size={20} color="#f53131" />
                                                                                {/* Filled version */}

                                                                                {/* <div className={styles.red} />
                    <div className={styles.check}>X</div> */}
                                                                            </div>
                                                                            <div className={styles.brianTaylor}>{invitedPeopleName(r.firstName, r.lastName, r.phone)}</div>
                                                                        </div>)
                                                                    }
                                                                }



                                                                )}
                                                            </>
                                                        ) : null

                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            

                                            {activityData?.activityDescription ? <div>
                                                <div>
                                                    <b className={styles.details}>Details</b>
                                                    <div className={styles.plr10}>
                                                        {activityData?.activityDescription}

                                                    </div>
                                                </div>
                                                
                                            </div> : <></>}

                                        </div>
                                        {showButton == true ? <div className={`${styles.bottomfixed} ${styles.contentscroll} `} style={{ width: "100%", display: 'flex' }}>

                                            {decl(specificUser)}
                                        </div> : <></>}



                                    </div>

                                    <>
                                        {chooseDateType ? <Popup title="RSVP to recurring event" closePopup={togglePopup} chooseTypeCalender={submitnextrrdate} close={close} /> : ''}
                                    </>

                                    <>
                                        {typeCalender ? <CalenderPopup title="Please choose type" phone={cookeValue.phone.trim()} repeatrule={activityData.activityRepeatRule} shareduser={activityData.activitySharedUsers} eventsArray={eventDates} submitcalendarAction={submitcalendarAction} closeCalandar={closeCalandar} closePopup={chooseTypeCalender} passcolorDates={passcolorDates} disabledDate={disabledDate} /> : ''}
                                    </>
                                </> : <><div style={{ margin: 'auto', width: "100%", textAlign: "center", paddingTop: "200px" }}>No Data Available</div></>}
                        </>}

                    </>
                }
            </>

            <ToastContainer />
        </div>

    );
};

export default SingleEvent;
