import {
    useEffect,
    useState,
} from 'react';
import styles from "./FeedbackList.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import Headertag from './header';
import axios from 'axios';


const apiUrl = "../api";

const FeedbackList = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showLoader, setshowLoader] = useState(false);
    const location = useLocation();
    const [feedbackList, setFeedBackList] = useState([]);
    const { phone, status } = location.state || {};

    useEffect(() => {
        setshowLoader(true);
        getUser();

    }, [])

    const getUser = async () => {
        const CookeData = Cookies.get('phoneNumber');
        let cookieData = CookeData ? JSON.parse(CookeData) : {};
        setPhoneNumber(cookieData.phone);
        let user = ''
        try {
            const responseData = await axios.get(`${apiUrl}/feedback`);
            
            const DataList = responseData['data'].sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            setFeedBackList(DataList);
            setshowLoader(false);
        } catch (err: any) {
            
            setshowLoader(false);

        }

    }



    return (
        <>
            <div><Headertag showHeader={status} dropDownShow={false} /></div>
            {showLoader ? <div className={styles.centerloader}><div className={styles.loader}></div></div> :
                <div className={styles.loginContainer}>

                    <div className={styles.loginlogo}>
                        <img src="./logo.png" alt="Motus Logo" />
                    </div>
                    <h2>Motus <span className={styles.betatag}>Beta</span></h2>

                    <div style={{ marginBottom: '20px' }}>
                        <h4>Feedback List</h4>
                        <table style={{ borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Phone</th>
                                    <th>Comments</th>
                                    <th>Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {feedbackList.map((item: any) => (
                                    <tr key={item.id}>
                                        <td>{item.firstName}</td>
                                        <td>{item.lastName}</td>
                                        <td>{item.phone}</td>
                                        <td>{item.comments}</td>
                                        <td>{new Date(item.createdAt).toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                </div>}

        </>
    );
}

export default FeedbackList