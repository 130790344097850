import React,{ useEffect, useState } from 'react';
import '../global.css'

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };
  
  const center = {
    lat: 37.7749, // Default latitude
    lng: -122.4194, // Default longitude
  };


  // const GOOGLE_MAPS_API_KEY = 'AIzaSyAXrPVn3SjLfblK9zpY_cPZg_LP3Tw3LiM';
  // const GOOGLE_MAPS_API_KEY = 'AIzaSyDPO7NitJcW2y-w0pBRiKB8URZggvR7FkM ';
  
  const GOOGLE_MAPS_API_KEY = 'AIzaSyCuAPKydbCX6pJUoPE6ldA30FgT4nR_3Jk';

const MapPopup = (props:any) => {
    const [selectedPosition, setSelectedPosition] = useState<google.maps.LatLngLiteral | null>(null);
    const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
    const [center, setCenter] = useState({ lat: 49.28817, lng: -123.1155982 });
    
    useEffect(() => {
      
      if(props.position){
        setSelectedPosition(props.position);
        setCenter(props.position)
      }else{
        
        setSelectedPosition({ lat: 49.28817, lng: -123.1155982 })
        setCenter({ lat: 49.28817, lng: -123.1155982 })
      }
      
      // Handle position change...
  }, [props.position]);
    

    const handleMapClick = (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
          const latLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          
          setSelectedPosition(latLng);
          reverseGeocode(latLng);
        }
      };
    
      const reverseGeocode = async (latLng: google.maps.LatLngLiteral) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: latLng }, (results, status) => {
            
          if (status === 'OK' && results && results.length > 0) {
            setSelectedAddress(results[0].formatted_address);
            
            props.setSelectedAdres(results[0].formatted_address)
          } else {
            setSelectedAddress('Address not found');
          }
        });
      };

  return (
    <div>
      <h6>Select an Area on the Map</h6>
      {/* <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}> */}
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={12}
          onClick={handleMapClick}
        >
          {selectedPosition && <Marker position={selectedPosition} />}
        </GoogleMap>
      {/* </LoadScript> */}

      {/* {selectedPosition && (
        <div>
          <h6>Selected Coordinates</h6>
          <p>Latitude: {selectedPosition.lat}</p>
          <p>Longitude: {selectedPosition.lng}</p>
          {selectedAddress && (
            <>
              <h6>Selected Address</h6>
              <p>{selectedAddress}</p>
            </>
          )}
        </div>
      )} */}
    </div>
//     <div className="popup2">
//       <div className="popup_inner_3">
//         <div style={{width:'100%',flexDirection:'row',display: 'flex',color:'#000'}}>
//           <div style={{flex:8}}>
//           <h5>See this invite in...</h5>
//           </div>
//           <div style={{flex:4,textAlign:'right'}}>
//           <button style={{background:'none'}} onClick={ props.closePopup }  ><span className="crosssign">
//   {/* <div className="crosssign_circle"></div> */}
//   <div className="crosssign_stem"></div>
//   <div className="crosssign_stem2"></div>
// </span></button>
//           </div>
//         </div>
//     <div>
    
        
//     </div>
//       </div>
//     </div>   
  )
}
export default MapPopup
