import React from 'react';
import '../global.css'
import CalenderPopup from './calenderpopup';

const BottomPopup = (props:any) => {
  return (
    <div className="popup">
      <div className="popup_inner_3">
        <div style={{width:'100%',flexDirection:'row',display: 'flex',color:'#000'}}>
          <div style={{flex:8}}>
          <h5>See this invite in...</h5>
          </div>
          <div style={{flex:4,textAlign:'right'}}>
          <button style={{background:'none'}} onClick={ props.closePopup }  ><span className="crosssign">
  {/* <div className="crosssign_circle"></div> */}
  <div className="crosssign_stem"></div>
  <div className="crosssign_stem2"></div>
</span></button>
          </div>
        </div>
    <div>
        <div style={{width:"100%",flexDirection:'row',display: 'flex',paddingTop:'20px',}}>
        <h5 style={{width:"75%",color:'#000',padding: "10px 10px 10px 0px"}}>Motus App</h5>
        <button style={{width:"25%",background:'#000 !important',borderRadius:'5px'}}  className='buttonprimary_popu'  onClick={ props.openApp }> Get App </button>
        </div>
    
      <div style={{width:"100%",flexDirection:'row',display: 'flex',paddingTop:'5px',}}>
      <h5 style={{width:"75%",color:'#000',padding: "10px 10px 10px 0px"}}>Browser</h5>
      <button style={{width:"25%",borderRadius:'5px',background:'#fff',border: '1px solid black',color: '#000'}} className='buttonprimary_popu' onClick={ props.closePopup } > Continue </button>
      </div>
    
        
        
    </div>
      </div>
    </div>   
  )
}
export default BottomPopup
