import {
    SyntheticEvent,
    useEffect,
    useState,
} from 'react';
import { FunctionComponent } from "react";
import styles from "./Userdetail.module.css";
import { Container } from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';

const ProfilePage = () => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [errorstatus, seterrorstatus] = useState(true);
    const location = useLocation();
    const { phone } = location.state || {};

    useEffect(()=>{
        // setPhoneNumber(phone)
        const storedNumber = Cookies.get('phoneNumber');
        if(storedNumber){
            const values=JSON.parse(storedNumber);
            setFirstname(values.firstname);
            setLastname(values.lastname);
            setPhoneNumber(values.phone);
        }

    })

    const handleLogout = () => {
        // Remove specific cookies, e.g., 'authToken' or 'userSession'
        Cookies.remove('phoneNumber');
    
        // Optionally, you can clear other cookies or perform additional cleanup actions here.
    
        // Redirect to the login page or homepage after logout
        navigate('/signin');
      };



    return (
        <div className={styles.loginContainer}>
            <div className={styles.loginlogo}>
                <img src="./logo.png" alt="Motus Logo" />
            </div>
            <h2>Motus <span className={styles.betatag}>Beta</span></h2>
            {/* <h6>Sign in/ Sign up to Motus</h6> */}
            <div>
                <p>{firstname}</p>
                <p>{lastname}</p>
                <p>{phoneNumber}</p>
            </div>
            <div style={{padding:'20px'}}>
                <p><a onClick={()=> handleLogout()}>Logout</a></p>
            </div>
            {/* <form className={styles.loginform} onSubmit={handleSubmit}>
                <div className={styles.phoneinputmargin}>
                    <div style={{textAlign:'left'}}>
                        <label>First name</label>
                    </div>
                    <div className={styles.phoneinput}>
                        <input
                            type="text"
                            value={firstname}
                            onChange={handlefistname}
                            placeholder="First name"
                            className={styles.phonenumber}
                        />

                    </div>
                </div>
                <div className={styles.phoneinputmargin}>
                    <div style={{textAlign:'left'}}>
                        <label>Last name</label>
                    </div>
                    <div className={styles.phoneinput}>
                        <input
                            type="text"
                            value={lastname}
                            onChange={handlelastname}
                            placeholder="Last name"
                            className={styles.phonenumber}
                        />

                    </div>
                </div>
                <div className={styles.phoneinputmargin}>
                    <div style={{textAlign:'left'}}>
                        <label>Phone number</label>
                    </div>
                    <div className={styles.phoneinput}>
                        <input
                            type="text"
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                            placeholder="Phone Number"
                            className={styles.phonenumber}
                            disabled
                        />
                       
                    </div>
                    <p style={{textAlign:'right'}}><a onClick={()=> navigate('/signin')}>Click here to change phone number</a></p>
                </div>
                <button type="submit" className={styles.submitbtn} disabled={checkallfields()}>
                    Sign up
                </button>
            </form> */}
        </div>
    );
}

export default ProfilePage