import { FunctionComponent,useEffect, useState } from "react";
import styles from "./Eventlist.module.css";
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule';
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { isMobile, isAndroid } from 'react-device-detect';
import SignupFormPopup from "./SignupFormPopup";
import OpenApp from 'web-open-app';
import axios from 'axios';
import Cookies from "js-cookie";
import { colors } from "@material-ui/core";
import Acitivitycalender from "./ActivityCalender";

interface RelatedApplication {
    id: string;
    platform: string;
}

interface EventList {
    noti: any
}

const apiUrl = "../api";
const EventList: FunctionComponent<EventList> = (props) => {
    const [gridevent, setgridevent]: any = useState(false);
    const [signedUser, setSignedUser]: any = useState('');
    const [AllEvents, setAllEvents]: any = useState([]);
    const [browserdata, setBrowserData]: any = useSearchParams();
    const [previousMonth, setPreviousMonth]: any = useState('');
    const [previousYear, setPreviousYear]: any = useState('');
    const [activityData, setactivityData]: any = useState([]);
    const [holdMonth, setHoldMonth]: any = useState('');
    const [holdYear, setHoldYear]: any = useState('');
    const [boolean, setBoolean]: any = useState(false);
    const [indexValue, setindexValue]: any = useState(-1);
    const navigate = useNavigate();
    const location = useLocation();
    const [isAppInstalled, setIsAppInstalled] = useState(false);
    const [testbool, settestbool] = useState(true);
    const [testboolpopup, settestboolpopup] = useState(false);
    const [commingSoon, setcommingSoon] = useState(false);
    const [Installed, setInstalled] = useState(false);
    const [isAppInstalledapp, setIsAppInstalledapp] = useState(null);
    const [statustext, setstatustext] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');
    const [ruleShow, setruleShow] = useState(false);
    const [sumbmitloader, setSumbmitLoader] = useState(false);

    useEffect(() => {
        fetchTodos();
        
        setcommingSoon(false);

        const checkAppInstallation = async () => {
            if ('getInstalledRelatedApps' in navigator && typeof navigator.getInstalledRelatedApps === 'function') {
                try {
                    const relatedApps = await navigator.getInstalledRelatedApps?.();
                    if (relatedApps) {
                        const isInstalled = relatedApps.some((app: any) => {
                            // Replace 'com.example.app' with your package name or bundle identifier
                            return app.id === 'adventureplanet.com://';
                        });

                        // alert("app is installed"+isInstalled)
                        setIsAppInstalled(isInstalled);
                        // alert(isInstalled);
                    }
                } catch (error) {
                    console.error('Error checking app installation:', error);
                }
            } else {
                console.warn('getInstalledRelatedApps is not supported in this browser.');
            }
        };

        checkAppInstallation();


    }, []);





    const openapp = new OpenApp({
        scheme: "motus://",
        applink: "",
        iosUseScheme: true,
        copyText: "number: " + Math.ceil(Math.random() * 10000),
        callback: function (status: any, msg: any) {
            
            setstatustext(status);
            if (status == 'FAILED') {
                window.location.href = "https://apps.apple.com/ca/app/motus-fun/id6474702612";
            }
      
        }
    })

    const openappandroid = new OpenApp({
        scheme: "adventureplanet.com://",
        applink: "",
        copyText: "number: " + Math.ceil(Math.random() * 10000),
        callback: function (status: any, msg: any) {
            
            setstatustext(status);
            if (status == 'FAILED') {
                window.location.href = "https://play.google.com/store/apps/details?id=fun.motus.app&hl=en_CA&gl=US";
            }
        }
    })



    const checkAndRedirectToApp = async () => {
        const appPackageName = 'adventureplanet.com://'; // Replace with your app's package name
        try {
            if (isMobile && !isAndroid) {
                // iOS device
                
                checkIsAppInstalledIOS();
            } else if (isMobile && isAndroid) {
                
                checkIsAppInstalledAndroid();
            } else {
                
                
                window.location.href = "https://play.google.com/store/apps/details?id=fun.motus.app&hl=en_CA&gl=US";
            }

        } catch (error) {
            console.error('Error during redirection:', error);
        }
    };

    const checkIsAppInstalledAndroid = async () => {
        // Define your app's custom URI scheme
        const customScheme = `adventureplanet.com://`;

        return new Promise((resolve) => {
            // Create an invisible iframe and try to open your app's custom URI scheme
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = customScheme;
            document.body.appendChild(iframe);

            // Set a timeout to determine if the app is installed or not
            const timeout = setTimeout(() => {
                document.body.removeChild(iframe);

                resolve(false); // Timeout occurred, app is likely not installed
                if (Installed == false) {
                    window.location.href = "https://play.google.com/store/apps/details?id=fun.motus.app&hl=en_CA&gl=US";
                }
            }, 2000); // Adjust the timeout duration as needed

            // Listen for when the app's custom URI scheme is opened
            window.addEventListener('blur', () => {
                clearTimeout(timeout);
                document.body.removeChild(iframe);
                setInstalled(true);

                resolve(true); // App is installed
                window.location.href = customScheme;
            });
        });
    };

    const checkIsAppInstalledIOS = async () => {
        // Define your app's custom URI scheme
        const customSchemeIos = `motus://`;

        return new Promise((resolve) => {
            // Create an invisible iframe and try to open your app's custom URI scheme
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = customSchemeIos;
            document.body.appendChild(iframe);

            // Set a timeout to determine if the app is installed or not
            const timeout = setTimeout(() => {
                document.body.removeChild(iframe);

                resolve(false); // Timeout occurred, app is likely not installed
                if (Installed == false) {
                    window.location.href = "https://apps.apple.com/ca/app/motus-fun/id6474702612";
                }
            }, 2000); // Adjust the timeout duration as needed

            // Listen for when the app's custom URI scheme is opened
            window.addEventListener('blur', () => {
                clearTimeout(timeout);
                document.body.removeChild(iframe);
                setInstalled(true);

                resolve(true); // App is installed
                window.location.href = customSchemeIos;
            });
        });
    };

    const news = async () => {
        window.location.href = "https://apps.apple.com/ca/app/motus-fun/id6474702612";
    }

    const close = () => {
        
        settestboolpopup(true);
    }


    const formatPhoneNumber = (phone: string) => {
        phone = phone.toString(); // Ensure the phone is a string
        if (!phone.startsWith('+')) {
            phone = '+' + phone;
        }
        return phone;
    }

    const fetchTodos = async () => {
        setSumbmitLoader(true);
        const CookeData = Cookies.get('phoneNumber');
        let cookieData = CookeData ? JSON.parse(CookeData) : {};
        
        setphoneNumber(cookieData.phone)
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        try {
            const todoData = await axios.get(`${apiUrl}/draftEvent/activity/list`);
            
            const activityList = todoData?.data;
            const eventList: any = activityList.filter((val: any) => {
                
                let owner = JSON.parse(val['activityOwner']);
                let activiuse = JSON.parse(val['activitySharedUsers']);
                val['draft'] = false;
                let findparticipatns = activiuse.find((s: any) => formatPhoneNumber(s.phone) == cookieData.phone);
                
                if (findparticipatns) {
                    
                    return val;
                }

            })
            
            
            setactivityData(eventList);

            const dates: string[] = [];
            const activityData: any[] = [];
            // 
            for (var i = 0; i < eventList.length; i++) {
                let rule: any = []
                let rulesdatanew: any = [];
                let ownerId = JSON.parse(eventList[i].activityOwner);
                // if (activityList[i].activityType == '2' ) {
                if (eventList[i].activityGroupId) {
                    
                    rulesdatanew.push(new Date(eventList[i].activityStartDate));
                    setruleShow(false);
                    
                } else {
                    if (eventList[i].activityRepeatRule == "") {
                        
                        rulesdatanew.push(eventList[i].activityStartDate);
                        setruleShow(false);
                    } else {
                        setruleShow(true);
                        
                        let ruleData = eventList[i].activityRepeatRule as string;
                        const ruleArray = RRule.fromString(ruleData);
                        // 
                        rulesdatanew = ruleArray.between(datetime(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate() - 1), datetime(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate() + 10));
                        
                        if (rulesdatanew.length > 0) {
                            let get_Month = new Date(rulesdatanew[0]);
                            
                            rulesdatanew = ruleArray.between(datetime(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate() - 1), datetime(get_Month.getFullYear(), get_Month.getMonth() + 1, new Date().getDate() + 30));
                            // 
                            setPreviousMonth(get_Month.getMonth() + 1);
                            setPreviousYear(get_Month.getFullYear());
                            setHoldMonth(get_Month.getMonth() + 1);
                            setHoldYear(get_Month.getFullYear());
                        }
                        // rulesdatanew=ruleArray.all()
                    }
                }

                // }


                // 

                const rulesdata = rulesdatanew.filter((dateStr: string | number | Date) => {
                    let toLocalstring=new Date(dateStr).toLocaleDateString();
                    const date = new Date(eventList[i].activityStartTime);
                    
                    
                    return date >= new Date(new Date().setDate(new Date().getDate() - 1));
                });
                
                // for(var k=0;k<rulesdatanew.length;k++){

                // }
                // 
                for (var j = 0; j < rulesdata.length; j++) {
                    if (!dates.includes(rulesdata[j].toDateString())) {
                        // 
                        dates.push(rulesdata[j].toDateString())
                        const data: any = {}
                        const arrayList = []
                        arrayList.push(eventList[i]);
                        data.actualtime=eventList[i].activityStartTime;
                        data.date = rulesdata[j].toDateString();
                        data.arrayList = arrayList

                        activityData.push(data)

                    } else {
                        // 
                        for (var k = 0; k < activityData.length; k++) {
                            if (rulesdata[j].toDateString() == activityData[k].date) {
                                let sharedUsers = JSON.parse(eventList[i].activitySharedUsers)
                                activityData[k].arrayList.push(eventList[i])

                            }

                        }
                    }
                }
            }
            
            setAllEvents(activityData);
            setSumbmitLoader(false);
        }
        catch (error) {
            setSumbmitLoader(false);
            
        }

    }

    const viewEventacivity = (id: any, date: any) => {
        
        Cookies.set("eventDate", date, { expires: 100 })
        navigate('/le/' + id, { state: { status: false } })
    }

    const fetchTodosReadmore = async (data: any) => {
        
        try {
            const activityList = activityData;
            // 
            const dates: string[] = [];
            const activityDataList: any[] = [];
            for (var i = 0; i < activityList.length; i++) {
                let rule: any = []
                let rulesdatanew: any = [];
                let ownerId = JSON.parse(activityList[i].activityOwner);
                // if (activityList[i].activityType == '2') {
                if (activityList[i].activityGroupId) {
                    
                    rulesdatanew.push(new Date(activityList[i].activityStartDate));
                } else {
                    if (activityList[i].activityRepeatRule == "") {

                        rulesdatanew.push(activityList[i].activityStartDate);
                    } else {
                        let ruleData = activityList[i].activityRepeatRule as string;
                        const ruleArray = RRule.fromString(ruleData);
                        if (data === 0) {
                            rulesdatanew = ruleArray.between(datetime(previousYear, previousMonth - 1, new Date().getDate()), datetime(previousYear, previousMonth - 1, new Date().getDate() + 30));
                            setPreviousMonth(previousMonth - 1);
                            setPreviousYear(previousYear);
                        } else if (data === 1) {
                            rulesdatanew = ruleArray.between(datetime(previousYear, previousMonth + 1, new Date().getDate()), datetime(previousYear, previousMonth + 1, new Date().getDate() + 30));
                            setPreviousMonth(previousMonth + 1);
                            setPreviousYear(previousYear);
                        }



                       
                    }
                }

               
                const rulesdata = rulesdatanew.filter((dateStr: string | number | Date) => {
                    let toLocalstringRedo=new Date(dateStr).toLocaleDateString();
                    const date = new Date(toLocalstringRedo);
                    return date >= new Date();
                });
              
                for (var j = 0; j < rulesdata.length; j++) {
                    if (!dates.includes(rulesdata[j].toDateString())) {
                      
                        dates.push(rulesdata[j].toDateString())
                        const data: any = {}
                        const arrayList = []
                        arrayList.push(activityList[i])
                        data.date = rulesdata[j].toDateString()
                        data.arrayList = arrayList
                        activityDataList.push(data)
                    } else {
                        // 
                        for (var k = 0; k < activityDataList.length; k++) {
                            if (rulesdata[j].toDateString() == activityDataList[k].date) {
                                let sharedUsers = JSON.parse(activityList[i].activitySharedUsers)
                                activityDataList[k].arrayList.push(activityList[i])
                            }
                     
                        }
                    }
                }
            }
            
            setAllEvents(activityDataList)
        }
        catch (error) {
            
        }
    }




    const timestring = (dateValue: any) => {
        
        const date = new Date(dateValue);
        
        // Extract hours and minutes
        let hours = date.getUTCHours().toString().padStart(2, '0');;
        let minutes = date.getUTCMinutes().toString().padStart(2, '0');

        // Combine hours and minutes in the desired format
        const time = `${hours}:${minutes}`;
        return time
    }

    const testFuntion = (value: any, length: any) => {
        if (length > 1) {
            setBoolean(true);
            setindexValue(value)
        }

    }

    

    const checkDates = (date: any, users: any) => {
        let sharedUser = JSON.parse(users);
        // 
        const myEvent = sharedUser.find((val: any) => formatPhoneNumber(val.phone) == phoneNumber);

        if (!myEvent) {
            
            return "";
        }

        

        // Check allDays condition
        if (myEvent.allDays === "") {
            return "Pending";
        } else if (myEvent.allDays === true) {
            return "✓ You're going";
        } else if (myEvent.allDays === false) {
            // Handle the case when `allDays` is false and `notGoingDate` exists
            if (myEvent.notGoingDate?.length > 0) {
                let headerDate = new Date(date);
                let result = myEvent.notGoingDate.some((dateStr: string) => {
                    let vvDate = new Date(dateStr);
                    return isSameDate(headerDate, vvDate);
                });

                if (result) {
                    return "X You're not going";
                } else {
                    return "✓ You're going"

                }
            }

            // Handle the case when `goingDate` exists
            if (myEvent.goingDate?.length > 0) {
                let headerDate = new Date(date);
                let result = myEvent.goingDate.some((dateStr: string) => {
                    let vvDate = new Date(dateStr);
                    return isSameDate(headerDate, vvDate);
                });

                if (result) {
                    return "✓ You're going";
                } else {
                    return "X You're not going";
                }
            }

            return "X You're not going";
        }

        return ""; // Default case if no conditions match
    };

    const isSameDate = (date1: any, date2: any) => {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }

    const localDate = (dateString: any) => {
        
        const localDate = new Date(dateString);
        
        const utcString = localDate.toUTCString();

        const headingformattedDate = utcString.split(' ').slice(0, 4).join(' ');
        return headingformattedDate
    }

    const viewData = AllEvents.map((course: any, index: any) =>
        <div>

            <div className={styles.leftAlign}>
                <b className={styles.jodyWilliamsIsContainer}>{localDate(course.actualtime)}</b>
            </div>
            <div>
                <div className={(boolean === true && index == indexValue) ? styles.buttonwrapsd : styles.buttonwrap} style={(boolean === true && index == indexValue) ? { 'height': ((course.arrayList.length * 85) + 100) } : {}}>
                    {course.arrayList.map((item: any, i: any) =>

                        <>


                            <div className={styles.buttonView} style={(boolean === true && index == indexValue) ? { "transform": 'translate(0px,' + ((course.arrayList.length * 85) - (i * 100)) + 'px)', width: "100%" } : { width: "100%" }} onClick={() => { testFuntion(index, course.arrayList.length) }}>
                                <div className={item.activityType == '1' ? styles.leftborderE : styles.leftborderA}>
                                    <div className={styles.container1}>
                                        <div className={item.activityType == '1' ? styles.eventround : styles.availablityround}></div>
                                        <span style={{ width: '35%', textAlign: 'left' }} className={styles.text}>{item.activityType == '1' ? item.activityName : 'Available'} </span>
                                        {(course.arrayList.length > 1 && index != indexValue) ? <div style={{ width: '35%' }} >
                                            <div className={styles.text2}>{course.arrayList.length > 1 ? <div className={styles.text3}>+{(course.arrayList.length - 1)} others</div> : ''} </div>
                                        </div> : ''}

                                        {item.activityType == '1' ? <>{(course.arrayList.length > 1 && index != indexValue) ? <div style={{ width: '35%', paddingBottom: "10px" }}><div style={{ textAlign: 'right', color: checkDates(course.date, item.activitySharedUsers) === "✓ You're going" ? 'green' : checkDates(course.date, item.activitySharedUsers) === "X You're not going" ? 'red' : checkDates(course.date, item.activitySharedUsers) === "Pending" ? 'orange' : 'black' }}>{checkDates(course.date, item.activitySharedUsers)}</div></div> : <div style={{ width: '70%', paddingBottom: "10px" }}><div style={{ textAlign: 'right', color: checkDates(course.date, item.activitySharedUsers) === "✓ You're going" ? 'green' : checkDates(course.date, item.activitySharedUsers) === "X You're not going" ? 'red' : checkDates(course.date, item.activitySharedUsers) === "Pending" ? 'orange' : 'black' }}>{checkDates(course.date, item.activitySharedUsers)}</div></div>}</> : <></>}


                                    </div>
                                    <div className={styles.container3} >
                                        {item.activityType == '1' ? <div className={styles.outerschedule}><p className={styles.schedule} style={{ cursor: 'pointer' }} onClick={() => { viewEventacivity(item.id, course.actualtime) }}><div>{checkDates(course.date, item.activitySharedUsers) == 'Pending' ? 'RSVP' : 'View'}</div></p></div> : <></>}
                                    </div>
                                    <div className={styles.container2}>
                                        <div >{timestring(item.activityStartTime)} - {timestring(item.activityEndTime)}</div>
                                    </div>
                                </div>

                            </div>

                        </>

                    )}

                    {/* {arrayDataItems(course.arrayList)} */}
                </div>

            </div>
        </div>

    )

  

    return (
       
        <div >

            <>{gridevent == true ? <Acitivitycalender /> : <>
                {commingSoon == true ? <>
                    {/* <div>---{isMobile}</div> */}
                    {isMobile && !isAndroid ? <><div className={styles.aboveloader}><button className={styles.loadmore} onClick={() => news()}>Download Motus</button></div></> : <div className={styles.aboveloader}>
                        <div className={styles.loader}></div>
                    </div>}
                </> : <>
                    {viewData.length > 0 ? <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                        {viewData}
                        
                        {ruleShow == true ? <div style={{ width: '100%', padding: '20px', textAlign: 'center' }}>
                            <button className={styles.loadmore} onClick={() => fetchTodosReadmore(1)}>load more</button>
                        </div> : <></>}

                    </div> : <>
                        {sumbmitloader == true ? <div className={styles.centerloader}>
                            <div className={styles.spinner}></div>
                        </div> : <><div style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '20px' }}>
                            <div>No Available Event </div>
                            <div>Go to <a style={{ cursor: 'pointer' }} onClick={() => { props.noti('draftEvent1') }}>Draft Events</a> or <a style={{ cursor: 'pointer' }} onClick={() => { props.noti('draftEvent2') }}>Archived Events</a></div>
                        </div></>}
                    </>}

                    
                </>
                }
            </>}</>
        </div>


    );
};

export default EventList;
