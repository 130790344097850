import { FunctionComponent, SyntheticEvent, useEffect, useState } from "react";
import styles from "./EventEventDetailsNoAcc.module.css";
import { useNavigate } from "react-router-dom";
import ApiCalendar from 'react-google-calendar-api';
import { isMobile, isAndroid } from 'react-device-detect';
import { RRule, datetime } from "rrule";
import GooglePopup from "./GoogleCalenderPopup";
// const config = {
//   "clientId": "523412829126-9c4i546ne8n0lhpkj8vsjnb06m6ehi2b.apps.googleusercontent.com",
//   "apiKey": "AIzaSyAjMHfAw4s3tdQ6MqtE17miWT9kyGYe4Zc",
//   "scope": "https://www.googleapis.com/auth/calendar",
//   "discoveryDocs": [
//     "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
//   ]
// }

const config = {
  "clientId": "698185071274-qe0cjpk6c0isre2o1vu43vq6grg4pi59.apps.googleusercontent.com",
  "apiKey": "AIzaSyB7B8CTu3vD23jMjOLfYqIu43pNRJXWkK4",
  "scope": "https://www.googleapis.com/auth/calendar",
  "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}

const apiCalendar = new ApiCalendar(config)
const EventEventDetailsNoAcc: FunctionComponent = () => {
  const [activityData,setActivityData]:any = useState('');
  const [activityId,setActivityId]:any = useState('');
  const [events, setEvents] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [Installed , setInstalled] = useState(false);
  const [showPopup , setshowPopup] = useState(false);
  const [showId , setId] = useState('');
  const [showAGCB , setshowAGCB]:any = useState('false');
  const [twomontDates, settwomontDates] = useState<Date[]>([]);
  const [rRule, setrRule] = useState(false);

  const navigate = useNavigate()
  useEffect(() => {
    
    if(!activityData || activityData =="" || activityData == undefined){
      fetchTodos();
    }

    
    const checkAppInstallation = async () => {
      if ('getInstalledRelatedApps' in navigator && typeof navigator.getInstalledRelatedApps === 'function') {
        try {
          const relatedApps = await navigator.getInstalledRelatedApps?.();
          if (relatedApps) {
            const isInstalled = relatedApps.some((app:any) => {
              // Replace 'com.example.app' with your package name or bundle identifier
              return app.id === 'adventureplanet.com://';
            });
            setIsAppInstalled(isInstalled);
            // alert(isInstalled);
          }
        } catch (error) {
          console.error('Error checking app installation:', error);
        }
      } else {
        console.warn('getInstalledRelatedApps is not supported in this browser.');
      }
    };

    checkAppInstallation();
  })

  

  
  const handleItemClick = (event: SyntheticEvent<any>, name: string): void => {
    if (name === 'sign-in') {
      apiCalendar.handleAuthClick()
    } else if (name === 'sign-out') {
      apiCalendar.handleSignoutClick();
    }
  };
  
  async function fetchTodos() {
    try {
      let activityNewData:any = localStorage.getItem("activityData");
      let activityNewId:any = localStorage.getItem("activityId");
      
      let activityJsonData :any = JSON.parse(activityNewData);
      let activityOwnerData = JSON.parse(activityJsonData.activityOwner)
      activityJsonData.activityOwner = activityOwnerData
      
      const ss = RRule.fromString(activityJsonData.activityRepeatRule);
      settwomontDates(ss.between(datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()), datetime(new Date().getFullYear(), new Date().getMonth()+2, new Date().getDate()+31)));
      let resulta = activityJsonData.activityRepeatRule.includes("FREQ");
      
      if (resulta == true) {
        
       setrRule(true);
      } else {
        
        setrRule(false);
        // if(checkDateandTime(activityData.activityStartTime)){
        //   setshowEventcomplte(true)
        // }else{
        //   
        //   setshowEventcomplte(false)
         
        // }
      }
      setActivityData(activityJsonData)
      setActivityId(activityNewId)
    } catch (err) {
      
    }
  } 

  const checkDateandTimeview = (eventtime:any) =>{
    
        let activityStime=new Date(activityData.activityStartTime);
        
        let eventDateandTime=new Date(eventtime);
        eventDateandTime.setHours(activityStime.getHours());
        eventDateandTime.setMinutes(activityStime.getMinutes());

        let currentDate =new Date();
        // 
        
        
        if(currentDate > eventDateandTime){
          let vv1=twomontDates[1].toDateString();
          
            return vv1
        }else{
         
          let vv2=twomontDates[0].toDateString();
          
          return vv2
        }
  }
  

  const addToGoogleCalender = () =>{
    
    // localStorage.setItem('showAGC','true');
    // 
    // let sh=localStorage.getItem('showAGC')
    // setshowAGCB(sh);
    
    // setId("Event saved successfully to hari@tryangled.com"+name)
    // setshowPopup(true);
    apiCalendar.handleAuthClick()
    .then((value) => {
     
      
      const eventFromNow: any = {
        summary: activityData.activityName,
        description: 'This is a test event',
        location: 'Canada',
        start: {
          dateTime:rRule ? new Date(checkDateandTimeview(twomontDates[0])).toISOString() : new Date(activityData.activityStartTime).toISOString() ,
          timeZone: 'Canada/Newfoundland',
        },
        end: {
          dateTime: new Date(activityData.activityEndTime).toISOString(),
          timeZone: 'Canada/Newfoundland',
        },
      };
      
      apiCalendar
        .createEvent(eventFromNow)
        .then((result: any) => {
          
          
          let name=result['result']['creator']['email'];
          setId("Event saved successfully to "+name)
          setshowPopup(true);
          // localStorage.setItem('showAGC','false');
          setshowAGCB(false)
        })
        .catch((error: any) => {
          
          setId("Error : "+error.result.error.message)
          setshowPopup(true);
        });
    })
    .catch((error) => {
      console.error('Error during login:', error);
    });

    
     }
  
  const backToEventDetails = () =>{
    navigate("/event-event-details-browser1?id="+activityId+"&phone=+"+localStorage.getItem('Phonenumber'))
  }
  const goToAppDownload = () =>{
    // const ua = navigator.userAgent.toLowerCase()
    //   const isAndroid = ua.includes('android')
    //   if(isAndroid==true){
    //     window.location.href="https://play.google.com/store/apps/details?id=com.whatsapp&pcampaignid=web_share"
    //   }
    //   
    //   const isIPhone:any = (navigator.userAgent.match(/iPhone/i)) ||(navigator.userAgent.match(/iPod/i))
    //   if(isIPhone == "iPhone"){
    //     window.location.href="https://apps.apple.com/in/app/whatsapp-messenger/id310633997"
    //   }
    //   

  }

  const checkAndRedirectToAppCreateAccoount = () => {
    
    // alert("open app");
    const appPackageName = 'adventureplanet.com://'; // Replace with your app's package name
    try { 
    if (isMobile && !isAndroid) {
      // iOS device
      // window.location.href = appPackageName;
      // setTimeout(function() { window.location.href="https://app.motus.fun/?redirect=invite"; }, 2000);
      checkIsAppInstalledIOS();
    } else if (isMobile && isAndroid) {
      
      // Android device
      // window.location.href = appPackageName;
      // setTimeout(function() { window.location.href="https://app.motus.fun/?redirect=invite"; }, 2000);
      checkIsAppInstalledAndroid();
    } else {
      
      
        // window.location.href = appPackageName;
        // setTimeout(function() { window.location.href="https://app.motus.fun/?redirect=invite"; }, 2000);
        checkIsAppInstalledAndroid();
    }

  } catch (error) {
    console.error('Error during redirection:', error);
    // 
    // 
  }
  };

  const checkIsAppInstalledAndroid = async () => {
    // Define your app's custom URI scheme
    const customScheme = `adventureplanet.com://`;
  
    return new Promise((resolve) => {
      // Create an invisible iframe and try to open your app's custom URI scheme
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = customScheme;
      document.body.appendChild(iframe);
  
      // Set a timeout to determine if the app is installed or not
      const timeout = setTimeout(() => {
        document.body.removeChild(iframe);
       
        resolve(false); // Timeout occurred, app is likely not installed
        if(Installed == false){
          window.location.href = "https://play.google.com/store/apps/details?id=fun.motus.app&hl=en_CA&gl=US";
        }
      }, 2000); // Adjust the timeout duration as needed
  
      // Listen for when the app's custom URI scheme is opened
      window.addEventListener('blur', () => {
        clearTimeout(timeout);
        document.body.removeChild(iframe);
        setInstalled(true);
        
        resolve(true); // App is installed
        window.location.href = customScheme;
      });
    });
  };

  const checkIsAppInstalledIOS = async () => {
    // Define your app's custom URI scheme
    const customSchemeIos = `motus://`;
  
    return new Promise((resolve) => {
      // Create an invisible iframe and try to open your app's custom URI scheme
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = customSchemeIos;
      document.body.appendChild(iframe);
  
      // Set a timeout to determine if the app is installed or not
      const timeout = setTimeout(() => {
        document.body.removeChild(iframe);
       
        resolve(false); // Timeout occurred, app is likely not installed
        if(Installed == false){
          window.location.href = "https://apps.apple.com/ca/app/motus-fun/id6474702612";
        }
      }, 2000); // Adjust the timeout duration as needed
  
      // Listen for when the app's custom URI scheme is opened
      window.addEventListener('blur', () => {
        clearTimeout(timeout);
        document.body.removeChild(iframe);
        setInstalled(true);
        
        resolve(true); // App is installed
        window.location.href = customSchemeIos;
      });
    });
  };

 const closepopup = ()=>{
    setshowPopup(false)
 }

  return (
    <div className={styles.eventEventDetailsNoAcc}>
      <div className={styles.wellLetRichContainer}>
        <div style={{width: '90%',textAlign: 'center',margin: '0 auto'}}>
        <span style={{padding:'10px'}}>We’ll let {activityData?.activityOwner?.firstName} {activityData?.activityOwner?.lastName} know you are going to <b>{activityData.activityName}</b>  on {rRule ? checkDateandTimeview(twomontDates[0]) : new Date(activityData.activityStartDate).toDateString()}   {new Date(activityData.activityStartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(activityData.activityEndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }.</span>
        </div>
      </div>
      
      <div className={styles.eventEventDetailsNoAccChild} />
      <div className={styles.header}>
        <div className={styles.check}>✔</div>
        <b className={styles.youreAllSet}>You’re all set!</b>
      </div>
     
      <div className={styles.button} onClick={backToEventDetails}>
        <div className={styles.check}>Back to event details</div>
      </div>
      {showAGCB ? <><div className={styles.button1} onClick={addToGoogleCalender}>
        <div className={styles.check}>Add to Google calendar</div>
      </div></> :<></>}
      
      <div className={styles.cardCta}>
        <div className={styles.cardCtaChild} />
        <div className={styles.buttonPrimary} onClick={checkAndRedirectToAppCreateAccoount}>
          <b className={styles.check}>Create account</b>
        </div>
        <b className={styles.joinRichOn}>Join {activityData?.activityOwner?.firstName} {activityData?.activityOwner?.lastName} on Motus</b>
        <div className={styles.messagesAvatar}>
          <img
            className={styles.contactPhotoIcon}
            alt=""
            src="/motuscircle.png"
          />
        </div>
        <div style={{textAlign:'center'}} className={styles.signUpTo}>
          Sign up to Motus to schedule rides of your own.
        </div>
      </div>

      {showPopup ? <GooglePopup title={showId}   close={closepopup} /> : ''}
    </div>


  );
};

export default EventEventDetailsNoAcc;
