import {
    SyntheticEvent,
    useState,
    useEffect
  } from 'react';
  import { FunctionComponent } from "react";
  import styles from "./Loginpage.module.css";
  import { Container } from "@material-ui/core";
  import { useNavigate, useLocation } from "react-router-dom";
  import Cookies from 'js-cookie';
  import axios from 'axios';
  import CryptoJS from 'crypto-js';
  import '../global.css';

  const SECRET_KEY = 'qwdfGhty234';

  const botUrl = process.env.REACT_APP_BOT_URL+'/wa';

  const Signin = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState('');
    const [msgerror, setmsgError] = useState('');
    const [countryCode, setcountryCode] = useState('');
    const navigate = useNavigate();
    const [errorstatus, seterrorstatus] = useState(true);
    const canadianPhoneRegex = /^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;
    const [sumbmitloader, setSumbmitLoader] = useState(false);
    
    useEffect(() => {
      // console.log(botUrl)
      const storedNumber = Cookies.get('phoneNumber');
      // 
      if(storedNumber){
        navigate('/details',{ state: { status: false } })
      }
      selctCountrycode('+1')
    }, []); 

  const handlePhoneChange = (e:any) => {
    const value = e.target.value;
    setPhoneNumber(e.target.value);

    // Regex for 10-digit number validation
    const phoneRegex = /^\d{10}$/;

    if (value === '' || phoneRegex.test(value)) {
      setError(''); // Clear error if valid
      seterrorstatus(false)
    } else {
      setError('Please enter a valid 10-digit number');
      seterrorstatus(true)
    }
   
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    if (phoneNumber) {
      setSumbmitLoader(true);
      
      try {
       let obj= {
          "phone":countryCode+phoneNumber,
          "otp": generateOTP()
      }
      
        const result = await axios.post(`${botUrl}/sendotp`, obj);
        
        let data={phone:countryCode+phoneNumber}
        navigate('/verification',{ state: data })
        setmsgError("");
        setSumbmitLoader(false);
      } catch (error) {
        setmsgError("Error")
        console.error('===>>messagesent Error posting data:', error);
        setSumbmitLoader(false);
      }
      // 
      // let data={phone:countryCode+phoneNumber}
      // navigate('/verification',{ state: data })
     
    }
  };

  const generateOTP = () => {
    const otp = Math.floor(100000 + Math.random() * 900000);
    const sendotp=otp;
    const generatedOtp = otp.toString();
    const encryptedOtp = encryptOTP(generatedOtp);
    Cookies.set('auth', encryptedOtp, { expires: 100 });
    
    return generatedOtp
  };


  const selctCountrycode = (value:any)=>{
      
      setcountryCode(value)
  }
 

  const encryptOTP = (otp:any) => {
    return CryptoJS.AES.encrypt(otp, SECRET_KEY).toString();
  };
  
    return (
        <div className={styles.loginContainer}>
        <div className={styles.loginlogo}>
          <img src="./logo.png" alt="Motus Logo" />
        </div>
        <h2>Motus <span className={styles.betatag}>Beta</span></h2>
        <h6>Sign in / Sign up to Motus</h6>
  
        <form className={styles.loginform} onSubmit={handleSubmit}>
          <div className={styles.phoneinput}>
            <select className={styles.countrycode} onChange={(e)=>{selctCountrycode(e.target.value)}} value={countryCode}>
              <option value="+1">+1</option>
              <option value="+91">+91</option>
              {/* Add more country codes as needed */}
            </select>
            <input
              type="text"
              value={phoneNumber}
              onChange={handlePhoneChange}
              placeholder="Phone Number"
              className={styles.phonenumber}
            />
             
          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {msgerror && <p style={{ color: 'red' }}>{msgerror}</p>}
          
          <button type="submit" className={styles.submitbtn} disabled={errorstatus || sumbmitloader}>
            Request login code
          </button>

          {sumbmitloader == true ? <div className={styles.centerloader}>
                        <div className={styles.spinner}></div>
                        </div> : <></>}
        </form>
      </div>
    );
  }
  
  export default Signin