import { FunctionComponent, useCallback, useEffect, useState } from "react";
import styles from "./LiveEvent.module.css";
import { useNavigate, useSearchParams,useParams } from "react-router-dom";
import { RRule, datetime } from "rrule";
import { isMobile, isAndroid } from 'react-device-detect';
import { FaCheckCircle, FaTimesCircle, FaRegTimesCircle } from "react-icons/fa";
import Cookies from "js-cookie";
import Headertag from "./header";
import axios from 'axios';


const apiUrl = "../api";
const LiveEvent: FunctionComponent = () => {


    const [activityData, setActivityData]: any = useState('');
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastName] = useState('');
    const [browserdata, setBrowserData]: any = useSearchParams();
    const [accepted, setAccepted] = useState('');
    const [chooseDateType, setchooseDateType] = useState(false);
    const [typeChoose, settypeChoose] = useState('');
    const [typeCalender, settypeCalender] = useState(false);
    const [eventDates, seteventDates] = useState<Date[]>([]);
    const [passcolorDates, setpasscolorDates] = useState<Object[]>([]);
    const [eventProccess, seteventProccess] = useState('initial value');
    const [disabledDate, setdisabledDate] = useState<Date[]>([]);
    const [Rrule, setRrule] = useState(false);
    const [rruleText, setrruleText] = useState('');
    const [numberBoolean, setnumberBoolean] = useState(false);
    const [bottomPopup, setbottomPopup] = useState(false);
    const [firstRender, setFirstRender] = useState(false);
    const [isAppInstalled, setIsAppInstalled] = useState(false);
    const [Installed, setInstalled] = useState(false);
    const [twomontDates, settwomontDates] = useState<Date[]>([]);
    const [showEventcomplte, setshowEventcomplte] = useState(false);
    const [rRule, setrRule] = useState(false);
    const [showLoader, setshowLoader] = useState(false);
    const [showDate, setShowDate] = useState<any>('');
    const [eventName, seteventName] = useState('');
    const [eventLocation, seteventLocation] = useState('');
    const [editEvent, seteditEvent] = useState(false);

    const navigate = useNavigate();
    const { id } = useParams() as { id: string };
    
    useEffect(() => {
        const CookeDataDate = Cookies.get('eventDate');
        setShowDate(CookeDataDate)
        
        if (!activityData || activityData == "" || activityData == undefined) {
            setshowLoader(true)
            fetchTodos();
        }



        //  
        const checkAppInstallation = async () => {
            if ('getInstalledRelatedApps' in navigator && typeof navigator.getInstalledRelatedApps === 'function') {
                try {
                    const relatedApps = await navigator.getInstalledRelatedApps?.();
                    if (relatedApps) {
                        const isInstalled = relatedApps.some((app: any) => {
                            // Replace 'com.example.app' with your package name or bundle identifier
                            return app.id === 'adventureplanet.com://';
                        });
                        setIsAppInstalled(isInstalled);
                        // alert(isInstalled);
                    }
                } catch (error) {
                    console.error('Error checking app installation:', error);
                }
            } else {
                console.warn('getInstalledRelatedApps is not supported in this browser.');
            }
        };

        checkAppInstallation();

    }, [firstRender]);

    const setBootompopupActive = () => {
        setbottomPopup(true)
    }

    async function fetchTodos() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        
        if (params.redirect == 'invite') {
            navigate('/webapp')
        }
        else if (params.userId) {
            navigate('/event-event-details-no-account1?userId=' + params.userId)
        }
        try {
            let AcceptedData: any = localStorage.getItem("Accepted");
            setAccepted(AcceptedData)
            const todoData = await axios.get(`${apiUrl}/draftEvent/activity/${id}`);
            
            

            if (todoData.data != null) {
                // let findNumber = JSON.parse(todoData.data.getActivity.activitySharedUsers).find((s: any) => { if (s.phone == "+" + browserdata.get("phone").trim()) { return s } });
                // 
                // if (findNumber == undefined) {
                //     setnumberBoolean(false);
                // } else {
                //     setnumberBoolean(true);
                // }
                setnumberBoolean(true);
            } else {
                setnumberBoolean(false);
            }

            
            let activityData: any = todoData.data;
            const ss = RRule.fromString(activityData.activityRepeatRule);
            
            setrruleText(ss.toText());
            // 
            settwomontDates(ss.between(datetime(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()), datetime(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate() + 31)));
            // seteventDates(ss.between(datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()), datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()+10)));
            let sharedUsers: any = JSON.parse(activityData.activitySharedUsers);
            let activityOwner: any = JSON.parse(activityData.activityOwner);
            activityData.activitySharedUsers = sharedUsers;
            
            setFirstname(activityOwner.firstName)
            setLastName(activityOwner.lastName)
            setActivityData(activityData);
            seteventName(activityData?.activityName);
            seteventLocation(activityData?.activityLocation);
            setRrule(activityData.activityRepeatRule.includes("FREQ"))
            let resulta = activityData.activityRepeatRule.includes("FREQ");
            
            if (resulta == true) {
                setrRule(true);
            } else {
                setrRule(false);
                if (checkDateandTime(activityData.activityStartTime)) {
                    setshowEventcomplte(true)
                } else {
                    
                    setshowEventcomplte(false)

                }
            }

            localStorage.setItem("activityData", JSON.stringify(activityData))
            localStorage.setItem("activityId", id)
            // allactivity(activityData.activitySharedUsers, ss.between(datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()), datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()+10)));
            // const todos = todoData.data.lis.items;
            // setTodos(todos);
            if (!firstRender) {
                setBootompopupActive();
            }
            setshowLoader(false);
        } catch (err) {
            setshowLoader(false);
            
        }
    }

    const checkDateandTime = (eventtime: any) => {
        
        let eventDateandTime = new Date(eventtime);

        let currentDate = new Date();
        // 
        
        
        if (currentDate > eventDateandTime) {
            return true
        } else {
            return false
        }
    }


    const checktwoDates = (compare: any, check: any) => {
        
        const date1 = new Date(compare);
        const date2 = new Date(check);
        
        if (date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()) {
            return true;
        } else {
            return false;
        }
    }


    const close = () => {
        setchooseDateType(false);
    }

    const updateEvent = async (updateuser: any) => {
        let updateActivityData = {
            id: browserdata.get("id"),
            activitySharedUsers: JSON.stringify(updateuser)
        }
        const todoData = await axios.put(`${apiUrl}/draftEvent/activity/update`,{activitySharedUsers: JSON.stringify(updateuser)});
        
        close()
    }

    const checkDateandTimeSender = async (eventtime: any) => {
        
        let activityStime = new Date(activityData.activityStartTime);
        
        let eventDateandTime = new Date(eventtime);
        eventDateandTime.setHours(activityStime.getHours());
        eventDateandTime.setMinutes(activityStime.getMinutes());

        let currentDate = new Date();
        // 
        
        
        if (currentDate > eventDateandTime) {
            let vv1 = twomontDates.slice(1, 2);
            
            return vv1
        } else {

            let vv2 = twomontDates.slice(0, 1);
            
            return vv2
        }
    }



    const chagneFistletter = (value: string) => {
        if (value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        } else {
            return '';
        }

    }


    const checkingTwodates = (datesArray: any) => {
        const CookeData = Cookies.get('eventDate');
        let index = datesArray.indexOf(CookeData);
        
        if (index !== -1) {
            return true
        } else {
            return false
        }
    }


    return (
        <div style={{ padding: '0px' }}>
            <div>
                {/* {bottomPopup ? <BottomPopup closePopup={togglebottomPopup} openApp={checkAndRedirectToApp} /> : <></>} */}
            </div>
            <>

                {showEventcomplte ? <><div style={{ margin: 'auto', width: "100%", textAlign: "center", paddingTop: "200px" }}>Event Completed</div></> :
                    <>
                        {showLoader ? <div className={styles.centerloader}><div className={styles.loader}></div></div> : <>
                            {numberBoolean ?
                                <>
                                    <div><Headertag showHeader={true} dropDownShow={false} /></div>
                                    <div className={`${styles.eventEventDetailsNoAcc} ${styles.contentscroll} `}>

                                        <div className={styles.eventEventDetailsNoAccChild}>
                                            
                                            <div >
                                                {editEvent == true ?  <div style={{ padding: '14px 16px 14px 16px' }}>
                                                    <input
                                                        type="text"
                                                        value={eventName} // Step 2: Bind the input value to state
                                                        onChange={(e) => seteventName(e.target.value)} // Step 3: Make the input editable by updating the state
                                                    />
                                                </div> : <b className={styles.frommeShred}>{activityData?.activityName}</b>}
                                            </div>
                                            <div className={styles.p10}>
                                                <div className={styles.datecard}>
                                                    <img
                                                        className={styles.iconLocationPin}
                                                        alt=""
                                                        src="/-icon-time.svg"
                                                    />
                                                </div>
                                                <div>

                                                    {/* <div >{rRule ? <>{checkDateandTimeview(twomontDates[0])}</> : <>{new Date(activityData.activityStartDate).toDateString()}</>}</div> */}
                                                    <div >{showDate}</div>
                                                    <div style={{ paddingLeft: "35px", color: "#958f8f" }}>{new Date(activityData.activityStartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(activityData.activityEndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                                                </div>
                                            </div>
                                            <div style={{ paddingLeft: '15px' }}>
                                                <div className={styles.datecard}>
                                                    <div className={styles.iconLocationPin}>&nbsp;</div>
                                                </div>
                                                <div>
                                                    <div >{rRule ? <>{chagneFistletter(rruleText)}</> : <>&nbsp;</>}</div>
                                                </div>
                                            </div>
                                            {activityData?.activityLocation ? <div className={styles.p10}>
                                                <div className={styles.datecard}>
                                                    <img
                                                        className={styles.iconLocationPin}
                                                        alt=""
                                                        src="/-icon-location-pin.svg"
                                                    />
                                                </div>
                                                <div>
                                                    {editEvent == true ? <div style={{ padding: '3px 16px 14px 16px' }}>
                                                    <input
                                                        type="text"
                                                        value={eventLocation} // Step 2: Bind the input value to state
                                                        onChange={(e) => seteventLocation(e.target.value)} // Step 3: Make the input editable by updating the state
                                                    />
                                                    </div> : <div>{activityData?.activityLocation}</div>}
                                                    
                                                    
                                                </div>
                                            </div> : <></>}

                                            {firstName ? <div className={styles.p10}>
                                                <div className={styles.datecard}>
                                                    <img
                                                        className={styles.iconLocationPin}
                                                        alt=""
                                                        src="/-icon-person.svg"
                                                    />
                                                </div>
                                                <div>
                                                    <div >Hosted by {firstName} {lastName}</div>
                                                </div>
                                            </div> : <></>}

                                        </div>
                                        <div style={{ padding: '20px' }}>
                                            <div >
                                                {activityData.activitySharedUsers ? (
                                                    <>
                                                        <b className={styles.peopleAreInvited}>{activityData?.activitySharedUsers.length} people are invited</b>
                                                    </>
                                                ) : (
                                                    <>
                                                        <b className={styles.peopleAreInvited}>0 people are invited</b>
                                                    </>
                                                )
                                                }
                                                <div>
                                                    <div className={styles.chipParent}>
                                                        
                                                        {(activityData.activitySharedUsers && activityData.activitySharedUsers.length > 0) ? (
                                                            <>
                                                                {activityData?.activitySharedUsers.map((r: any, _index: any) => {

                                                                    if (r.allDays == undefined || r.allDays.length == 0) {
                                                                        
                                                                        return (<div className={styles.chip}>
                                                                            <div className={styles.ellipseParent}>
                                                                                <div className={styles.orange} />

                                                                            </div>
                                                                            <div className={styles.brianTaylor}>{r.firstName} {r.lastName}</div>
                                                                        </div>)

                                                                    } else if (r.allDays == true && r.notGoingDate.length == 0) {
                                                                        
                                                                        return (<div className={styles.chip}>
                                                                            <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                                                                                <FaCheckCircle size={20} color="#00be9a" />
                                                                            </div>
                                                                            <div className={styles.brianTaylor}>{r.firstName} {r.lastName}</div>
                                                                        </div>)
                                                                    } else if (r.allDays == true && r.notGoingDate.length > 0) {
                                                                        
                                                                        return (<div className={styles.chip}>
                                                                            {checkingTwodates(r.notGoingDate) == true ? <>
                                                                                <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                                                                                    <FaCheckCircle size={20} color="#f53131" />
                                                                                </div>
                                                                            </> : <>
                                                                                <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                                                                                    <FaCheckCircle size={20} color="#00be9a" />
                                                                                </div>
                                                                            </>}

                                                                            <div className={styles.brianTaylor}>{r.firstName} {r.lastName}</div>
                                                                        </div>)
                                                                    } else if (r.allDays == false && r.goingDate.length > 0) {
                                                                        
                                                                        return (<div className={styles.chip}>
                                                                            {checkingTwodates(r.goingDate) == true ? <>
                                                                                <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                                                                                    <FaCheckCircle size={20} color="#00be9a" />
                                                                                </div>
                                                                            </> : <>
                                                                                <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                                                                                    <FaCheckCircle size={20} color="#f53131" />
                                                                                </div>
                                                                            </>}

                                                                            <div className={styles.brianTaylor}>{r.firstName} {r.lastName}</div>
                                                                        </div>)
                                                                    } else if (r.allDays == false && r.goingDate.length == 0) {
                                                                        
                                                                        return (<div className={styles.chip}>
                                                                            <div style={{ paddingRight: '5px', paddingTop: '5px' }}>


                                                                                <FaTimesCircle size={20} color="#f53131" />
                                                                                
                                                                            </div>
                                                                            <div className={styles.brianTaylor}>{r.firstName} {r.lastName}</div>
                                                                        </div>)
                                                                    }
                                                                }



                                                                )}
                                                            </>
                                                        ) : null

                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            {activityData?.activityDifficulty ? <div>
                                                <b className={styles.difficulty}>Difficulty</b>
                                                <div className={styles.difficult}>{activityData?.activityDifficulty}</div>
                                            </div> : <></>}

                                            {activityData?.activityDescription ? <div>
                                                <div>
                                                    <b className={styles.details}>Details</b>
                                                    <div className={styles.plr10}>
                                                        {activityData?.activityDescription}

                                                    </div>
                                                </div>
                                               
                                            </div> : <></>}

                                        </div>


                                    </div>

                                </> : <><div style={{ margin: 'auto', width: "100%", textAlign: "center", paddingTop: "200px" }}>No Data Available</div></>}
                        </>}

                    </>
                }
            </>


        </div>

    );
};

export default LiveEvent;
