import {
    useEffect,
    useState
} from 'react';
import styles from "./Userdetail.module.css";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Headertag from './header';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import { LiaRedoSolid } from 'react-icons/lia';
import UpdateProfile from './updateProfile';
import WeekHours from './GeneralAvailablity';
import GoogleCalender from './accessGoolecalender';
import GoogleCalenderModule from './googleCalendar';
import '../global.css';


const apiUrl = "../api";



const UserDetail = () => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [errorstatus, seterrorstatus] = useState(true);
    const [showLoader, setshowLoader] = useState(false);
    const location = useLocation();
    const [userdetails, setusrDetails]: any = useState('');
    const [sumbmitloader, setSumbmitLoader] = useState(false);
    const [browserdata, setBrowserData]: any = useSearchParams();
    const [activeIndex, setActiveIndex] = useState<any>(null);
    const { phone, status } = location.state || {};
    const [showPopup, setShowPopup] = useState(false);
    const [Popuptext, setPopuptext] = useState('');
    const [activeMenu, setActiveMenu] = useState<any>(null);

    const isMobile = useMediaQuery({ maxWidth: 768 });
    const isDesktop = useMediaQuery({ minWidth: 769 });

    const items = [{ name: 'Name', value: userdetails?.firstName && userdetails?.lastName ? userdetails?.firstName + '' + userdetails?.lastName : '', navigate: '/updateprofile', method: '' }, { name: 'Phone Number', value: phoneNumber, navigate: '/updateprofile', method: '' }, { name: 'Working Hours', value: '', navigate: '/generalavailability', method: '' }, { name: 'Calendar Access', value: '', navigate: '/googlecalender', method: '' }, { name: 'Delete Account', value: '', navigate: '', method: '' }];

    useEffect(() => {
        if (browserdata.get("googlestatus") != null) {
            let ind = 1;
            setActiveIndex(ind);
            setActiveMenu("calendar");
        }else if(browserdata.get("liststatus") != null){
            let ind = 1;
            setActiveIndex(ind);
            setActiveMenu("calendar");
        } else{
            let ind = 2;
            setActiveIndex(ind);
            setActiveMenu("userprofile");
        }



        setshowLoader(true);
        getUser(phone);

    }, [])

    const handleClick = (index: any) => {

        setActiveIndex(activeIndex === index ? null : index);


    };

    const getUser = async (phoneNumber: any) => {
        const CookeData = Cookies.get('phoneNumber');
        let cookieData = CookeData ? JSON.parse(CookeData) : {};
        setPhoneNumber(cookieData.phone);
        let user = ''
        try {
            let modifiedNumber = cookieData.phone.replace("+", "");
            const responseData = await axios.get(`${apiUrl}/draftEvent/user/` + modifiedNumber);

            user = responseData['data'].Items[0];
            setusrDetails(responseData['data'].Items[0])
            redirect(responseData['data'].Items[0], cookieData.phone)
        } catch (err: any) {
            setusrDetails(undefined)
            redirect(undefined, cookieData.phone)
        }


    }

    const redirect = (findUser: any, phone: any) => {

        let user = null;

        // Check if findUser exists and has required fields
        if (findUser && findUser.firstName && findUser.lastName) {
            setFirstname(findUser.firstName)
            setLastname(findUser.lastName)
            // Retrieve existing cookie data if available
            const CookeData = Cookies.get('phoneNumber');
            let cookieData = CookeData ? JSON.parse(CookeData) : {};
            let generalActivity = undefined;
            let calendertoken = undefined;


            if (findUser.generalAvailabilityTime) {
                generalActivity = findUser.generalAvailabilityTime
            }
            if (findUser.calendarToken) {
                calendertoken = findUser.calendarToken
            }

            if (cookieData.generalAvailabilityTime) {
                generalActivity = cookieData.generalAvailabilityTime;
                findUser.generalAvailabilityTime = cookieData.generalAvailabilityTime;

            }
            if (cookieData.calendarToken) {
                calendertoken = cookieData.calendarToken;
                findUser.calendarToken = cookieData.calendarToken;
            }

            // Only set generalAvailabilityTime and calenderToken if they don't exist in the cookie
            const dataToSet = {
                firstName: findUser.firstName,
                lastName: findUser.lastName,
                phone: phone,
                generalAvailabilityTime: generalActivity,
                calendarToken: calendertoken,
                id: findUser.id
            };

            Cookies.set('phoneNumber', JSON.stringify(dataToSet), { expires: 100 });
            user = findUser;
        } else {
            // If no findUser, check for cookie data
            const CookeData = Cookies.get('phoneNumber');
            if (CookeData) {
                user = JSON.parse(CookeData);
                setFirstname(user?.firstName)
                setLastname(user?.lastName)
            }
        }
        setshowLoader(false)

        if (user && user.firstName && user.lastName && status == false) {

            // Check generalAvailabilityTime and calenderToken for both findUser and cookieData
            if (user.generalAvailabilityTime) {
                if (user.calendarToken) {

                    const CookeExpireData = Cookies.get('phoneNumber');
                    let cookieData = CookeExpireData ? JSON.parse(CookeExpireData) : {};
                    let calnderTok = typeof user.calendarToken == 'string' ? JSON.parse(user.calendarToken) : user.calendarToken

                    if (TokenexpiryDate(calnderTok.expires_in, calnderTok.token_generate_Date) == true) {
                        navigate('/');
                    } else {
                        navigate('/googlecalender', { state: { status: false } });
                    }

                } else {

                    navigate('/googlecalender', { state: { status: false } });
                }
            } else {
                navigate('/generalavailability', { state: { status: false } });
            }
        }
    }

    const TokenexpiryDate = (e: any, d: any) => {
        const generateTime = new Date(d);

        // Add expiresIn (in seconds) to generateTime
        const expirationTime = new Date(generateTime.getTime() + e * 1000);

        // Get the current date and time
        const currentTime = new Date();

        // Check if current time is greater than expiration time
        if (currentTime > expirationTime) {
            return false; // Expired
        } else {
            return true; // Not expired
        }
    }

    const handlePhoneChange = (e: any) => {
        const value = e.target.value;
        setPhoneNumber(e.target.value);

        // Regex for 10-digit number validation
        const phoneRegex = /^\d{10}$/;

        if (value === '' || phoneRegex.test(value)) {
            setError(''); // Clear error if valid
            seterrorstatus(false)
        } else {
            setError('Please enter a valid 10-digit number');
            seterrorstatus(true)
        }

    };

    const handlefistname = ((val: any) => {

        setFirstname(val.target.value);
    })
    const handlelastname = ((val: any) => {
        setLastname(val.target.value);
    })

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (phoneNumber && firstname && lastname) {
            setSumbmitLoader(true);

            const CookeData = Cookies.get('phoneNumber');
            let cookieData = CookeData ? JSON.parse(CookeData) : {};

            const dataToSet = {
                firstName: firstname,
                lastName: lastname,
                phone: cookieData.phone,
                generalAvailabilityTime: cookieData.generalAvailabilityTime,
                calendarToken: cookieData.calendarToken,
                id: cookieData.id
            };
            Cookies.set('phoneNumber', JSON.stringify(dataToSet), { expires: 100 });
            if (userdetails) {

                try {
                    const phoneNosign = userdetails.phone.startsWith("+") ? userdetails.phone.slice(1) : userdetails.phone;

                    const response = await axios.put(`${apiUrl}/draftEvent/updateUser`, { phone: phoneNosign, firstName: firstname, lastName: lastname });

                    setSumbmitLoader(false);
                } catch (err) {

                    setSumbmitLoader(false);
                }

            } else {
                setSumbmitLoader(false);
            }

            checkwhileSkip(userdetails, phoneNumber)
        }
    };

    const checkallfields = () => {
        if (phoneNumber && firstname && lastname) {
            return false
        } else {
            return true
        }
    }

    const skipPage = () => {

        checkwhileSkip(userdetails, phoneNumber)
    }

    const checkwhileSkip = (findUser: any, phone: any) => {
        let user = null;

        // Check if findUser exists and has required fields
        if (findUser) {
            // Retrieve existing cookie data if available
            const CookeData = Cookies.get('phoneNumber');
            let cookieData = CookeData ? JSON.parse(CookeData) : {};
            let generalActivity = undefined;
            let calendertoken = undefined;
            if (cookieData.generalAvailabilityTime) {
                generalActivity = cookieData.generalAvailabilityTime
            }
            if (cookieData.calendarToken) {
                calendertoken = cookieData.calendarToken
            }

            if (findUser.generalAvailabilityTime) {
                generalActivity = findUser.generalAvailabilityTime
            }
            if (findUser.calendarToken) {
                calendertoken = findUser.calendarToken
            }

            // Only set generalAvailabilityTime and calenderToken if they don't exist in the cookie
            const dataToSet = {
                firstName: findUser.firstName,
                lastName: findUser.lastName,
                phone: phone,
                generalAvailabilityTime: generalActivity,
                calendarToken: calendertoken,
                id: findUser.id
            };

            Cookies.set('phoneNumber', JSON.stringify(dataToSet), { expires: 100 });
            user = findUser;
        } else {
            // If no findUser, check for cookie data
            const CookeData = Cookies.get('phoneNumber');
            if (CookeData) {
                user = JSON.parse(CookeData);
            }
        }


        if (user) {
            // Check generalAvailabilityTime and calenderToken for both findUser and cookieData
            if (user.generalAvailabilityTime) {
                if (user.calendarToken) {
                    const CookeExpireData = Cookies.get('phoneNumber');
                    let cookieData = CookeExpireData ? JSON.parse(CookeExpireData) : {};
                    let calnderTok = typeof user.calendarToken == 'string' ? JSON.parse(user.calendarToken) : user.calendarToken

                    if (TokenexpiryDate(calnderTok.expires_in, calnderTok.token_generate_Date) == true) {
                        navigate('/');
                    } else {
                        navigate('/googlecalender', { state: { status: false } });
                    }
                } else {
                    navigate('/googlecalender', { state: { status: false } });
                }
            } else {
                navigate('/generalavailability', { state: { status: false } });
            }
        }
    }

    const clearCokkies = () => {
        Cookies.remove('phoneNumber');
        Cookies.remove('specifichours');
        Cookies.remove('weeklyhours');
        Cookies.remove('auth');
        Cookies.remove('eventDate');
        Cookies.remove('calenderToken');
        Cookies.remove('code');
        Cookies.remove('skipcalendar');

        navigate('/signin')
    }

    const handleSignout = () => {
        Cookies.remove('phoneNumber');
        Cookies.remove('specifichours');
        Cookies.remove('weeklyhours');
        Cookies.remove('auth');
        Cookies.remove('eventDate');
        Cookies.remove('calenderToken');
        Cookies.remove('code');
        Cookies.remove('skipcalendar');

        navigate('/signin')
    }
    const accordionItems = [
        { title: "Working Hours", content: "availablity" },
        { title: "Calendar Access", content: "calendar" },
        { title: "Personal Info", content: "userprofile" },
       
    ];

    const accordionItemsDesktop = [
        { title: "Working Hours", content: "availablity" },
        { title: "Calendar Access", content: "calendar" },
        { title: "Personal Info", content: "userprofile" },
        { title: "Logout", content: "logout" },
        { title: "Delete Account", content: "delete" },
    ];

    const handleClickdesktop = (index: any,content:any) => {
        console.log(index)
        handleMenuClick(content);
        setActiveIndex(index);

        if (index == 3) {
            setShowPopup(true); 
            setPopuptext('logout');
        }
        if (index == 4) {
            setShowPopup(true);
            setPopuptext('delete');
        }

    };

    const handleMenuClick = (menuName:any) => {
        setActiveMenu(menuName); // Set the clicked menu as active
      };

    const handleDelete = async () => {
        // Perform the delete action
        // alert("Item deleted!");
        if (Popuptext == 'logout') {
            console.log("logout",Popuptext);
            handleSignout();
        } else if (Popuptext == 'delete') {
            console.log("delete",Popuptext);
            if (userdetails) {

                try {
                    const phoneNosign = userdetails.phone.startsWith("+") ? userdetails.phone.slice(1) : userdetails.phone;

                    const response = await axios.put(`${apiUrl}/draftEvent/deleteUser`, { phone: phoneNosign });
                    setShowPopup(false);
                    handleSignout();
                } catch (err) {
                    setShowPopup(false);
                }

            } else {
                setShowPopup(false);
                handleSignout();
            }
        }

    };
    const handleCancel = () => {
        setShowPopup(false);
    };

    const formatPhoneNumber = (number: any) => {
        // Remove any non-digit characters
        number = number.replace(/\D/g, '');

        // Match the country code and phone number
        const match = number.match(/^(\d{1,3})(\d{10})$/);

        if (match) {
            const countryCode = match[1];
            const localNumber = match[2];
            return `+${countryCode} ${localNumber}`;
        }

        return null; // Return null if the input is invalid
    }

    return (
        <>
            <div><Headertag showHeader={true} dropDownShow={false} /></div>
            {showLoader ? <div className={styles.centerloader} > <div className={styles.loader}></div></div > :
                <>
                    {isMobile ? <><div style={{ margin: "0 auto" }}>
                        {accordionItems.map((item, index) => (
                            <div key={index} >
                                <div
                                    style={{
                                        alignItems: 'center',
                                        backgroundColor: '#fff',
                                        borderBottom: '1px solid #ddd',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '12px',
                                        transition: 'background-color .2s ease',
                                    }}
                                    onClick={() => handleClick(index)}
                                >
                                    {item.title}
                                    {item.content == 'userprofile' ? <span>{` >`} </span> : <></>}
                                    {item.content == 'availablity' ? <span>{` >`}</span> : <></>}
                                    {item.content == 'calendar' ? <span>{` >`}</span> : <></>}
                                </div>
                                {activeIndex === index && (
                                    <div style={{ padding: "10px", backgroundColor: "#fff" }}>
                                        {item.content == 'userprofile' ? <><UpdateProfile showstript={false} /></> : <></>}
                                        {item.content == 'availablity' ? <><WeekHours showstript={false} /></> : <></>}
                                        {item.content == 'calendar' ? <><GoogleCalenderModule showstript={false} googlestatus={browserdata.get("googlestatus")} /></> : <></>}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div >
                        <div style={{ padding: '10px' }}>
                            <button className={styles.logoutbtn} onClick={() => { setShowPopup(true); setPopuptext('logout') }} disabled={sumbmitloader}>Logout</button>
                        </div>
                        <div style={{ padding: '10px' }}>
                            <button className={styles.deletebtn} disabled={sumbmitloader} onClick={() => { setShowPopup(true); setPopuptext('delete') }}>Delete Account</button>
                        </div>
                    </> : <></>}
                    {isDesktop ? <>
                        <div className={styles.container}>
                            <div className={styles.weekhourscontainer}>
                                {accordionItemsDesktop.map((item, index) => (
                                    <div key={index} >
                                        <div
                                            className={`${activeMenu === item.content ? styles.leftmenuactive : styles.leftmenu}`}
                                            onClick={() => handleClickdesktop(index,item.content)}
                                        >
                                            {item.title}
                                            {item.content == 'userprofile' ? <span></span> : <></>}
                                            {item.content == 'availablity' ? <span></span> : <><></></>}
                                            {item.content == 'calendar' ? <span></span> : <><></></>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.weekhourscontainersecond}>
                                {accordionItems.map((item, index) => (
                                    <div key={index} >
                                        {activeIndex === index && (
                                            <div style={{ padding: "10px", backgroundColor: "#fff" }}>
                                                {item.content == 'userprofile' ? <><UpdateProfile showstript={false} /></> : <></>}
                                                {item.content == 'availablity' ? <><WeekHours showstript={false} /></> : <></>}
                                                {item.content == 'calendar' ? <><GoogleCalenderModule showstript={false} googlestatus={browserdata.get("googlestatus")} liststatus={browserdata.get("liststatus")} /></> : <></>}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </> : <></>}
                </>
            }

            {showPopup && (
                <div
                    style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "white",
                        padding: "20px",
                        border: "1px solid #ccc",
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                        zIndex: 1000,
                    }}
                >
                    <p style={{ fontSize: '20px' }}>Are you sure you want to {Popuptext} your account?</p>
                    <button onClick={handleDelete} style={{ marginRight: "10px", marginTop: '10px', background: 'red', padding: '0px 16px', color: 'var(--font-color)',borderRadius:'50px' }}>
                        Yes
                    </button>
                    <button style={{ float: 'right', marginRight: "10px", marginTop: '10px', background: '#00be9a', padding: '0px 16px', color: 'var(--font-color)',borderRadius:'50px' }} onClick={handleCancel}>No</button>
                </div>
            )}
            {showPopup && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 999,
                    }}
                />
            )}
        </>
    );
}

export default UserDetail