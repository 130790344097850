import React, { useState, useEffect } from 'react';
import styles from './Verificationpage.module.css'; // Ensure you have your CSS imported
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
const SECRET_KEY = 'qwdfGhty234';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import '../global.css';

const apiUrl = "../api";

const VerificationCode = () => {
    const [code, setCode] = useState(Array(6).fill([]));
    // const [code, setCode] = useState([3,5,3,5,8,2]);
    const [isComplete, setIsComplete] = useState(false);
    const [verificationtext, setincorrectText] = useState('');
    const [sumbmitloader, setSumbmitLoader] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { phone } = location.state || {};


    useEffect(() => {
        // Push a new state to the history
        window.history.pushState(null, "", window.location.href);

        const preventBackNavigation = (e: any) => {
            // Prevent the default behavior of the back button
            window.history.pushState(null, "", window.location.href);
            //   alert("Back navigation is disabled!"); // Optional: Add custom feedback for users
        };

        window.addEventListener("popstate", preventBackNavigation);

        return () => {
            window.removeEventListener("popstate", preventBackNavigation);
        };
    }, [navigate]);


    useEffect(() => {
        // Check if all fields are filled

        const complete = code.length === 6 && code.every((s: any) => s !== "");

        setIsComplete(complete);
    }, [code]);


    const retrieveOTP = () => {
        const encryptedOtp = Cookies.get('auth');
        if (encryptedOtp) {
            const decryptedOtp = decryptOTP(encryptedOtp);
            return decryptedOtp
        } else {
            return null
        }
    };

    const decryptOTP = (encryptedOtp: any) => {
        const bytes = CryptoJS.AES.decrypt(encryptedOtp, SECRET_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    const handleChange = (e: any, index: any) => {


        const value = e.target.value;
        const newCode = [...code];

        // Check if the user has pasted a full OTP
        if (value.length > 1) {
            // Split the pasted OTP across the input fields
            const pastedCode = value.split('').slice(0, 6); // Adjust based on OTP length
            for (let i = 0; i < pastedCode.length; i++) {
                if (index + i < 6) {
                    newCode[index + i] = pastedCode[i];
                }
            }
            setCode(newCode);

            // Focus on the last filled input
            const lastInput = document.getElementById(`input-${index + pastedCode.length - 1}`);
            if (lastInput) {
                lastInput.focus();
            }
        } else {
            // Handle normal single character input
            newCode[index] = value.slice(0, 1); // Ensure only one character is taken
            setCode(newCode);

            // Move to the next input if current input is filled
            if (value && index < 5) {
                const nextInput = document.getElementById(`input-${index + 1}`);
                if (nextInput) {
                    nextInput.focus();
                }
            }
        }
    };

    const handleKeyDown = (e: any, index: any) => {


        if (e.key === 'Backspace') {
            const newCode = [...code];
            if (newCode[index]) {
                newCode[index] = ''; // Clear the current input
                setCode(newCode);
            } else if (index > 0) {
                const previousInput = document.getElementById(`input-${index - 1}`);
                if (previousInput) {
                    previousInput.focus(); // Move to the previous input
                }
            }
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setSumbmitLoader(true);
        let modifiedNumber = phone.replace("+", "");
        const liveDataId = Cookies.get('liveId');
        let liveid = liveDataId ? liveDataId : undefined;

        try {

            const responseData = await axios.get(`${apiUrl}/draftEvent/user/` + modifiedNumber);

            const verificationCode = code.join('');

            if (verificationCode == retrieveOTP()) {
                Cookies.set('phoneNumber', JSON.stringify({ phone: phone }), { expires: 100 });
                let data = { phone: phone, status: false };
                if (liveid) {
                    navigate(liveid);
                    Cookies.remove('liveId');
                } else {
                    navigate('/updateprofile', { state: data })
                }

                setincorrectText('');
            } else {
                setincorrectText('Verification code incorrect');
            }
            setSumbmitLoader(false);
        } catch (err: any) {

            setSumbmitLoader(false);
            if (err.response) {
                if (err.response.data.error == 'No Users Found') {

                    let userObj = {
                        "participants":
                            [
                                {
                                    "first_name": "",
                                    "last_name": "",
                                    "phone": modifiedNumber,
                                    "registration_status": "auto",
                                    "platform": "whatsapp",
                                    "additional_data": ""
                                }
                            ]
                    }

                    try {
                        const createData = await axios.post(`${apiUrl}/draftEvent/adduser`, userObj);

                        const verificationCode = code.join('');

                        if (verificationCode == retrieveOTP()) {
                            Cookies.set('phoneNumber', JSON.stringify({ phone: phone }), { expires: 100 });
                            let data = { phone: phone, status: false }
                            if (liveid) {
                                navigate('/le/' + liveid);
                                Cookies.remove('liveId');
                            } else {
                                navigate('/updateprofile', { state: data })
                            }
                            // navigate('/details', { state: data })
                            setincorrectText('');
                        } else {
                            setincorrectText('Verification code incorrect');
                        }
                    } catch (err: any) {

                        setincorrectText('User does not exist cannot create user');
                    }
                } else {
                    setincorrectText('Verification Error');
                }
            } else {
                setincorrectText('Verification Error');
            }

        }


    };

    const formatPhoneNumber = (number: any) => {
        // Remove any non-digit characters
        number = number.replace(/\D/g, '');

        // Match the country code and phone number
        const match = number.match(/^(\d{1,3})(\d{10})$/);

        if (match) {
            const countryCode = match[1];
            const localNumber = match[2];
            return `+${countryCode} ${localNumber}`;
        }

        return null; // Return null if the input is invalid
    }

    return (
        <div className={styles.verificationcontainer}>
            <h3> Verification Code</h3>
            <h6 style={{ padding: '10px' }}> We have sent the verification code to </h6>

            <h6 style={{ padding: '10px' }}> <b>{formatPhoneNumber(phone)}</b></h6>
            <h6 style={{ padding: '10px' }}><a onClick={() => navigate('/signin')}> Click here to change phone number </a></h6>
            <form onSubmit={handleSubmit}>
                <div className={styles.inputgroup}>
                    {code.map((value, index) => (
                        <input
                            key={index}
                            id={`input-${index}`}
                            type="number"
                            value={value}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            className={styles.roundedinput}
                            maxLength={1} // Ensure this is a number
                            required
                        />
                    ))}
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <button type="submit" className={isComplete ? styles.submitbtn : styles.disabled} disabled={!isComplete}>
                        Finish verification
                    </button>
                    <div style={{ color: 'red' }}>{verificationtext}</div>
                    {sumbmitloader == true ? <div className={styles.centerloader}>
                        <div className={styles.spinner}></div>
                    </div> : <></>}
                </div>
            </form>
        </div>
    );
};

export default VerificationCode;
