import React, { useEffect, useState } from 'react';
import '../global.css';
import { Calendar,CalendarViewChangeEvent } from 'primereact/calendar';
import { PrimeReactProvider } from 'primereact/api'; 
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { RRule, datetime } from "rrule";

const CalenderPopup = (props:any) => {

   

    const [date, setDate] = useState(null);
    const [enableDatesarr,setenableDatesarr]= useState([]);
    const [viewDate, setViewDate] = useState(new Date());
    const [enabledDates, setEnabledDates] = useState([new Date()]);
    const [enableColors, setenableColors] = useState([]);
    const [selectedDates, setSelectedDates] = useState<Date[]>([]);;
    // let enabledDates = props.eventsArray;
    const rrule=props.repeatrule;
    const sharedUser=props.shareduser;
    const phonenumber=props.phone;
    // 
    // const enableColors =props.passcolorDates;
    useEffect(() => {
      
      const rr = RRule.fromString(rrule);
         setEnabledDates(rr.between(datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()), datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()+31)))
         allactivity(sharedUser,rr.between(datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()), datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()+31)));
        },[]);
    // const test =()=>{
    //     const filteredArray = enabledDates.filter((date:any) => !props.disabledDate.some((selectedDates:any) => (checktwoDates(date,selectedDates) == true)));
    //         //   
    //           setenableDatesarr(filteredArray)
    // }

    // const checktwoDates =(compare:any,check:any)=>{
    //     
    //     const date1 = new Date(compare);
    //             const date2 = new Date(check);
    //             // 
    //             if (date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()) {
    //               return true;
    //             }else{
    //               return false;
    //             }
    //           }
    // 
    // 
    localStorage.setItem('calendervalue',JSON.stringify(date));

   const renderDateTemplate= (date:any) => {
    // 
        // const { enabledDates } = this.props;
        const isSelectedDate = selectedDates.some(selectedDate => selectedDate && selectedDate.getDate() === date.day);
          const cellClass = isSelectedDate ? 'custom-selected' : '';

        if(isSelectedDate){
         
      
          return (
         
             <div className={cellClass} style={{color:date.color,background:date.background,padding:date.padding}}>
             {date.day}
           </div>
          );
        }else if(!isSelectedDate){
          const isHighlighted = enableColors.some((highlightedDate:any) => {
            date.color=highlightedDate.color;
            date.background=highlightedDate.background;
            date.padding=highlightedDate.padding;
          return date.day === highlightedDate.dates.getDate() &&
            date.month === highlightedDate.dates.getMonth() &&
            date.year === highlightedDate.dates.getFullYear();
        });
    
        if (isHighlighted) {
          return (
            <div className="highlighted-date" style={{color:date.color,background:date.background,padding:date.padding}}>
              {date.day}
            </div>
          );
        } else {
          return (
            <div>
              {date.day}
            </div>
          );
        }
        }
        
       
      }


      const nextMonth = () => {
        const next = new Date(viewDate);
        next.setMonth(next.getMonth() + 1);
        setViewDate(next);
      };
    
      const prevMonth = () => {
        const prev = new Date(viewDate);
        prev.setMonth(prev.getMonth() - 1);
        setViewDate(prev);
      };

      const handleViewDateChange = (updatedViewDate:any) => {
        // 
        setViewDate(updatedViewDate.value);
        let thismonth=new Date(updatedViewDate.value);
        // 
        const ss = RRule.fromString(rrule);
         setEnabledDates(ss.between(datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()), datetime(thismonth.getFullYear(), thismonth.getMonth()+1, thismonth.getDate()+31)))
        
        allactivity(sharedUser,ss.between(datetime(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()), datetime(thismonth.getFullYear(), thismonth.getMonth()+1, thismonth.getDate()+31)));
    };

    
    const allactivity = (user: any, arr: any) => {

      let uservalue = user.find((val: any) => val.phone == "+" + phonenumber);
  
      // uservalue.allDays='';
      
      
      if (uservalue) {
        if (uservalue.goingDate.length == 0 && uservalue.notGoingDate.length == 0 && uservalue.allDays === true) {
          
          let allpassValue = arr.map((s: any) => { return { "dates": s, "color": '#fff',background:'#369986d9',padding:'20px' } })
          setenableColors(allpassValue)
        } else if (uservalue.goingDate.length == 0 && uservalue.notGoingDate.length == 0 && uservalue.allDays === false) {
          
          let allpassValue = arr.map((s: any) => { return { "dates": s, "color": '#fff',background:'#e48a8a',padding:'20px' } })
          setenableColors(allpassValue)
        }else if (uservalue.notGoingDate.length == 0 && uservalue.allDays === true) {
          
          let allpassValue = arr.map((s: any) => { return { "dates": s, "color": '#fff',background:'#369986d9',padding:'20px'  } })
          setenableColors(allpassValue)
        }else if (uservalue.goingDate.length == 0 && uservalue.allDays === false) {
          
          let allpassValue = arr.map((s: any) => { return { "dates": s, "color": '#fff',background:'#e48a8a',padding:'20px' } })
          setenableColors(allpassValue)
        }else if (uservalue.notGoingDate.length > 0 && uservalue.allDays === true) {
          
          let allpassValue = arr.map((sd: any) => {
            let getDate = uservalue.notGoingDate.find((s: any) => { if (checktwoDates(sd, s) == true) { return s } });
            
  
            if (checktwoDates(sd, getDate) == true) {
              return { "dates": sd, "color": '#fff',background:'#e48a8a',padding:'20px' }
            } else {
              return { "dates": sd, "color": '#fff',background:'#369986d9',padding:'20px'  }
            }
          })
          
          setenableColors(allpassValue)
        } else if (uservalue.goingDate.length > 0 && uservalue.allDays === false) {
          
          let allpassValue = arr.map((sd: any) => {
            let getDate = uservalue.goingDate.find((s: any) => { if (checktwoDates(sd, s) == true) { return s } });
            
  
            if (checktwoDates(sd, getDate) == true) {
              return { "dates": sd, "color": '#fff',background:'#369986d9',padding:'20px'  }
            } else {
              return { "dates": sd, "color": '#fff',background:'#e48a8a',padding:'20px' }
            }
          })
          
          setenableColors(allpassValue)
        } else if (uservalue.allDays === '') {
          
          let allpassValue = arr.map((sd: any) => {
            let getDate = uservalue.goingDate.find((s: any) => { if (checktwoDates(sd, s) == true) { return s } });
            let getDatenotgoing = uservalue.notGoingDate.find((s: any) => { if (checktwoDates(sd, s) == true) { return s } });
            
  
  
            if (checktwoDates(sd, getDate) == true) {
              return { "dates": sd, "color": '#fff',background:'#369986d9',padding:'20px' }
            } else if (checktwoDates(sd, getDatenotgoing) == true) {
              return { "dates": sd, "color": '#fff',background:'#e48a8a',padding:'20px'  }
            } else {
              return { "dates": sd, "color": '#999fa6'}
            }
          })
          
          setenableColors(allpassValue);
  
        }
        // if(user.allDays == '' && user.goingDate.l)
      }
  
    }
  
    const checktwoDates = (compare: any, check: any) => {
      
      const date1 = new Date(compare);
      const date2 = new Date(check);
      
      if (date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()) {
        return true;
      } else {
        return false;
      }
    }


    const handleSelect = (e:any) => {
      setSelectedDates([...selectedDates, e.value]); // Add the selected date to the state
    };

    const customDateTemplate = (date:any) => {
     
    };

  return (
    <div className="popup">
        
      <div className="popup_inner_2" style={{padding:'10px'}}>
        <div style={{textAlign: 'right',marginBottom: '10px'}} >
        <button style={{background: 'none'}}   onClick={ props.closeCalandar }> <span className="crosssign">
    <div className="crosssign_circle"></div>
    <div className="crosssign_stem"></div>
    <div className="crosssign_stem2"></div>
  </span> </button>
        </div>
      
      <Calendar  value={date}  viewDate={viewDate}
        onViewDateChange={handleViewDateChange} onChange={(e:any) => {setDate(e.value);console.log(e)}}     onSelect={handleSelect}   dateTemplate={renderDateTemplate} selectionMode="multiple" enabledDates={enabledDates}   readOnlyInput inline />
        <div style={{textAlign:'center'}}>
        <button style={{width: '90%'}} className='buttonprimary' onClick={ props.submitcalendarAction }> Accept </button>
        {/* <button style={{width: '90%'}}  className='buttonprimary' onClick={ props.closeCalandar }> close </button> */}
        </div>
        
        {/* <button className='buttonprimary' onClick={ props.closePopup }> ChooseDate </button> */}
      </div>
    </div>    
  )
}
export default CalenderPopup
