import { FunctionComponent, useCallback, useEffect, useState } from "react";
import styles from "./EventEventDetailsBrowse1.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import Popup from "./popup";
import CalenderPopup from "./calenderpopup";
import { RRule, datetime } from "rrule";
import BottomPopup from "./bottomPopup";
import { isMobile, isAndroid } from 'react-device-detect';
import { FaCheckCircle, FaTimesCircle, FaRegTimesCircle } from "react-icons/fa";
// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
import axios from 'axios';
const apiUrl = "../api";
const EventEventDetailsBrowse1: FunctionComponent = () => {


  const [activityData, setActivityData]: any = useState('');
  const [firstName, setFirstname] = useState('');
  const [lastName, setLastName] = useState('');
  const [browserdata, setBrowserData]: any = useSearchParams();
  const [accepted, setAccepted] = useState('');
  const [chooseDateType, setchooseDateType] = useState(false);
  const [typeChoose, settypeChoose] = useState('');
  const [typeCalender, settypeCalender] = useState(false);
  const [eventDates, seteventDates] = useState<Date[]>([]);
  const [passcolorDates, setpasscolorDates] = useState<Object[]>([]);
  const [eventProccess, seteventProccess] = useState('initial value');
  const [disabledDate, setdisabledDate] = useState<Date[]>([]);
  const [Rrule, setRrule] = useState(false);
  const [rruleText, setrruleText] = useState('');
  const [numberBoolean, setnumberBoolean] = useState(false);
  const [bottomPopup, setbottomPopup] = useState(false);
  const [firstRender, setFirstRender] = useState(false);
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [Installed, setInstalled] = useState(false);
  const [twomontDates, settwomontDates] = useState<Date[]>([]);
  const [showEventcomplte, setshowEventcomplte] = useState(false);
  const [rRule, setrRule] = useState(false);


  const navigate = useNavigate();
  
  useEffect(() => {
    
    if (!activityData || activityData == "" || activityData == undefined) {
      fetchTodos();
    }



    //  
    const checkAppInstallation = async () => {
      if ('getInstalledRelatedApps' in navigator && typeof navigator.getInstalledRelatedApps === 'function') {
        try {
          const relatedApps = await navigator.getInstalledRelatedApps?.();
          if (relatedApps) {
            const isInstalled = relatedApps.some((app: any) => {
              // Replace 'com.example.app' with your package name or bundle identifier
              return app.id === 'adventureplanet.com://';
            });
            setIsAppInstalled(isInstalled);
            // alert(isInstalled);
          }
        } catch (error) {
          console.error('Error checking app installation:', error);
        }
      } else {
        console.warn('getInstalledRelatedApps is not supported in this browser.');
      }
    };

    checkAppInstallation();

  }, [firstRender]);

  const setBootompopupActive = () => {
    setbottomPopup(true)
  }

  async function fetchTodos() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    
    if (params.redirect == 'invite') {
      navigate('/webapp')
    }
    else if (params.userId) {
      navigate('/event-event-details-no-account1?userId=' + params.userId)
    }
    try {
      let AcceptedData: any = localStorage.getItem("Accepted");
      setAccepted(AcceptedData)
      const todoData = await axios.get(`${apiUrl}/draftEvent/activity/${browserdata.get("id")}`);
      if (todoData.data != null) {
        let findNumber = JSON.parse(todoData.data.activitySharedUsers).find((s: any) => { if (s.phone == "+" + browserdata.get("phone").trim()) { return s } });
        
        if (findNumber == undefined) {
          setnumberBoolean(false);
        } else {
          setnumberBoolean(true);
        }
      } else {
        setnumberBoolean(false);
      }

      
      let activityData: any = todoData.data;
      const ss = RRule.fromString(activityData.activityRepeatRule);
      
      setrruleText(ss.toText());
      settwomontDates(ss.between(datetime(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()), datetime(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate() + 31)));
      let sharedUsers: any = JSON.parse(activityData.activitySharedUsers);
      let activityOwner: any = JSON.parse(activityData.activityOwner);
      activityData.activitySharedUsers = sharedUsers;
      
      setFirstname(activityOwner.firstName)
      setLastName(activityOwner.lastName)
      setActivityData(activityData);
      setRrule(activityData.activityRepeatRule.includes("FREQ"))
      let resulta = activityData.activityRepeatRule.includes("FREQ");
      
      if (resulta == true) {
        setrRule(true);
      } else {
        setrRule(false);
        if (checkDateandTime(activityData.activityStartTime)) {
          setshowEventcomplte(true)
        } else {
          
          setshowEventcomplte(false)

        }
      }

      localStorage.setItem("activityData", JSON.stringify(activityData))
      localStorage.setItem("activityId", browserdata.get("id"))
      if (!firstRender) {
        setBootompopupActive();
      }
    } catch (err) {
      
    }
  }

  const checkDateandTime = (eventtime: any) => {
    
    let eventDateandTime = new Date(eventtime);

    let currentDate = new Date();
    
    
    if (currentDate > eventDateandTime) {
      return true
    } else {
      return false
    }
  }


  const checkDateandTimeview = (eventtime: any) => {
    
    let activityStime = new Date(activityData.activityStartTime);
    
    let eventDateandTime = new Date(eventtime);
    eventDateandTime.setHours(activityStime.getHours());
    eventDateandTime.setMinutes(activityStime.getMinutes());

    let currentDate = new Date();
    // 
    
    
    if (currentDate > eventDateandTime) {
      let vv1 = twomontDates[1].toDateString();
      
      return vv1
    } else {

      let vv2 = twomontDates[0].toDateString();
      
      return vv2
    }
  }


  const goToName = async () => {


    seteventProccess('ACCEPT');
    let result = activityData.activityRepeatRule.includes("FREQ");
    
    if (result == true) {
      setchooseDateType(true);

      
    } else {
      
      togglePopup('ACCEPT');
    }
  
  }

  const declainEvent = async () => {
    // activityData.activityRepeatRule="";
    seteventProccess('DECLAIN');
    
    let result = activityData.activityRepeatRule.includes("FREQ");
    if (result == true) {
      togglePopup('DECLAIN');
      // setchooseDateType(true);
    } else {

      togglePopup('DECLAIN');
    }
  }


  const checktwoDates = (compare: any, check: any) => {
    
    const date1 = new Date(compare);
    const date2 = new Date(check);
    
    if (date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()) {
      return true;
    } else {
      return false;
    }
  }


  const close = () => {
    setchooseDateType(false);
  }

  const closeCalandar = () => {
    settypeCalender(false);
  }

  const togglePopup = (value: any) => {

    
    setchooseDateType(false);
    settypeChoose("ALLDATE");
    activityData.activitySharedUsers = activityData.activitySharedUsers.filter((val: any) => {
      if (val.phone == "+" + browserdata.get('phone').trim()) {

        let processValue = typeof value == 'string' ? value : eventProccess;
        
        if (val.goingDate.length == 0 && val.notGoingDate.length == 0) {

          if (processValue == 'ACCEPT') {
            
            val.allDays = true;
          } else if (processValue == 'DECLAIN') {
            
            val.allDays = false;
          }

          return val
        } else {
          val.goingDate = [];
          val.notGoingDate = [];
          if (processValue == 'ACCEPT') {
            val.allDays = true;
          } else if (processValue == 'DECLAIN') {
            val.allDays = false;
          }
          return val;
        }
      } else {
        return val
      }
    });
   

    if (activityData.activitySharedUsers.length > 0) {
      let findbyPhoneno = activityData.activitySharedUsers.find((val: any) => val.phone == '+' + browserdata.get("phone").trim());
      
      if (findbyPhoneno) {
        let processValues = typeof value == 'string' ? value : eventProccess;
        localStorage.setItem("SharedUsersData", JSON.stringify(activityData.activitySharedUsers));
        navigate('/no-account-accept-invite-enter-name-disabled?eventId=' + browserdata.get("id") + '&phone=' + findbyPhoneno.phone + '&value=' + processValues)
      }

    }
  }


  const chooseTypeCalender = () => {
    setchooseDateType(false);
    settypeCalender(!typeCalender);
    settypeChoose("CHOOSEDATE");
  }

  const checkDateandTimeSender = async (eventtime: any) => {
    
    let activityStime = new Date(activityData.activityStartTime);
    
    let eventDateandTime = new Date(eventtime);
    eventDateandTime.setHours(activityStime.getHours());
    eventDateandTime.setMinutes(activityStime.getMinutes());

    let currentDate = new Date();
    // 
    
    
    if (currentDate > eventDateandTime) {
      let vv1 = twomontDates.slice(1, 2);
      
      return vv1
    } else {

      let vv2 = twomontDates.slice(0, 1);
      
      return vv2
    }
  }


  const submitnextrrdate = async () => {

    let ss = twomontDates;


    if (ss.length > 0) {
      
      let newValue = await checkDateandTimeSender(twomontDates[0]);
      if (activityData.activitySharedUsers.length > 0) {
        activityData.activitySharedUsers = activityData.activitySharedUsers.filter((val: any) => {
          if (val.phone == "+" + browserdata.get('phone').trim()) {
            dateFormat(newValue).forEach((s: any) => val.goingDate.push(s));
            return val;
          } else {
            return val;
          }
        })

        localStorage.setItem("SharedUsersData", JSON.stringify(activityData.activitySharedUsers));
        navigate('/no-account-accept-invite-enter-name-disabled?eventId=' + browserdata.get("id") + '&phone=' + '+' + browserdata.get("phone").trim() + '&value=' + eventProccess)
        
      }

    }

  }

  const submitcalendarAction = () => {
    let ss = localStorage.getItem('calendervalue');
    if (ss === null) {
      ss = "default value";
    } else {
      ss = JSON.parse(ss);
    }
    
    if (eventProccess == 'ACCEPT' && ss != null) {
      
      if (activityData.activitySharedUsers.length > 0) {
        activityData.activitySharedUsers = activityData.activitySharedUsers.filter((val: any) => {

          if (val.phone == "+" + browserdata.get('phone').trim()) {
            // val.allDays='';
            
            if (val.allDays === '') {
              
              let selectedDates = dateFormat(ss);
              
              const filteredArray = val.notGoingDate.filter((date: any) => !selectedDates.some((selectedDates: any) => (checktwoDates(date, selectedDates) == true)));
              

              if (filteredArray != undefined) {
                val.notGoingDate = filteredArray;
              } else {
                dateFormat(ss).forEach((s: any) => val.notGoingDate.push(s));
              }

              // val.notGoingDate=filteredArray;
              dateFormat(ss).forEach((s: any) => val.goingDate.push(s));
              // val.goingDate=dateFormat(ss);
              return val;
            } else if (val.allDays == true || val.allDays == false) {
              
              let selectedDatesbool = dateFormat(ss);
              const filteredArray = val.notGoingDate.filter((date: any) => !selectedDatesbool.some((selectedDates: any) => (checktwoDates(date, selectedDates) == true)));
              

              if (filteredArray != undefined) {
                val.notGoingDate = filteredArray;
              }
              // else{
              //   dateFormat(ss).forEach((s:any)=> val.notGoingDate.push(s));
              // }
              dateFormat(ss).forEach((s: any) => val.goingDate.push(s));
              // val.notGoingDate=[];
              return val;
            }
          } else {
            return val;
          }
        })
        localStorage.setItem("SharedUsersData", JSON.stringify(activityData.activitySharedUsers));
        navigate('/no-account-accept-invite-enter-name-disabled?eventId=' + browserdata.get("id") + '&phone=' + '+' + browserdata.get("phone").trim())
        

      }
    } else if (eventProccess == 'DECLAIN' && ss != null) {
      
      if (activityData.activitySharedUsers.length > 0) {
        
        activityData.activitySharedUsers = activityData.activitySharedUsers.filter((val: any) => {
          if (val.phone == "+" + browserdata.get('phone').trim()) {
            // val.allDays='';
            if (val.allDays === '') {
              
              let selectedDates = dateFormat(ss);
              
              const filteredArray = val.goingDate.filter((date: any) => !selectedDates.some((selectedDates: any) => (checktwoDates(date, selectedDates) == true)));
              
              if (filteredArray != undefined) {
                val.goingDate = filteredArray;
              } else {
                dateFormat(ss).forEach((s: any) => val.goingDate.push(s));
              }

              // filteredArray.forEach((s:any)=> val.goingDate=s);
              dateFormat(ss).forEach((s: any) => val.notGoingDate.push(s));
              // val.notGoingDate=dateFormat(ss);
              return val;
            } else if (val.allDays == true || val.allDays == false) {
              let selectedDatesbool = dateFormat(ss);
              const filteredArray = val.goingDate.filter((date: any) => !selectedDatesbool.some((selectedDates: any) => (checktwoDates(date, selectedDates) == true)));
              

              if (filteredArray != undefined) {
                val.goingDate = filteredArray;
              }
              dateFormat(ss).forEach((s: any) => val.notGoingDate.push(s));
              // val.goingDate=[];
              return val
            }
          } else {
            return val
          }
        })
        localStorage.setItem("SharedUsersData", JSON.stringify(activityData.activitySharedUsers));
        navigate('/no-account-accept-invite-enter-name-disabled?eventId=' + browserdata.get("id") + '&phone=' + '+' + browserdata.get("phone").trim())
        

      }
    }
  }

  const dateFormat = (value: any) => {
    return value.map((val: any) => {
      const inputDate = val;
      const dateObj = new Date(inputDate);
      const formattedDate = dateObj.toDateString().substr(0, 3) + '' + dateObj.toDateString().substr(3);

      
      return formattedDate
    })
  }

  const chagneFistletter = (value: string) => {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    } else {
      return '';
    }

  }

  const togglebottomPopup = (value: any) => {
    setbottomPopup(false);
  }

  const checkAndRedirectToApp = () => {
    
    // alert("open app");
    const appPackageName = 'adventureplanet.com://'; // Replace with your app's package name

    if (isMobile && !isAndroid) {
      // iOS device
      checkIsAppInstalledIOS();

    } else if (isMobile && isAndroid) {
      // Android device

      checkIsAppInstalledAndroid();

    } else {
      // Non-mobile device
      checkIsAppInstalledAndroid();// Redirect to your website
    }
  };

  const checkIsAppInstalledAndroid = async () => {
    // Define your app's custom URI scheme
    const customScheme = `adventureplanet.com://`;

    return new Promise((resolve) => {
      // Create an invisible iframe and try to open your app's custom URI scheme
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = customScheme;
      document.body.appendChild(iframe);

      // Set a timeout to determine if the app is installed or not
      const timeout = setTimeout(() => {
        document.body.removeChild(iframe);

        resolve(false); // Timeout occurred, app is likely not installed
        if (Installed == false) {
          window.location.href = "https://play.google.com/store/apps/details?id=fun.motus.app&hl=en_CA&gl=US";
        }
      }, 2000); // Adjust the timeout duration as needed

      // Listen for when the app's custom URI scheme is opened
      window.addEventListener('blur', () => {
        clearTimeout(timeout);
        document.body.removeChild(iframe);
        setInstalled(true);

        resolve(true); // App is installed
        window.location.href = customScheme;
      });
    });
  };

  const checkIsAppInstalledIOS = async () => {
    // Define your app's custom URI scheme
    const customSchemeIos = `motus://`;

    return new Promise((resolve) => {
      // Create an invisible iframe and try to open your app's custom URI scheme
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = customSchemeIos;
      document.body.appendChild(iframe);

      // Set a timeout to determine if the app is installed or not
      const timeout = setTimeout(() => {
        document.body.removeChild(iframe);

        resolve(false); // Timeout occurred, app is likely not installed
        if (Installed == false) {
          window.location.href = "https://apps.apple.com/ca/app/motus-fun/id6474702612";
        }
      }, 2000); // Adjust the timeout duration as needed

      // Listen for when the app's custom URI scheme is opened
      window.addEventListener('blur', () => {
        clearTimeout(timeout);
        document.body.removeChild(iframe);
        setInstalled(true);

        resolve(true); // App is installed
        window.location.href = customSchemeIos;
      });
    });
  };


  const checkAndRedirectToAppCreateAccoount = async () => {
    
    // alert("open app");
    const appPackageName = 'adventureplanet.com://'; // Replace with your app's package name
    try {
      if (isMobile && !isAndroid) {
        // iOS device
        
        window.location.href = appPackageName;
        setTimeout(function () { window.location.href = "https://app.motus.fun/?redirect=invite"; }, 2000);
      } else if (isMobile && isAndroid) {
        
        // Android device
        window.location.href = appPackageName;
        setTimeout(function () { window.location.href = "https://app.motus.fun/?redirect=invite"; }, 2000);

      } else {
        
        

        try {
          window.location.href = "adventureplanet.com://";

          window.addEventListener('error', function (event) {
            console.error("Network error occurred:", event);
            // Perform additional error handling or logging here
          });
        } catch (error) {
          console.error("An error occurred while navigating to the URL:", error);
          // Handle the error gracefully, e.g., display a message to the user
        }
      }

    } catch (error) {
      console.error('Error during redirection:', error);
    }
  };



  return (
    <div style={{ padding: '0px' }}>
      <div>
        {bottomPopup ? <BottomPopup closePopup={togglebottomPopup} openApp={checkAndRedirectToApp} /> : <></>}
      </div>
      <>
        {showEventcomplte ? <><div style={{ margin: 'auto', width: "100%", textAlign: "center", paddingTop: "200px" }}>Event Completed</div></> :
          <>
            {numberBoolean ?
              <>
                <div className={`${styles.eventEventDetailsNoAcc} ${styles.contentscroll} `}>

                  <div className={styles.eventEventDetailsNoAccChild}>
                    <b className={styles.adventurePlanet}>Motus</b>
                    <div style={{ paddingTop: '10px' }}>
                      <b className={styles.frommeShred}>{activityData?.activityName}</b>
                    </div>
                    <div className={styles.p10}>
                      <div className={styles.datecard}>
                        <img
                          className={styles.iconLocationPin}
                          alt=""
                          src="/-icon-time.svg"
                        />
                      </div>
                      <div>

                        <div >{rRule ? <>{checkDateandTimeview(twomontDates[0])}</> : <>{new Date(activityData.activityStartDate).toDateString()}</>}</div>
                        <div style={{ paddingLeft: "35px", color: "#958f8f" }}>{new Date(activityData.activityStartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(activityData.activityEndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                      </div>
                    </div>
                    <div style={{ paddingLeft: '15px' }}>
                      <div className={styles.datecard}>
                        <div className={styles.iconLocationPin}>&nbsp;</div>
                      </div>
                      <div>
                        <div >{rRule ? <>{chagneFistletter(rruleText)}</> : <>&nbsp;</>}</div>
                      </div>
                    </div>
                    {activityData?.activityLocation ? <div className={styles.p10}>
                      <div className={styles.datecard}>
                        <img
                          className={styles.iconLocationPin}
                          alt=""
                          src="/-icon-location-pin.svg"
                        />
                      </div>
                      <div>
                        <div>{activityData?.activityLocation}</div>
                      </div>
                    </div> : <></>}

                    {firstName ? <div className={styles.p10}>
                      <div className={styles.datecard}>
                        <img
                          className={styles.iconLocationPin}
                          alt=""
                          src="/-icon-person.svg"
                        />
                      </div>
                      <div>
                        <div >Hosted by {firstName} {lastName}</div>
                      </div>
                    </div> : <></>}

                  </div>
                  <div style={{ padding: '20px' }}>
                    <div >
                      {activityData.activitySharedUsers ? (
                        <>
                          <b className={styles.peopleAreInvited}>{activityData?.activitySharedUsers.length} people are invited</b>
                        </>
                      ) : (
                        <>
                          <b className={styles.peopleAreInvited}>0 people are invited</b>
                        </>
                      )
                      }
                      <div>
                        <div className={styles.chipParent}>
                         
                          {(activityData.activitySharedUsers && activityData.activitySharedUsers.length > 0) ? (
                            <>
                              {activityData?.activitySharedUsers.map((r: any, _index: any) => {

                                if (r.allDays == undefined || r.allDays.length == 0) {
                                  
                                  return (<div className={styles.chip}>
                                    <div className={styles.ellipseParent}>
                                      <div className={styles.orange} />

                                    </div>
                                    <div className={styles.brianTaylor}>{r.firstName} {r.lastName}</div>
                                  </div>)

                                } else if (r.allDays == true || r.goingDate.length > 0) {
                                  
                                  return (<div className={styles.chip}>
                                    <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                                      <FaCheckCircle size={20} color="#00be9a" />
                                     
                                    </div>
                                    <div className={styles.brianTaylor}>{r.firstName} {r.lastName}</div>
                                  </div>)
                                } else if (r.allDays == false || r.notGoingDate.length > 0) {
                                  
                                  return (<div className={styles.chip}>
                                    <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                                      
                                        
                                        <FaTimesCircle size={20} color="#f53131" /> 
                                      
                                     
                                    </div>
                                    <div className={styles.brianTaylor}>{r.firstName} {r.lastName}</div>
                                  </div>)
                                }
                              }



                              )}
                            </>
                          ) : null

                          }
                          
                        </div>
                      </div>
                    </div>
                    {activityData?.activityDifficulty ? <div>
                      <b className={styles.difficulty}>Difficulty</b>
                      <div className={styles.difficult}>{activityData?.activityDifficulty}</div>
                    </div> : <></>}

                    {activityData?.activityDescription ? <div>
                      <div>
                        <b className={styles.details}>Details</b>
                        <div className={styles.plr10}>
                          {activityData?.activityDescription}

                        </div>
                      </div>
                   
                    </div> : <></>}

                  </div>

                  <div className={styles.bottomfixed} style={{ width: "100%", display: 'flex' }}>

                    <div className={styles.buttonPrimary} style={{ backgroundColor: accepted != "" ? "#00BE9A" : "" }} onClick={goToName}>
                      <b className={styles.no}>Accept</b>
                    </div>

                    <div className={styles.buttonDeclainalign} onClick={declainEvent}>
                      <b style={{ color: 'black' }} className={styles.no}>Decline</b>
                    </div>
                  
                  </div>

                </div>

                <>
                  {chooseDateType ? <Popup title="RSVP to recurring event" closePopup={togglePopup} chooseTypeCalender={submitnextrrdate} close={close} /> : ''}
                </>

                <>
                  {typeCalender ? <CalenderPopup title="Please choose type" phone={browserdata.get('phone').trim()} repeatrule={activityData.activityRepeatRule} shareduser={activityData.activitySharedUsers} eventsArray={eventDates} submitcalendarAction={submitcalendarAction} closeCalandar={closeCalandar} closePopup={chooseTypeCalender} passcolorDates={passcolorDates} disabledDate={disabledDate} /> : ''}
                </>
              </> : <><div style={{ margin: 'auto', width: "100%", textAlign: "center", paddingTop: "200px" }}>No Data Available</div></>}
          </>
        }
      </>


    </div>

  );
};

export default EventEventDetailsBrowse1;
