import { FunctionComponent, JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useEffect, useState } from "react";
import styles from "./DraftEventList.module.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';

import axios from 'axios';
import Headertag from "./header";
import EventList from "./Eventlist";
import AvailabilitySharedNoAccou from "./AvailabilitySharedNoAccou";
import Acitivitycalender from "./ActivityCalender";

interface RelatedApplication {
    id: string;
    platform: string;
}

interface Activity {
    activityOwner: string;
    activityType: string;
    activityRepeatRule: string;
    activityStartDate: string;
    activitySharedUsers: string;
}
const apiUrl = "../api";
const DraftEventList: React.FC = () => {
    const [allEvents, setAllEvents] = useState<any[]>([]);
    const [allEventsCopy, setAllEventsCopy] = useState<any[]>([]);
    const [gridEvent, setGridEvent] = useState(false);
    const [showLoader, setshowLoader] = useState(false);
    const [visibleItems, setVisibleItems] = useState(5);
    const [activeTab, setActiveTab] = useState('activity');
    const [selectedOption, setSelectedOption] = useState("activity");
    const [username, setfirstName] = useState("draftEvent1");
    const [cookdata, setCookieData] = useState(null);
    const [browserdata, setBrowserData]: any = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {

        const getEventList = async () => {
            try {
                setshowLoader(true);
                const response = await axios.get(`${apiUrl}/draftEvent`);
                const responseUser = await axios.get(`${apiUrl}/draftEvent/users/list`);

                const userDetails = responseUser['data'].data;
                const cookieData = Cookies.get('phoneNumber');
                let cookiesData = cookieData ? JSON.parse(cookieData) : {};
                if (cookiesData.firstName) {
                    setfirstName(cookiesData.firstName + "" + cookiesData.lastName)
                } else {
                    setfirstName(cookiesData.phone)

                }
                setCookieData(cookiesData);
                const filterVal = response.data.map((val: any) => {
                    try {

                        // let phoneNmber='';
                        // if(cookieData){
                        //     phoneNmber=JSON.parse(cookieData).phone;
                        // }
                        // 
                        if (val.startDateTime) {
                            if (typeof val.startDateTime == 'string') {
                                val.draft = true;
                                val.startDateTime = JSON.parse(val.startDateTime);
                                val.participants = JSON.parse(val.participants);
                                // 
                                return val
                            }
                            val.draft = true;
                            return val
                        } else {
                            val.draft = true;
                            return val
                        }
                    } catch (error) {
                        response.data.startDateTime = []
                    }
                })

                setAllEvents(response.data);
                let phoneNum = cookiesData.phone;
                // phoneNum = phoneNum.trim().replace(/\s+/g, '');
                // phoneNum = phoneNum.slice(1)
                // 
                let responses = response.data.filter((val: any) => {

                    //   
                    if (formatPhoneNumber(val.owner) == phoneNum) {

                        let findUserData = userDetails.find((user: any) => user.phone == formatPhoneNumber(val.owner));

                        val.userName = findUserData && findUserData.firstName ? findUserData?.firstName + ' ' + findUserData?.lastName : formatPhoneNumber(val.owner);
                        return val
                    } else {

                        let findparticipatns = val.participants.find((s: any) => formatPhoneNumber(s) == phoneNum);
                        if (findparticipatns) {
                            let findUserData = userDetails.find((user: any) => user.phone == formatPhoneNumber(val.owner));

                            val.userName = findUserData && findUserData.firstName ? findUserData?.firstName + ' ' + findUserData?.lastName : formatPhoneNumber(val.owner);
                            return val
                        }
                    }
                });

                let phoneNums = '';
                await fetchTodos(responses, phoneNums);
            } catch (error) {
                setshowLoader(false);
                console.error('Error fetching event data:', error);
            }
        };
        if (browserdata.get("code") != null) {
            const CalendatData = Cookies.get('skipcalendar');
            if (CalendatData != null || CalendatData != undefined) {
                if (CalendatData == 'true') {
                    navigate("/details?googlestatus=true")
                    Cookies.set('code', browserdata.get("code"))
                } else if (CalendatData == 'false') {
                    navigate("/googlecalender?googlestatus=true")
                    Cookies.set('code', browserdata.get("code"))
                }
            }

        } else if (location.search == '') {
            const storedNumber = Cookies.get('phoneNumber');
            if (storedNumber) {
                getEventList();
            } else {
                navigate('/signin')
            }
        }


    }, []);

    const formatPhoneNumber = (phone: string) => {
        phone = phone.toString(); // Ensure the phone is a string

        if (!phone.startsWith('+')) {
            phone = '+' + phone;
        }
        return phone;
    }

    const fetchTodos = async (event: any, phone: any) => {
        try {
            const mergeArray = event


            setAllEvents(mergeArray);
            setAllEventsCopy(mergeArray)
            setshowLoader(false);
        }
        catch (error) {

        }
    }

    const filterByphone = (value: any) => {

        const eventListValue = value.map((e: any) => { })
    }

    const loadMore = () => {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + 5); // Add 5 more items
    };
    const viewEvent = (id: any) => {
        navigate('/de/' + id)
    }
    const viewEventacivity = (id: any) => {
        // navigate('/event-event-details-browser1?id=' + id + '&phone=+17782334643')
    }

    function getFormattedDate(time: any) {

        if (time == undefined || time == undefined) {
            return '00:00'
        } else {


            const date = new Date(time);

            // Get parts of the date
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            // Determine AM or PM
            const ampm = hours >= 12 ? 'PM' : 'AM';

            // Convert 24-hour format to 12-hour format
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'

            // Add leading zeros to minutes and seconds
            const strMinutes = minutes < 10 ? '0' + minutes : minutes;
            const strSeconds = seconds < 10 ? '0' + seconds : seconds;

            // Format the time string
            const formattedTime = `${hours}:${strMinutes} ${ampm}`;

            return formattedTime;
        }
    }

    const dateFormat = (value: any) => {
        if (value) {
            let createdDate = new Date(value);
            const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
            const formattedDate = createdDate.toLocaleDateString('en-GB', options);
            return "Created At : " + formattedDate;
        } else {
            let newDate = new Date();
            const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
            const formattedDate = newDate.toLocaleDateString('en-GB', options);
            return "Created At : " + formattedDate;
        }

    }

    const handleTabChange = (event: any) => {


        if (event.target.value == 'draftEvent1') {
            let events = allEventsCopy.filter(s => {
                let rawDate = s.expiryDate ? s.expiryDate : null;
                let expirdate = new Date(rawDate);
                let currentDate = new Date();

                if (expirdate > currentDate && rawDate != null) {
                    return s
                }
            })

            setAllEvents(events);
            setActiveTab('draftEvent1');
            setSelectedOption('draftEvent1');
        } else if (event.target.value == 'draftEvent2') {
            let events = allEventsCopy.filter(s => {
                let rawDate = s.expiryDate ? s.expiryDate : null;
                let expirdate = new Date(rawDate);
                let currentDate = new Date();

                if (expirdate < currentDate && rawDate != null && s.eventStatus == "Declined") {
                    return s
                }
            })

            setAllEvents(events);
            setActiveTab('draftEvent1');
            setSelectedOption('draftEvent2');
        } else {
            setActiveTab(event.target.value);
            setSelectedOption(event.target.value);
        }

    };

    const notify = (event: any) => {


        if (event == 'draftEvent1') {
            let events = allEventsCopy.filter(s => {
                let rawDate = s.expiryDate ? s.expiryDate : null;
                let expirdate = new Date(rawDate);
                let currentDate = new Date();

                if (expirdate > currentDate && rawDate != null) {
                    return s
                }
            })

            setAllEvents(events);
            setActiveTab('draftEvent1');
            setSelectedOption('draftEvent1');
        } else if (event == 'draftEvent2') {
            let events = allEventsCopy.filter(s => {
                let rawDate = s.expiryDate ? s.expiryDate : null;
                let expirdate = new Date(rawDate);
                let currentDate = new Date();

                if (expirdate < currentDate && rawDate != null && s.eventStatus == "Declined") {
                    return s
                }
            })

            setAllEvents(events);
            setActiveTab('draftEvent1');
            setSelectedOption('draftEvent2');
        } else {
            setActiveTab(event);
            setSelectedOption(event);
        }

    };

    const viewData = allEvents.slice(0, visibleItems).map((event: any, index: any) =>
        <div>
            <div>
                <div >
                    <>
                        {event.draft == true ? <> <div className={styles.buttonView} style={{ width: "100%", paddingTop: "5px", paddingBottom: "5px" }} >
                            <div style={{ borderRadius: "10px", background: '#f6f7f9' }}>
                                <div className={styles.container1}>
                                    {selectedOption == 'draftEvent1' ? <div className={styles.round}></div> : <div className={styles.roundorange}></div>}

                                    <span style={{ width: '50%' }} className={styles.text}>Event by {event.userName}</span>

                                    {/* <div style={{ width: '100%' }}>
                                        <span className={styles.text} style={{ float: 'right', color: 'red' }}>Draft</span>
                                    </div> */}
                                </div>
                                <div className={styles.text2}>{event.name ? event.name : '--'}</div>
                                <div className={styles.container3} >
                                    <div className={styles.outerschedule}><p className={styles.schedule} style={{ cursor: 'pointer' }} onClick={() => viewEvent(event.Id)} >View</p></div>
                                </div>
                                <div className={styles.container2}>
                                    <div>&nbsp;&nbsp;</div>
                                    <div style={{ paddingLeft: '10px' }}>{dateFormat(event.createdAt)}</div>
                                    {/* <>{event.startDateTime && event.startDateTime.length > 0 ? <>{event.startDateTime.map((startTimeObj: any, index: number) => (
                                        <>{startTimeObj != '' ? (<div>{getFormattedDate(startTimeObj?.date)} - {getFormattedDate(event.endDateTime[index])}</div>) : '--:--'}</>
                                    ))}</> : '--:--'}</> */}

                                </div>
                            </div>

                        </div></> : <> <div className={styles.buttonView} style={{ width: "100%", paddingTop: "5px", paddingBottom: "5px" }} >
                            <div style={{ borderRadius: "10px", background: '#f6f7f9' }}>
                                <div className={styles.container1}>
                                    <div className={styles.round}></div>
                                    <span className={styles.text}>Event by {event.activityOwner ? `${event.activityOwner.firstName} ${event.activityOwner.lastName}` : '--'}</span>

                                </div>
                                <div className={styles.text2}>{event.activityName ? event.activityName : '--'}</div>
                                <div className={styles.container3} >
                                    <div className={styles.outerschedule}><p className={styles.schedule} style={{ cursor: 'pointer' }} onClick={() => viewEventacivity(event.id)} >View</p></div>
                                </div>
                                <div className={styles.container2}>
                                    <div>&nbsp;&nbsp;</div>
                                    {/* <>{event.startDateTime && event.startDateTime.length > 0 ? <>{event.startDateTime.map((startTimeObj: any, index: number) => (
                                        <>{startTimeObj != '' ? (<div>{getFormattedDate(startTimeObj?.date)} - {getFormattedDate(event.endDateTime[index])}</div>) : '--:--'}</>
                                    ))}</> : '--:--'}</> */}

                                </div>
                            </div>

                        </div></>}


                    </>
                </div>

            </div>
        </div>

    )

    return (
        <div>
            <div><Headertag showHeader={false} dropDownShow={true} value={selectedOption} onChange={handleTabChange} /></div>

            {/* <div style={{ padding: '30px' }}>
                <select id="dropdown" style={{ float: 'right', padding: '5px',borderRadius:'50px',width:'40%' }} value={selectedOption} onChange={handleTabChange}>
                    <option value="" disabled>Select an option</option>
                    <option value="draftEvent1">Draft Events</option>
                    <option value="draftEvent2">Archived Events</option>
                    <option value="activity">Active Events</option>
                </select>
            </div> */}
            {/* <div className={styles.tabs}>
                <button
                    onClick={() => handleTabChange('draftEvent')}
                    className={activeTab === 'draftEvent' ? styles.active : ''}
                >
                    Draft Event
                </button>
                <button
                    onClick={() => handleTabChange('activity')}
                    className={activeTab === 'activity' ? styles.active : ''}
                >
                    Activity
                </button>
            </div> */}
            {/* <div className={styles.leftAlign} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                <b style={{ fontSize: '20px' }} className={styles.subTitle}>Schedule</b>
                <span className={styles.filter}>
                    <img onClick={() => setGridEvent(false)} className={styles.iconListChild} alt="" src="/frame-20.svg" />
                    <img onClick={() => setGridEvent(true)} className={styles.iconListChild} alt="" src="/frame-21.svg" />
                </span>
            </div> */}
            <div className={styles.tabcontent}>
                {activeTab === 'draftEvent1' && <>  <div>
                    {/* <div className={styles.leftAlign}>
                <b className={styles.title}>Motus</b>
            </div> */}




                    {allEvents.length > 0 ? (
                        <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                            {viewData}
                            {visibleItems < allEvents.length && (
                                <div style={{ width: '100%', padding: '20px', textAlign: 'center' }}>
                                    <button className={styles.loadmore} onClick={loadMore}>Load more</button>

                                </div>)}
                            {/* <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                                   <p ><a >Skip</a></p>
                               </div> */}
                        </div>
                    ) : (
                        <div>
                            {showLoader ? <div className={styles.centerloader}><div className={styles.loader}></div></div> : <div style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '20px' }}>
                                {selectedOption == 'draftEvent1' ? <><div>No Available Event </div>
                                    <div>Go to <a style={{ cursor: 'pointer' }} onClick={() => { notify('draftEvent2') }}>Archived Events</a> or <a style={{ cursor: 'pointer' }} onClick={() => { notify('activity') }}>Active Events</a></div></> : <></>}
                                {selectedOption == 'draftEvent2' ? <><div>No Available Event </div>
                                    <div>Go to <a style={{ cursor: 'pointer' }} onClick={() => { notify('draftEvent1') }}>Draft Events</a> or <a style={{ cursor: 'pointer' }} onClick={() => { notify('activity') }}>Active Events</a></div></> : <></>}
                            </div>}


                        </div>
                    )}


                </div></>}
                <div>{gridEvent == true && activeTab == 'activity' ? <><Acitivitycalender /></> : <>{activeTab === 'activity' && <><div><EventList noti={notify} /></div></>}  </>} </div>
            </div>
        </div>

    );
};

export default DraftEventList;