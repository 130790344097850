import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './header.module.css';
import { FaArrowLeft } from "react-icons/fa";
import axios from "axios";
import { LiaRedoSolid } from "react-icons/lia";
import '../global.css';


const apiUrl = "../api";

const Headertag = (props: any) => {
  const [user, setuser] = useState<any>('');
  const [process, setprocess] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [showArrow, setshowArrow] = useState(false);
  const [userdetails, setusrDetails]: any = useState('');
  const [showdropDown, setshowdropDown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // 
    const auth: string | undefined = Cookies.get('phoneNumber');

    if (auth !== undefined) {
      const phonenumber = JSON.parse(auth);
      getUser();
      setuser(phonenumber);
      setprocess(true);
      setshowArrow(props.showHeader);
      setshowdropDown(true);
    } else {
      setuser('');
      setprocess(false);
      setshowArrow(false);
      setshowdropDown(false);
    }
  }, [])

  const getUser = async () => {
    const CookeData = Cookies.get('phoneNumber');
    let cookieData = CookeData ? JSON.parse(CookeData) : {};
    let user = ''
    try {
      let modifiedNumber = cookieData.phone.replace("+", "");
      const responseData = await axios.get(`${apiUrl}/draftEvent/user/` + modifiedNumber);

      user = responseData['data'].Items[0];
      setusrDetails(responseData['data'].Items[0])
    } catch (err: any) {
      setusrDetails(undefined)
    }


  }


  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const handleSignout = () => {
    Cookies.remove('phoneNumber');
    Cookies.remove('specifichours');
    Cookies.remove('weeklyhours');
    Cookies.remove('auth');
    Cookies.remove('eventDate');
    Cookies.remove('calenderToken');

    navigate('/signin')
  }
  //   const { text, required, htmlFor } = props;
  return (
    <>
      {/* <div>
      <div style={{width:'100%',textAlign:'center',fontSize:'20px',color:'black',cursor:'pointer'}} onClick={()=> navigate('/feedback')} >Click here to report an issue / feedback</div>
      </div> */}

      <div className={styles.stripheader}>
        {showdropDown && props.dropDownShow ? <div style={{ padding: '10px', margin: '0 auto', width: '50%' }}>
          {/* <label htmlFor="dropdown">Choose an option: </label> */}
          <select id="dropdown" className={styles.headerdropdown} value={props.value} onChange={props.onChange}>
            {/* <option value="" style={{color:'#e5e7eb'}} disabled>Select an option</option> */}
            <option value="draftEvent1">Draft Events</option>
            <option value="draftEvent2">Archived Events</option>
            <option value="activity">Active Events</option>
          </select>
          {/* {selectedOption && <p>You selected: {selectedOption}</p>} */}
        </div> : <></>}

        {showArrow == true ? <div style={{ textAlign: 'left', width: '100%' }}>
          <div className={styles.adventurePlanet} style={{ display: 'flex' }}>
            <FaArrowLeft onClick={() => { navigate('/') }} style={{ width: '40px', height: '36px', paddingTop: '15px', cursor: 'pointer' }} />
            <div style={{ paddingTop: '14px', fontSize: '18px' }}>Dashboard</div>
          </div>
        </div> : <></>}



        {process ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>

              {/* <div style={{ paddingRight: '10px' }}>Login as: {user.phone}</div> */}
              <div className={styles.profileWrapper} onClick={toggleDropdown}>
                <div>

                  <img
                    src="../logo.png"
                    alt="profile"
                    className={styles.profileIcon}
                  />
                </div>
                {isDropdownOpen && (
                  <div className={styles.dropdown}>
                    {userdetails.firstName && userdetails.lastName ? <div
                      className={styles.dropdownItem}
                      style={{ fontSize: '20px' }}
                    >{userdetails.firstName} {userdetails.lastName}</div> : <></>}
                          
                    <div
                      style={{ color: 'rgb(173 166 166)', fontSize: '16px' }}
                      className={styles.dropdownItem}

                    >
                      {user.phone}
                    </div>
                    <div
                      className={styles.dropdownItem}
                      style={{ fontSize: '18px',paddingTop:'15px',textAlign:'center' }}
                      onClick={() => navigate('/feedback')}
                    >
                      <a>Submit Feedback <LiaRedoSolid />
                      </a>
                    </div>
                    <div
                      className={styles.dropdownItem}
                      onClick={() => navigate('/details', { state: { status: true } })}
                    >
                      <button className={styles.submitbtn} style={{ fontSize: '16px' }}>My Account</button>

                    </div>

                    {/* <div
                    className={styles.dropdownItem}
                    onClick={() => navigate('/')}
                  >
                    Dashboard
                  </div>
                  <div className={styles.border}></div>
                  <div
                    className={styles.dropdownItem}
                    onClick={() => navigate('/details', { state: { status: true } })}
                  >
                    Profile
                  </div>
                  <div className={styles.border}></div>
                  <div
                    className={styles.dropdownItem}
                    onClick={() => navigate('/generalavailability', { state: { status: true } })}
                  >
                    General Availability
                  </div>
                  <div className={styles.border}></div>
                  <div
                    className={styles.dropdownItem}
                    onClick={() => navigate('/googlecalender', { state: { status: true } })}
                  >
                    Google Access
                  </div>
                  <div className={styles.border}></div>
                  <div
                    className={styles.dropdownItem}
                    onClick={() => navigate('/feedback')}
                  >
                    Feedback
                  </div>
                  <div className={styles.border}></div>
                  <div className={styles.dropdownItemlogout} onClick={handleSignout}>
                    Signout
                  </div>
                  <div className={styles.border}></div>
                  <div
                    className={styles.dropdownItemlogedin}
                  >
                    Logged in as {user.phone}
                  </div> */}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>

            <div
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/signin')}
            >
              <div style={{ paddingRight: '10px' }}>
                Not logged in, click here to Login/Sign up
              </div>
            </div>
          </>
        )}
      </div>
      {/* <div className={styles.stripheader}>
        {process ? <><div style={{paddingRight:'10px'}}>Login as : {user.phone}</div>
        <div style={{cursor:'pointer'}} onClick={()=>{SignOutAlt()}}>Signout</div></> : <>
        <div style={{paddingRight:'10px'}}>Not logedin click here to signup _ </div>
        <div style={{cursor:'pointer'}} onClick={()=>{navigate('/signin')}}>Login</div></>}
        
    </div> */}
      {/* <label htmlFor={htmlFor}>
        <span className="font-bold">{text}</span>
        {required && <span className="text-red-700">*</span>}
      </label> */}
    </>
  );
};

export default Headertag;