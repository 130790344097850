import { FunctionComponent, useCallback, useEffect, useState } from "react";
import styles from "./EventEventDetailsBrowse1.module.css";
import { Container } from "@material-ui/core";
import { useNavigate, useSearchParams } from "react-router-dom";
import Popup from "./popup";
import CalenderPopup from "./calenderpopup";
import { RRule, datetime } from "rrule";
import { arrayBuffer } from "stream/consumers";
import BottomPopup from "./bottomPopup";
import { isMobile, isAndroid } from 'react-device-detect';
import { FaCheckCircle, FaTimesCircle, FaRegTimesCircle } from "react-icons/fa";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import MapPopup from "./Mappopup";
import OptionPopup from "./Optionpopup";
import Headertag from "./header";
import { FaArrowLeft } from "react-icons/fa6";
import Cookies from "js-cookie";

// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';


const apiUrl = "../api";
const EventEventDetailsBrowse1: FunctionComponent = () => {


  const [activityData, setActivityData]: any = useState('');
  const [circleColor, setcircleColor]: any = useState('');
  const [firstName, setFirstname] = useState('');
  const [lastName, setLastName] = useState('');
  const [browserdata, setBrowserData]: any = useSearchParams();
  const [accepted, setAccepted] = useState('');
  const [chooseDateType, setchooseDateType] = useState(false);
  const [typeChoose, settypeChoose] = useState('');
  const [typeCalender, settypeCalender] = useState(false);
  const [eventDates, seteventDates] = useState<Date[]>([]);
  const [passcolorDates, setpasscolorDates] = useState<Object[]>([]);
  const [eventProccess, seteventProccess] = useState('initial value');
  const [disabledDate, setdisabledDate] = useState<Date[]>([]);
  const [Rrule, setRrule] = useState(false);
  const [rruleText, setrruleText] = useState('');
  const [numberBoolean, setnumberBoolean] = useState(false);
  const [bottomPopup, setbottomPopup] = useState(false);
  const [firstRender, setFirstRender] = useState(false);
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [Installed, setInstalled] = useState(false);
  const [twomontDates, settwomontDates] = useState<Date[]>([]);
  const [showEventcomplte, setshowEventcomplte] = useState(false);
  const [rRule, setrRule] = useState(false);
  const [mapshowPopup, setmapshowPopup] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [textLocation, settextLocation] = useState(false);
  const [locationshow, setLocatuibShow] = useState(false);
  const [userlist, setUserList] = useState<Object[]>([]);

  const { id } = useParams() as { id: string };;
  

  const navigate = useNavigate();

  useEffect(() => {
    
    const CookeData = Cookies.get('phoneNumber');
    let cookieData = CookeData ? JSON.parse(CookeData) : {};
    
    if (cookieData.phone) {
      
      setLocatuibShow(true)
    } else {
      
      setLocatuibShow(false)
    }
    setshowLoader(true)
    getpatients(id);



    //  
    const checkAppInstallation = async () => {
      if ('getInstalledRelatedApps' in navigator && typeof navigator.getInstalledRelatedApps === 'function') {
        try {
          const relatedApps = await navigator.getInstalledRelatedApps?.();
          if (relatedApps) {
            const isInstalled = relatedApps.some((app: any) => {
              // Replace 'com.example.app' with your package name or bundle identifier
              return app.id === 'adventureplanet.com://';
            });
            setIsAppInstalled(isInstalled);
            // alert(isInstalled);
          }
        } catch (error) {
          console.error('Error checking app installation:', error);
        }
      } else {
        console.warn('getInstalledRelatedApps is not supported in this browser.');
      }
    };

    checkAppInstallation();

  }, [firstRender]);


  const closemapPopup = () => {
    setmapshowPopup(false);
    getpatients(id);
  }


  const getpatients = async (id: any) => {
    
    try {
      const response = await axios.get(`${apiUrl}/draftEvent/object/` + id);
      const responseUser = await axios.get(`${apiUrl}/draftEvent/users/list`);
      const userDetails = responseUser['data'].data;
      setUserList(userDetails)
      
      try {
        response.data.startDateTime = JSON.parse(response.data.startDateTime);
        
      } catch (error) {
        response.data.startDateTime = []
      }
      try {
        if (typeof response.data.location == 'string') {
          response.data.location = JSON.parse(response.data.location);
        } else {
          response.data.location = JSON.parse(response.data.location).join(',');
        }

      } catch (error) {
        response.data.location = []
      }
      try {
        if (response.data.location.includes('outdoor') == true || response.data.location.includes('outdoors') == true || response.data.location.length == 0 || response.data.location == "") {
          settextLocation(true)
          // setmapshowPopup(true);
        } else {
          settextLocation(false)
          // setmapshowPopup(false);

        }

        response.data.owner = findUserByPhoneOwner(response.data.owner, userDetails)

        response.data.participants = JSON.parse(response.data.participants);
        const pallarrays: any[] = [];
        const nonearray: any[] = [];

        response.data.startDateTime.forEach((entry: any) => {
          pallarrays.push(...entry.poll);
          nonearray.push(...entry.none);
        });

        
        
        response.data.participants = await Promise.all(
          response.data.participants.map(async (val: any) => {
            // Check if the value is in the poll or none arrays

            const inPoll = pallarrays.includes(val);
            const inNone = nonearray.includes(val);
            
            let findUserData = userDetails.find((user: any) => user.phone == formatPhoneNumber(val));
            val = findUserData && findUserData.firstName ? findUserData?.firstName + ' ' + findUserData?.lastName : formatPhoneNumber(val);
            
            if (inPoll) {
              
              return { color: '#00be9a', value: val };
            } else if (inNone) {
              
              return { color: '#f53131', value: val };
            } else {
              
              return { color: '#fbc167', value: val };
            }
          })
        );

        
        response.data.startDateTime = await Promise.all(
          response.data.startDateTime.map(async (entry: any) => {
            // Map over the poll array to replace phone numbers with user names if available
            entry.poll = entry.poll.map((phone: any) => {
              let user = findUserByPhone(phone, userDetails); // Assuming userDetails is the array of user data
              if (user) {
                return `${user.firstName} ${user.lastName}`;
              } else {
                return '+'+ phone; // Show phone number if no user found
              }
            });
            return entry; // Return the modified entry
          })
        );
        // Now you can use the updated participants array
        // setParticipants(response.data.participants);
        

      } catch (error) {
        response.data.participants = []
      }



      
      // response.data.startDateTime=JSON.parse(response.data.startDateTime);
      // response.data.location=JSON.parse(response.data.location);
      setActivityData(response.data);
      setshowLoader(false)
      
    } catch (error) {
      setshowLoader(false)
      
    }
  }

  const findUserByPhone = (phone: any, userList: any) => {
    return userList.find((user: any) => user.phone.replace('+', '') === phone);
  }


  const findUserByPhoneOwner = (phone: any, userList: any) => {
    let user = userList.find((user: any) => user.phone.replace('+', '') === phone);
    if (user) {
      return `${user.firstName} ${user.lastName}`;
    } else {
      return '+'+ phone; // Show phone number if no user found
    }
  }


  const formatPhoneNumber = (phone: string) => {
    phone = phone.toString(); // Ensure the phone is a string
    
    if (!phone.startsWith('+')) {
      phone = '+' + phone;
    }
    return phone;
  }

  const checkDateandTimeview = (eventtime: any) => {
    
    let activityStime = new Date(activityData.activityStartTime);
    
    let eventDateandTime = new Date(eventtime);
    eventDateandTime.setHours(activityStime.getHours());
    eventDateandTime.setMinutes(activityStime.getMinutes());

    let currentDate = new Date();
    // 
    
    
    if (currentDate > eventDateandTime) {
      let vv1 = twomontDates[1].toDateString();
      
      return vv1
    } else {

      let vv2 = twomontDates[0].toDateString();
      
      return vv2
    }
  }



  // const submitAccept = () =>{
  //   settypeCalender(false);
  //   

  //   if(typeChoose == 'ALLDATE'){
  //     

  //   }else if(typeChoose == 'CHOOSEDATE'){
  //     
  //   }

  // }

  const close = () => {
    setchooseDateType(false);
  }

  const closeCalandar = () => {
    settypeCalender(false);
  }

  const togglePopup = (value: any) => {

    
    setchooseDateType(false);
    settypeChoose("ALLDATE");
    activityData.activitySharedUsers = activityData.activitySharedUsers.filter((val: any) => {
      if (val.phone == "+" + browserdata.get('phone').trim()) {

        let processValue = typeof value == 'string' ? value : eventProccess;
        
        if (val.goingDate.length == 0 && val.notGoingDate.length == 0) {

          if (processValue == 'ACCEPT') {
            
            val.allDays = true;
          } else if (processValue == 'DECLAIN') {
            
            val.allDays = false;
          }

          return val
        } else {
          val.goingDate = [];
          val.notGoingDate = [];
          if (processValue == 'ACCEPT') {
            val.allDays = true;
          } else if (processValue == 'DECLAIN') {
            val.allDays = false;
          }
          return val;
        }
      } else {
        return val
      }
    });
    // 
    // for (var i = 0; i < activityData.activitySharedUsers.length; i++) {
    //   if (activityData.activitySharedUsers[i].status != "Accepted") {
    //     activityData.activitySharedUsers[i].status = "Accepted"
    //   }
    // }

    if (activityData.activitySharedUsers.length > 0) {
      let findbyPhoneno = activityData.activitySharedUsers.find((val: any) => val.phone == '+' + browserdata.get("phone").trim());
      
      if (findbyPhoneno) {
        let processValues = typeof value == 'string' ? value : eventProccess;
        localStorage.setItem("SharedUsersData", JSON.stringify(activityData.activitySharedUsers));
        navigate('/no-account-accept-invite-enter-name-disabled?eventId=' + browserdata.get("id") + '&phone=' + findbyPhoneno.phone + '&value=' + processValues)
      }

    }
  }


  const chooseTypeCalender = () => {
    setchooseDateType(false);
    settypeCalender(!typeCalender);
    settypeChoose("CHOOSEDATE");
  }

  const checkDateandTimeSender = async (eventtime: any) => {
    
    let activityStime = new Date(activityData.activityStartTime);
    
    let eventDateandTime = new Date(eventtime);
    eventDateandTime.setHours(activityStime.getHours());
    eventDateandTime.setMinutes(activityStime.getMinutes());

    let currentDate = new Date();
    // 
    
    
    if (currentDate > eventDateandTime) {
      let vv1 = twomontDates.slice(1, 2);
      
      return vv1
    } else {

      let vv2 = twomontDates.slice(0, 1);
      
      return vv2
    }
  }


  const submitnextrrdate = async () => {

    let ss = twomontDates;


    if (ss.length > 0) {
      
      let newValue = await checkDateandTimeSender(twomontDates[0]);
      if (activityData.activitySharedUsers.length > 0) {
        activityData.activitySharedUsers = activityData.activitySharedUsers.filter((val: any) => {
          if (val.phone == "+" + browserdata.get('phone').trim()) {
            dateFormat(newValue).forEach((s: any) => val.goingDate.push(s));
            return val;
          } else {
            return val;
          }
        })

        localStorage.setItem("SharedUsersData", JSON.stringify(activityData.activitySharedUsers));
        navigate('/no-account-accept-invite-enter-name-disabled?eventId=' + browserdata.get("id") + '&phone=' + '+' + browserdata.get("phone").trim() + '&value=' + eventProccess)
        
      }

    }

  }

  // const submitcalendarAction = () => {
  //   let ss = localStorage.getItem('calendervalue');
  //   if (ss === null) {
  //     ss = "default value";
  //   } else {
  //     ss = JSON.parse(ss);
  //   }
  //   
  //   if (eventProccess == 'ACCEPT' && ss != null) {
  //     
  //     if (activityData.activitySharedUsers.length > 0) {
  //       activityData.activitySharedUsers = activityData.activitySharedUsers.filter((val: any) => {

  //         if (val.phone == "+" + browserdata.get('phone').trim()) {
  //           // val.allDays='';
  //           
  //           if (val.allDays === '') {
  //             
  //             let selectedDates = dateFormat(ss);
  //             
  //             const filteredArray = val.notGoingDate.filter((date: any) => !selectedDates.some((selectedDates: any) => (checktwoDates(date, selectedDates) == true)));
  //             

  //             if (filteredArray != undefined) {
  //               val.notGoingDate = filteredArray;
  //             } else {
  //               dateFormat(ss).forEach((s: any) => val.notGoingDate.push(s));
  //             }

  //             // val.notGoingDate=filteredArray;
  //             dateFormat(ss).forEach((s: any) => val.goingDate.push(s));
  //             // val.goingDate=dateFormat(ss);
  //             return val;
  //           } else if (val.allDays == true || val.allDays == false) {
  //             
  //             let selectedDatesbool = dateFormat(ss);
  //             const filteredArray = val.notGoingDate.filter((date: any) => !selectedDatesbool.some((selectedDates: any) => (checktwoDates(date, selectedDates) == true)));
  //             

  //             if (filteredArray != undefined) {
  //               val.notGoingDate = filteredArray;
  //             }
  //             // else{
  //             //   dateFormat(ss).forEach((s:any)=> val.notGoingDate.push(s));
  //             // }
  //             dateFormat(ss).forEach((s: any) => val.goingDate.push(s));
  //             // val.notGoingDate=[];
  //             return val;
  //           }
  //         } else {
  //           return val;
  //         }
  //       })
  //       localStorage.setItem("SharedUsersData", JSON.stringify(activityData.activitySharedUsers));
  //       navigate('/no-account-accept-invite-enter-name-disabled?eventId=' + browserdata.get("id") + '&phone=' + '+' + browserdata.get("phone").trim())
  //       

  //     }
  //   } else if (eventProccess == 'DECLAIN' && ss != null) {
  //     
  //     if (activityData.activitySharedUsers.length > 0) {
  //       
  //       activityData.activitySharedUsers = activityData.activitySharedUsers.filter((val: any) => {
  //         if (val.phone == "+" + browserdata.get('phone').trim()) {
  //           // val.allDays='';
  //           if (val.allDays === '') {
  //             
  //             let selectedDates = dateFormat(ss);
  //             
  //             const filteredArray = val.goingDate.filter((date: any) => !selectedDates.some((selectedDates: any) => (checktwoDates(date, selectedDates) == true)));
  //             
  //             if (filteredArray != undefined) {
  //               val.goingDate = filteredArray;
  //             } else {
  //               dateFormat(ss).forEach((s: any) => val.goingDate.push(s));
  //             }

  //             // filteredArray.forEach((s:any)=> val.goingDate=s);
  //             dateFormat(ss).forEach((s: any) => val.notGoingDate.push(s));
  //             // val.notGoingDate=dateFormat(ss);
  //             return val;
  //           } else if (val.allDays == true || val.allDays == false) {
  //             let selectedDatesbool = dateFormat(ss);
  //             const filteredArray = val.goingDate.filter((date: any) => !selectedDatesbool.some((selectedDates: any) => (checktwoDates(date, selectedDates) == true)));
  //             

  //             if (filteredArray != undefined) {
  //               val.goingDate = filteredArray;
  //             }
  //             dateFormat(ss).forEach((s: any) => val.notGoingDate.push(s));
  //             // val.goingDate=[];
  //             return val
  //           }
  //         } else {
  //           return val
  //         }
  //       })
  //       localStorage.setItem("SharedUsersData", JSON.stringify(activityData.activitySharedUsers));
  //       navigate('/no-account-accept-invite-enter-name-disabled?eventId=' + browserdata.get("id") + '&phone=' + '+' + browserdata.get("phone").trim())
  //       

  //     }
  //   }
  // }

  const dateFormat = (value: any) => {
    return value.map((val: any) => {
      const inputDate = val;
      const dateObj = new Date(inputDate);
      const formattedDate = dateObj.toDateString().substr(0, 3) + '' + dateObj.toDateString().substr(3);

      
      return formattedDate
    })
  }

  const chagneFistletter = (value: string) => {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    } else {
      return '';
    }

  }

  const togglebottomPopup = (value: any) => {
    setbottomPopup(false);
  }

  const checkAndRedirectToApp = () => {
    
    // alert("open app");
    const appPackageName = 'adventureplanet.com://'; // Replace with your app's package name

    if (isMobile && !isAndroid) {
      // iOS device
      checkIsAppInstalledIOS();

    } else if (isMobile && isAndroid) {
      // Android device
      // setTimeout(function () { window.location.href = "http://play.google.com/store/apps/details?id=adventureplanet.com://"; }, 25);

      checkIsAppInstalledAndroid();

    } else {
      // Non-mobile device
      checkIsAppInstalledAndroid();// Redirect to your website
      // window.location.href ="http://play.google.com/store/apps/details?id=<package_name>"
    }
  };

  const checkIsAppInstalledAndroid = async () => {
    // Define your app's custom URI scheme
    const customScheme = `adventureplanet.com://`;

    return new Promise((resolve) => {
      // Create an invisible iframe and try to open your app's custom URI scheme
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = customScheme;
      document.body.appendChild(iframe);

      // Set a timeout to determine if the app is installed or not
      const timeout = setTimeout(() => {
        document.body.removeChild(iframe);

        resolve(false); // Timeout occurred, app is likely not installed
        if (Installed == false) {
          window.location.href = "https://play.google.com/store/apps/details?id=fun.motus.app&hl=en_CA&gl=US";
        }
      }, 2000); // Adjust the timeout duration as needed

      // Listen for when the app's custom URI scheme is opened
      window.addEventListener('blur', () => {
        clearTimeout(timeout);
        document.body.removeChild(iframe);
        setInstalled(true);

        resolve(true); // App is installed
        window.location.href = customScheme;
      });
    });
  };

  const checkIsAppInstalledIOS = async () => {
    // Define your app's custom URI scheme
    const customSchemeIos = `motus://`;

    return new Promise((resolve) => {
      // Create an invisible iframe and try to open your app's custom URI scheme
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = customSchemeIos;
      document.body.appendChild(iframe);

      // Set a timeout to determine if the app is installed or not
      const timeout = setTimeout(() => {
        document.body.removeChild(iframe);

        resolve(false); // Timeout occurred, app is likely not installed
        if (Installed == false) {
          window.location.href = "https://apps.apple.com/ca/app/motus-fun/id6474702612";
        }
      }, 2000); // Adjust the timeout duration as needed

      // Listen for when the app's custom URI scheme is opened
      window.addEventListener('blur', () => {
        clearTimeout(timeout);
        document.body.removeChild(iframe);
        setInstalled(true);

        resolve(true); // App is installed
        window.location.href = customSchemeIos;
      });
    });
  };

  function getFormattedTime(time: any) {
    
    if (time == undefined || time == undefined) {
      return '00:00'
    } else {


      const date = new Date(time);
      // 
      // // Get parts of the date
      // let hours = date.getHours();
      // const minutes = date.getMinutes();
      // const seconds = date.getSeconds();

      // // Determine AM or PM
      // const ampm = hours >= 12 ? 'PM' : 'AM';

      // // Convert 24-hour format to 12-hour format
      // hours = hours % 12;
      // hours = hours ? hours : 12; // the hour '0' should be '12'

      // // Add leading zeros to minutes and seconds
      // const strMinutes = minutes < 10 ? '0' + minutes : minutes;
      // const strSeconds = seconds < 10 ? '0' + seconds : seconds;
      let localtime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      // Format the time string
      const formattedTime = `${localtime}`;

      return formattedTime;
    }
  }

  function getFormattedEndTime(sdate: any, time: any) {
    
    if (sdate == undefined || sdate == undefined) {
      return '00:00'
    } else {


      const date = new Date(sdate);
      date.setMinutes(date.getMinutes() + time);
      let localtime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      // Format the time string
      const formattedTime = `${localtime}`;

      return formattedTime;
    }
  }

  const getFormattedDate = (date: any) => {
    
    if (date == undefined || date == undefined) {
      return '-- -- --'
    } else {
      const options: Intl.DateTimeFormatOptions = {
        weekday: 'short',  // 'Mon'
        year: 'numeric',   // '2024'
        month: 'short',    // 'Oct'
        day: 'numeric'     // '14'
      };
      let dateFormat = new Date(date).toLocaleDateString('en-US', options);
      return dateFormat
    }

  };

  const loginVerification = () => {
    Cookies.set('liveId', '/de/' + id, { expires: 100 })
    navigate('/signin');
  }

  return (
    <>

      <div style={{ padding: '0px' }}>

        <div>
          {bottomPopup ? <BottomPopup closePopup={togglebottomPopup} openApp={checkAndRedirectToApp} /> : <></>}
        </div>
        <>
          {showLoader ? <div className={styles.centerloader}><div className={styles.loader}></div></div> : <> {showEventcomplte ? <><div style={{ margin: 'auto', width: "100%", textAlign: "center", paddingTop: "200px" }}>Event Completed</div></> :
            <>
              {mapshowPopup ? <OptionPopup closePopup={closemapPopup} eventId={activityData?.Id} groupId={activityData?.groupId} location={activityData?.location} /> : <>{activityData ?
                <>

                  <div className={`${styles.eventEventDetailsNoAcc} ${styles.contentscroll} `}>
                    <div><Headertag showHeader={true} dropDownShow={false} /></div>
                    <div className={styles.eventEventDetailsNoAccChild}>
                      {/* <b className={styles.adventurePlanet}>
                    <FaArrowLeft onClick={()=>{navigate(-1)}} style={{ width: '40px', height: '36px',paddingTop:'22px' }} />

                      Motus</b> */}
                      <div style={{ paddingTop: '10px' }}>
                        <b className={styles.frommeShred}>{activityData?.name}</b>
                      </div>
                      <div className={styles.p10}>
                        <div className={styles.datecard}>
                          <img
                            className={styles.iconLocationPin}
                            alt=""
                            src="/-icon-time.svg"
                          />
                        </div>
                        <div >
                          {/* <div style={{ paddingLeft: "35px", color: "#958f8f" }}>{new Date(activityData.activityStartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(activityData.activityEndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div> */}
                          <>{activityData.startDateTime && activityData.startDateTime.length > 0 ? <>{activityData.startDateTime.map((startTimeObj: any, index: number) => (
                            <>{startTimeObj != '' ? (<div style={{ marginLeft: "35px" }}>{getFormattedDate(startTimeObj?.date)} , {getFormattedTime(startTimeObj?.date)} - {getFormattedEndTime(startTimeObj?.date, activityData.endDateTime[0])} &nbsp; {`(${startTimeObj?.poll.length})`}

                            </div>) : '--:--'}
                            </>
                          ))}</> : '--:--'}</>
                        </div>
                      </div>
                      {/* <div style={{ paddingLeft: '15px' }}>
                      <div className={styles.datecard}>
                        <div className={styles.iconLocationPin}>&nbsp;</div>
                      </div>
                      <div>
                        <div >{rRule ? <>{chagneFistletter(rruleText)}</> : <>&nbsp;</>}</div>
                      </div>
                    </div> */}
                      {activityData?.location ? <div className={styles.p10}>
                        <div className={styles.datecard}>
                          <img
                            className={styles.iconLocationPin}
                            alt=""
                            src="/-icon-location-pin.svg"
                          />
                        </div>
                        <div>
                          {textLocation == true ? <div>{activityData?.location} {locationshow == true ? <a style={{ paddingLeft: '5px', cursor: 'pointer' }} onClick={() => setmapshowPopup(true)}>Add Location</a> : <></>}</div> : <div>{activityData?.location} {locationshow == true ? <a style={{ paddingLeft: '5px', cursor: 'pointer' }} onClick={() => setmapshowPopup(true)}>Change Location</a> : <></>}</div>}
                          {locationshow == false ? <div style={{ textAlign: 'left', paddingTop: '10px', paddingLeft: '36px', color: 'red' }}>Please <a style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { loginVerification() }}>Login</a> to change location.</div> : <></>}
                        </div>
                      </div> : <></>}

                      {activityData.owner ? <div className={styles.p10}>
                        <div className={styles.datecard}>
                          <img
                            className={styles.iconLocationPin}
                            alt=""
                            src="/-icon-person.svg"
                          />
                        </div>
                        <div>
                          <div >Hosted by {activityData?.owner}</div>
                        </div>
                      </div> : <></>}

                    </div>
                    <div style={{ padding: '20px' }}>
                      <div >
                        {activityData.participants ? (
                          <>
                            <b className={styles.peopleAreInvited}>{activityData?.participants.length} people are invited</b>
                          </>
                        ) : (
                          <>
                            <b className={styles.peopleAreInvited}>0 people are invited</b>
                          </>
                        )
                        }
                        <div>
                          <div className={styles.chipParent}>

                            {(activityData?.participants && activityData?.participants.length > 0) ? (
                              <>
                                {activityData?.participants.map((r: any, _index: any) =>

                                (<div className={styles.chip}>
                                  <div className={styles.ellipseParent}>

                                    <div className={styles.orange} style={{ backgroundColor: r.color }} />

                                  </div>
                                  <div className={styles.brianTaylor}>{r.value}</div>
                                </div>)

                                )}
                              </>
                            ) : null

                            }
                          </div>
                        </div>
                      </div>

                      {/* {activityData?.difficulty ? <div>
                        <b className={styles.difficulty}>Difficulty</b>
                        <div className={styles.difficult}>{activityData?.difficulty}</div>
                      </div> : <></>} */}

                      {activityData?.description ? <div>
                        <div style={{ paddingTop: '20px' }}>
                          <b className={styles.details}>Details</b>
                          <div className={styles.plr10}>
                            {activityData?.description}

                          </div>
                        </div>
                      </div> : <></>}

                      {activityData?.startDateTime ? <div>
                        <div style={{ paddingTop: '20px' }}>
                          <b className={styles.details}>Poll Details</b>
                          <div className={styles.plr10}>
                            <div >
                              {/* <div style={{ paddingLeft: "35px", color: "#958f8f" }}>{new Date(activityData.activityStartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(activityData.activityEndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div> */}
                              <>{activityData.startDateTime && activityData.startDateTime.length > 0 ? <>{activityData.startDateTime.map((startTimeObj: any, index: number) => (
                                <>{startTimeObj != '' ? (<div > <b>{getFormattedDate(startTimeObj?.date)} ,{getFormattedTime(startTimeObj?.date)} - {getFormattedEndTime(startTimeObj?.date, activityData.endDateTime[0])}</b>
                                  <>
                                    <div >
                                      <div>
                                        <div className={styles.chipParent}>

                                          {(startTimeObj.poll && startTimeObj.poll.length > 0) ? (
                                            <>
                                              {startTimeObj.poll.map((r: any, _index: any) =>

                                              (<div className={styles.chip}>
                                                {/* <div className={styles.ellipseParent}>
                                                <div className={styles.orange} />

                                              </div> */}
                                                <div className={styles.brianTaylor}>{r}</div>
                                              </div>)

                                              )}
                                            </>
                                          ) : null

                                          }
                                        </div>
                                      </div>
                                      {/* {startTimeObj.poll && startTimeObj.poll.length > 0 ? <>
                          {startTimeObj.poll.map((pooldata:any,i:number)=>(
                          <div >{pooldata}</div>
                          ))}
                          </> : null} */}
                                    </div>
                                  </>
                                </div>) : '--:--'}
                                </>
                              ))}</> : '--:--'}</>
                            </div>
                          </div>
                        </div>
                      </div> : <></>}

                    </div>



                  </div>

                  <>
                    {chooseDateType ? <Popup title="RSVP to recurring event" closePopup={togglePopup} chooseTypeCalender={submitnextrrdate} close={close} /> : ''}
                  </>

                  <>
                    {/* {typeCalender ? <CalenderPopup title="Please choose type" phone={browserdata.get('phone').trim()} repeatrule={activityData.activityRepeatRule} shareduser={activityData.activitySharedUsers} eventsArray={eventDates} submitcalendarAction={submitcalendarAction} closeCalandar={closeCalandar} closePopup={chooseTypeCalender} passcolorDates={passcolorDates} disabledDate={disabledDate} /> : ''} */}
                  </>
                </> : <><div style={{ margin: 'auto', width: "100%", textAlign: "center", paddingTop: "200px" }}>No Data Available</div></>}</>}

            </>
          }</>}

        </>


      </div>
    </>
  );
};

export default EventEventDetailsBrowse1;
