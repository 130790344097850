import React from 'react';
import '../global.css'
import CalenderPopup from './calenderpopup';
import { FunctionComponent, useCallback, useEffect, useState } from "react";







const GooglePopup = (props:any) => {
  const [radioValue,setradioValue] = useState('');

  const handleOptionChange = (event:any) => {
  
    
    setradioValue(event.target.value)
    // setSelectedOption(event.target.value);
    
  };

  return (
    <div className="popup">
      <div className="popup_inner">
        <div style={{width:'100%',flexDirection:'row',display: 'flex'}}>
          <div style={{flex:12}}>
          <h5>{props.title} </h5>
          </div>
         
        </div>
        <div style={{textAlign: "right",padding: "10px"}}>
          <button onClick={props.close} style={{background: 'none',cursor: 'pointer'}}>OK</button>
        </div>
    {/* <div>
   
      <div style={{padding: '15px 0px 5px 0px'}}>
      <span style={{paddingRight: '5px'}}><input className='radiobt' id="option1"  name="options"
            value="1"   onChange={handleOptionChange} type="radio"    /> </span><label>This event</label>
      
      </div>
      <div style={{padding: '5px 0px 15px 0px'}}>
      <span style={{paddingRight: '5px'}}><input className='radiobt' id="option2"  name="options"
            value="2"  onChange={handleOptionChange} type='radio'  /></span> <label>This and following events</label>  
      </div>
    
     
      <div style={{padding:'15px 10px 0px 0px',textAlign:'right'}}>
    <button  style={{background:'none',padding:'5px',cursor:'pointer'}} onClick={ props.close }  >Close </button>
    
    </div>
    <div style={{padding:'0px 15px 0px 0px',textAlign:'right'}}>
    <button  style={{background:'none',padding:'5px',cursor:'pointer',color:'#087cf3'}} onClick={ radioValue == '1' ? props.chooseTypeCalender : props.closePopup }  >Ok </button>
    </div>
    </div> */}
  
      </div>
    </div>   
  )
}
export default GooglePopup
