import {
    useEffect,
    useState,
} from 'react';
import styles from "./Feedbackform.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import Headertag from './header';
import axios from 'axios';
import emailjs from 'emailjs-com';
import { v4 as uuidv4 } from 'uuid';

const apiUrl = "../api";

const FeedbackForm = () => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [feedback, setFeedBack] = useState('');

    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [errorstatus, seterrorstatus] = useState(true);
    const [showLoader, setshowLoader] = useState(false);
    const [sumbmitloader, setSubmitLoader] = useState(false);
    const [sumbmitError, setSubmitError] = useState('');
    const location = useLocation();
    const [userdetails, setusrDetails]: any = useState('');
    const { phone, status } = location.state || {};
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        
        setshowLoader(true);
        getUser(phone);

    }, [])

    const getUser = async (phoneNumber: any) => {
        const CookeData = Cookies.get('phoneNumber');
        let cookieData = CookeData ? JSON.parse(CookeData) : {};
        setPhoneNumber(cookieData.phone);
        let user = ''
        try {
            let modifiedNumber = cookieData.phone.replace("+", "");
            const responseData = await axios.get(`${apiUrl}/draftEvent/user/` + modifiedNumber);
            
            user = responseData['data'].Items[0];
            setusrDetails(responseData['data'].Items[0])
            redirect(responseData['data'].Items[0], cookieData.phone)
        } catch (err: any) {
            setusrDetails(undefined)
            redirect(undefined, cookieData.phone)
        }
    }

    const redirect = (findUser: any, phone: any) => {

        let user = null;

        // Check if findUser exists and has required fields
        if (findUser && findUser.firstName && findUser.lastName) {
            setFirstname(findUser.firstName)
            setLastname(findUser.lastName)
            setPhoneNumber(findUser.phone);
            if(findUser.phone){
                setIsButtonDisabled(false);
            }
            // Retrieve existing cookie data if available

        }
        setshowLoader(false)
        

    }

    const TokenexpiryDate = (e: any, d: any) => {
        const generateTime = new Date(d);

        // Add expiresIn (in seconds) to generateTime
        const expirationTime = new Date(generateTime.getTime() + e * 1000);

        // Get the current date and time
        const currentTime = new Date();
        
        // Check if current time is greater than expiration time
        if (currentTime > expirationTime) {
            return false; // Expired
        } else {
            return true; // Not expired
        }
    }

    const handlePhoneChange = (e: any) => {
        let value = e.target.value;

        // Ensure the input starts with `+`, if not, do not allow input
        if (!value.startsWith("+")) {
            return;
        }

        // Remove non-numeric characters except for the initial `+`
        if (value.startsWith("+")) {
            value = "+" + value.slice(1).replace(/\D/g, "");
        }

        // Check if it starts with +91 or +1
        const countryCodePattern = /^(\+91|\+1)/;

        if (countryCodePattern.test(value)) {
            // Extract digits after the country code
            const digitsAfterCode = value.replace(countryCodePattern, "");

            // Allow only 10 digits after the country code
            if (digitsAfterCode.length <= 10) {
                setPhoneNumber(value);

                // Enable the button only if there are exactly 10 digits after the country code
                if (digitsAfterCode.length === 10) {
                    setIsButtonDisabled(false); // Enable button
                } else {
                    setIsButtonDisabled(true); // Disable button if not exactly 10 digits
                }
            }
        } else {
            setPhoneNumber(value); // Allow input, but ensure logic for enabling button
            setIsButtonDisabled(true); // Disable button if country code is incorrect
        }
    };

    const handlefistname = ((val: any) => {
        
        setFirstname(val.target.value);
    })
    const handlelastname = ((val: any) => {
        setLastname(val.target.value);
    })

    const handlefeedback = ((val: any) => {
        setFeedBack(val.target.value);
    })
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitLoader(true);
        
        if (phoneNumber && firstname && lastname && feedback) {
            const templateParams = {
                from_name: firstname + " " + lastname,
                message: feedback
            };
            
            emailjs.send('service_jgif1ca', 'template_gaodwk9', templateParams, 'cXGYeCdNajt8Sy7D6')
                .then((response) => {
                    
                }, (err) => {
                    
                });

            try {
                let groupobj = {
                    "id": uuidv4(),
                    "firstName": firstname,
                    "lastName": lastname,
                    "phone": phoneNumber,
                    "comments": feedback,
                    "createdAt": new Date()

                }
                
                const groupresponse = await axios.post(`${apiUrl}/feedback`, groupobj);
                
                setSubmitLoader(false);
                setSubmitError("")
                setFeedBack("");
                navigate('/')
            } catch (error) {
                
                setSubmitLoader(false);
                setSubmitError("Error while submitting Feedback")
            }

        }
    };

    const checkallfields = () => {
        if (phoneNumber && feedback) {
            return false
        } else {
            return true
        }
    }


    return (
        <>
            <div><Headertag showHeader={status} dropDownShow={false} /></div>
            {showLoader ? <div className={styles.centerloader}><div className={styles.loader}></div></div> :
                <div className={styles.loginContainer}>
{/* 
                    <div className={styles.loginlogo}>
                        <img src="./logo.png" alt="Motus Logo" />
                    </div>
                    <h2>Motus <span className={styles.betatag}>Beta</span></h2> */}


                    <form className={styles.loginform} onSubmit={handleSubmit}>
                        <div className={styles.phoneinputmargin}>
                            <div style={{ textAlign: 'left' }}>
                                <label>First name</label>
                            </div>
                            <div className={styles.phoneinput}>
                                <input
                                    type="text"
                                    value={firstname}
                                    onChange={handlefistname}
                                    placeholder="First name"
                                    className={styles.phonenumber}
                                />

                            </div>
                        </div>
                        <div className={styles.phoneinputmargin}>
                            <div style={{ textAlign: 'left' }}>
                                <label>Last name</label>
                            </div>
                            <div className={styles.phoneinput}>
                                <input
                                    type="text"
                                    value={lastname}
                                    onChange={handlelastname}
                                    placeholder="Last name"
                                    className={styles.phonenumber}
                                />

                            </div>
                        </div>
                        <div className={styles.phoneinputmargin}>
                            <div style={{ textAlign: 'left' }}>
                                <label>Phone number</label>
                            </div>
                            <div className={styles.phoneinput}>
                                <input
                                    type="text"
                                    value={phoneNumber}
                                    onChange={handlePhoneChange}
                                    placeholder="Phone Number"
                                    className={styles.phonenumber}

                                />

                            </div>
                        </div>
                        <div className={styles.phoneinputmargin}>
                            <div style={{ textAlign: 'left' }}>
                                <label>FeedBack</label>
                            </div>
                            <div className={styles.phoneinput}>
                                <textarea
                                    style={{ height: '200px' }}
                                    value={feedback}
                                    onChange={handlefeedback}
                                    placeholder=""
                                    className={styles.phonenumber}
                                ></textarea>

                            </div>
                        </div>

                        <div style={{ padding: '0px 10px' }}>
                            <button type="submit" className={styles.submitbtn} disabled={checkallfields() || isButtonDisabled == true}>
                                Submit
                            </button>
                            {sumbmitloader == true ? <div className={styles.centerloader}>
                                <div className={styles.spinner}></div>
                            </div> : <></>}
                            <div style={{ color: 'red' }}>{sumbmitError}</div>
                        </div>
                    </form>

                </div>}

        </>
    );
}

export default FeedbackForm